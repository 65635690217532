import React from 'react'
import ReactModal from 'react-modal'
import { View } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import { ActivityIndicator, Button, List, Title } from 'react-native-paper'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { sellItem, SellItemState } from '../../reducers/Items/sellItem'
import { Portfolio } from '../../reducers/Portfolio'
import { User } from '../../reducers/User'
import { Alert, ArtText, ArtTitle, ArtView, Form, OtpProvider } from '../../ReusableComponents'
import { OtpProperties } from '../../ReusableComponents/OtpProvider/OtpProvider'
import { ReduxAppState } from '../../utils/types'
import * as _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { getPortfolio } from '../../reducers/Portfolio/getPortfolio'
import { Items } from '../../reducers/Items'
import { getPortfolioOfUser } from '../../reducers/User/getPortfolioOfUser'
import { getItemInfo } from '../../reducers/Items/getItemInfo'
import { StackNavigationProp } from '@react-navigation/stack'
import { RouteName } from '../../navigation/types'

export const modalCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxHeight: '100%',
        backgroundColor: '#E5E4E2',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: 'black'
    }
}

export interface ReminderModalState {

}

export interface OwnProps {
    remainingDays: number
    onRequestClose: () => void
    isOpen: boolean
}

export interface DispatchProps {

}

export interface StateProps {

}

export type ReminderModalProps = OwnProps & DispatchProps & StateProps

class ReminderModal extends React.Component<ReminderModalProps, ReminderModalState> {
    otpProvider?: OtpProperties
    constructor(props: any) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <ReactModal isOpen={this.props.isOpen && this.props.remainingDays >= 1} style={modalCustomStyles} onRequestClose={this.props.onRequestClose}>
                <ArtView direction='column' style={{ paddingHorizontal: 10, paddingVertical: 20, maxWidth: 250 }}>
                    <ArtTitle label={'REMINDER'} labelThickness='mid' />
                    {/* <Button onPress={this.otpProvider?.onRequestOtp}>Sell</Button> */}
                    <ArtText label={`Please note that you have only ${this.props.remainingDays} days before the shares you have reserved will expire.`} />
                    {/* <ArtView justifyAlignment='flex-end' style={{ marginTop: 20 }}>
                    </ArtView> */}
                    <Button onPress={this.props.onRequestClose} style={{ backgroundColor: '#F7931E', borderRadius: 10, marginTop: 20 }} color='white'><ArtText label='OK' labelThickness='bold' /></Button>
                </ArtView>
            </ReactModal>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => ({
    user: state.api?.user?.authUser?.response,
    portfolio: state.api?.portfolio?.getPortfolio.response,
    sellApi: state.api?.items?.sellItem,
    item: state.api?.items?.getItemInfo?.response
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(ReminderModal))