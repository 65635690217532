export enum RouteName {
    LoginPage = 'Login',
    HomePage = 'Home',
    AuthPage = 'Auth',
    MainHomePage = 'MainHome',
    DemoPage = 'Demo',
    DashboardPage = 'Dashboard',
    ListingPage = 'Listing',
    ListPage = 'List',
    ProfilePage = 'Profile',
    WalletPage = 'Wallet',
    SettingsPage = 'Settings',
    SignUpPage = 'Registration',
    ResetPwdPage = 'ResetPwd',
    ItemDetailPage = 'Details',
    PreviousOwnerPage = 'Previous Owner',
    ActiveListPage = 'Active List',
    WelcomePage = 'Welcome',
    NotificationPage = 'Notification',
    ForgotPwdPage = 'Forgot Password',
    VerifyEmailPage = 'Verify Email',
    VerifyOTPPage = 'Verify OTP',
    ChangePasswordPage = 'Change Password'
}

export enum TabName {
    DetailsTab = 'Details',
    PreviousOwnerTab = 'Previous Owner',
    ActiveListTab = 'Active List',
    UpcomingListTab = 'Upcoming List',
    ArtShareTab = 'Art Share',
    TranHistoryTab = 'Transaction History',
    WalletTab = 'Wallet Detail',
    ReservedListTab = 'ReservedList'
}