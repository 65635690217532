import React, { useEffect, useState } from 'react'
import { Image, Text, TouchableOpacity, View } from 'react-native'
import { IconButton, Paragraph, Title } from 'react-native-paper'
import { Notification } from '../../../reducers/Notification'
import { generateRandomId } from '../../../utils'
import { notificationStyles as styles } from '../styles'

export const NotifDetailDesktop = ({ ...props }) => {
  const { onReadNotification, item } = props

  const [read, setRead] = useState(false)

  useEffect(() => {
    if (item.hasOwnProperty('isRead')) {
      const _isRead = item?.isRead !== 0 ? true : false
      setRead(_isRead)
    }
  }, [])

  const getExpiryDate = (createdDate: Date) => {
    const date = new Date(createdDate)
    date.setDate(date.getDate() + 90)
    const expiryDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear()
    return expiryDate
  }

  const getTransactionDesc = (data: Notification) => {
    const str = data?.operation
    const n = 2 // second space
    const a = str.split(' ')
    const artWorkName = a.slice(n).join(' ')

    let tranDscp = ''
    switch (true) {
      case ((data?.tranType === 'Buy') && (data?.tranStatus === 0)):
        tranDscp = `Thank you. You have successfully purchased $${Number(data?.amt).toFixed(2)} amount of shares (${Number(data.amt) / Number(process.env.VALUE_PER_SHARE)}) for ${artWorkName}.`
        break
      case ((data?.tranType === 'Deposit') && (data?.tranStatus === 0)):
        tranDscp = `You have successfully deposited $${Number(data?.amt).toFixed(2)} in your account.`
        break
      case ((data?.tranType === 'Deposit') && (data?.tranStatus === 1)):
        tranDscp = `Sorry, we are having technical issues on our end. Please expect a call from our Payment Partner for assistance.`
        break
      case ((data?.tranType === 'Refund') && (data?.tranStatus === 0)):
        tranDscp = `We have refunded $${Number(data?.amt).toFixed(2)} to your account for the purchase of ${artWorkName} as this artwork has been delisted in our platform. Thank you`
        break
      case ((data?.tranType === 'Reserve') && (data?.tranStatus === 0)):
        tranDscp = `Thank you. You have successfully reserved $${Number(data?.amt).toFixed(2)} amount of shares (${Number(data.amt) / Number(process.env.VALUE_PER_SHARE)}) for ${artWorkName}. This reservation will last until ${getExpiryDate(data?.createdAt)}. To confirm the purchase, please cash in a total of $${Number(data?.amt).toFixed(2)}.`
        break
      case ((data?.tranType === 'Sell') && (data?.tranStatus === 0)):
        tranDscp = `Thank you. You have successfully sold $${Number(data?.amt).toFixed(2)} amount of shares (${Number(data.amt) / Number(process.env.VALUE_PER_SHARE)}) for ${artWorkName}.`
        break
      default:
        tranDscp = 'Unknown'
        break
    }

    return tranDscp
  }

  const getStatusValue = (status: number) => {
    let statusDscp = ''
    switch (status) {
      case 0:
        statusDscp = 'Successful'
        break;
      case 1:
        statusDscp = 'Failed'
        break;
      case 2:
        statusDscp = 'Cancelled'
        break;
      case 3:
        statusDscp = 'Pending'
        break;
      default:
        statusDscp = 'Unknown'
        break;
    }

    return statusDscp
  }

  const convertUTCToDate = (date: Date) => {
    const convertedDate = new Date(date)
    return (convertedDate.getMonth() + 1) + '/' + convertedDate.getDate() + '/' + convertedDate.getFullYear()
  }

  const onHandleClickButton = () => {
    onReadNotification()
    setRead(true)
  }

  return (
    <TouchableOpacity key={generateRandomId()} style={[styles.table, { backgroundColor: read ? '#FEF4E9' : '#FCDEBB' }]} onPress={() => onHandleClickButton()}>
      <View style={[styles.row, { width: '75%', height: 120 }]}>
        <View style={[styles.row, { width: '100%', height: '100%', position: 'relative', marginLeft: 10 }]}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ height: 100, width: 100 }}>
              <View style={styles.notifIcon}>
                <View style={{ width: 80, height: 80 }}>
                  {item?.tranType === 'Buy' ? (
                    <Image
                      source={require('../../../assets/buy.png')}
                      style={{ height: 50, width: 50, alignSelf: 'center', marginTop: 15 }}
                      resizeMode='contain'
                    />
                  ) : item?.tranType === 'Deposit' ? (
                    <Image
                      source={require('../../../assets/deposit.png')}
                      style={{ height: 80, width: 80, padding: 5, borderRadius: 40 }}
                      resizeMode='contain'
                    />
                  ) : item?.tranType === 'Sell' ? (
                    <Image
                      source={require('../../../assets/sell.png')}
                      style={{ height: 80, width: 80, padding: 5, borderRadius: 40 }}
                      resizeMode='contain'
                    />
                  ) : item?.tranType === 'Refund' ? (
                    <Image
                      source={require('../../../assets/refund.png')}
                      style={{ height: 50, width: 50, padding: 5, alignSelf: 'center', marginTop: 15 }}
                      resizeMode='contain'
                    />
                  ) : item?.tranType === 'Reserve' ? (
                    <Image
                      source={require('../../../assets/reserved.png')}
                      style={{ height: 50, width: 50, padding: 5, alignSelf: 'center', marginTop: 10 }}
                      resizeMode='contain'
                    />
                  ) : (
                    <Image
                      source={require('../../../assets/withdraw.png')}
                      style={{ height: 50, width: 50, alignSelf: 'center', marginTop: 20 }}
                    />
                  )}
                </View>
              </View>
            </View>
            <View style={{ marginLeft: 8, maxWidth: 600 }}>
              <Title numberOfLines={2} style={[styles.boldText, { fontSize: 16, flexShrink: 1, lineHeight: 25 }]}>{`${getStatusValue(item?.tranStatus)} ${item?.tranType}`}</Title>
              <Text numberOfLines={3} style={[styles.normalText, { fontSize: 14, flexShrink: 1 }]}>{getTransactionDesc(item)}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.row, { width: '20%', position: 'relative', right: 0 }]}>
        <View style={styles.rowTextContainer}>
          <Paragraph style={[styles.basicLabel, { textAlign: 'center', fontSize: 14 }]}>{convertUTCToDate(item?.createdAt)}</Paragraph>
        </View>
        <View style={styles.rowTextContainer}>
          <IconButton
            icon='dots-horizontal'
            size={25} />
        </View>
      </View>
    </TouchableOpacity>


  )
}

export default NotifDetailDesktop