import React from 'react'
import { View, Text, StyleProp, ViewStyle, TextStyle } from 'react-native'
import styles from './styles'

export interface AlertProps {
    children: string | Element
    visible: boolean
    onClose?: () => any
    type: 'error' | 'success' | 'warning'
    autoClose?: boolean
    style?: StyleProp<ViewStyle>
    labelStyle?: StyleProp<TextStyle>
}

export interface AlertState {
    isShow: boolean
}


class AlertComponent extends React.Component<AlertProps, AlertState> {
    constructor (props: AlertProps) {
        super(props)
        this.state = {
            isShow: props.visible
        }
    }

    componentDidMount() {
        if (this.props.visible && this.props.autoClose) this.onClose()
    }

    componentDidUpdate(prevProps: AlertProps) {
        if(this.props.visible !== prevProps.visible) {
            this.setState({ isShow: this.props.visible })
            if (this.props.autoClose && this.props.visible) this.onClose()
        }
    }

    onClose = () => {
        setTimeout(() => {
            this.setState({ isShow: false })
            if (this.props.onClose) this.props.onClose()
        }, 10000)
    }

    render() {
        return this.state.isShow &&(
            <View style={[styles.container, styles[this.props.type], this.props.style]}>
                <Text style={[styles.text, this.props.labelStyle]}>{this.props.children}</Text>
            </View>
        )
    }
}

export default AlertComponent