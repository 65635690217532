import React from 'react';
import { View, ToastAndroid, Platform, Image } from 'react-native';
import {
    DrawerContentScrollView,
    DrawerItem
} from '@react-navigation/drawer'
import {
    Title,
    Caption,
    Drawer,
} from 'react-native-paper';
import { navigationStyles as styles } from './styles'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { logOutUser } from '../reducers/User/user'
import { RouteName } from './types';
import { ReduxAppState } from '../utils/types';

export interface ComponentProps {
    navigation: any
}

export interface DispatchProps {
    logOutUser: () => any
}

export interface StateProps {
    currentUser?: any
}

export interface NavContentState { }

export type NavContentDetailsProps = ComponentProps & DispatchProps & StateProps

class DrawerContent extends React.Component<NavContentDetailsProps, NavContentState> {

    doLogOut = () => {
        this.props.logOutUser()
            .then(() => {
                if (Platform.OS !== 'web') {
                    ToastAndroid.showWithGravity(
                        "You have been successfully logged out",
                        ToastAndroid.SHORT,
                        ToastAndroid.BOTTOM
                    )
                }
            })
            .catch(() => {
                if (Platform.OS !== 'web') {
                    ToastAndroid.showWithGravity(
                        "ERROR!",
                        ToastAndroid.SHORT,
                        ToastAndroid.BOTTOM
                    )
                }
            })
    }

    capitalize(str: any) {
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    }


    render() {
        const { currentUser } = this.props;
        return (
            <View style={{ flex: 1 }}>
                <View style={styles.sideNavHeader}>
                    <Title style={styles.greetingsText}>{this.capitalize(currentUser?.name)}</Title>
                </View>
                <DrawerContentScrollView>
                    <View style={styles.drawerContent}>
                        <Drawer.Section style={styles.drawerSection}>
                            <DrawerItem
                                icon={({ color, size }) => (
                                    <Icon
                                        name="cog-outline"
                                        color={color}
                                        size={size}
                                    />
                                )}
                                label="Profile"
                                onPress={() => { this.props.navigation.navigate(RouteName.ProfilePage) }}
                            />
                            <DrawerItem
                                icon={({ color, size }) => (
                                    <Icon
                                        name="briefcase-outline"
                                        color={color}
                                        size={size}
                                    />
                                )}
                                label="Portfolio"
                                onPress={() => { this.props.navigation.navigate(RouteName.DashboardPage) }}
                            />
                            <DrawerItem
                                icon={({ color, size }) => (
                                    <Icon
                                        name="image-multiple-outline"
                                        color={color}
                                        size={size}
                                    />
                                )}
                                label="Listing"
                                onPress={() => { this.props.navigation.navigate(RouteName.ListPage) }}
                            />
                            <DrawerItem
                                icon={({ color, size }) => (
                                    <Icon
                                        name="wallet-outline"
                                        color={color}
                                        size={size}
                                    />
                                )}
                                label="Wallet"
                                onPress={() => { this.props.navigation.navigate(RouteName.WalletPage) }}
                            />
                            <DrawerItem
                                icon={({ color, size }) => (
                                    <Icon
                                        name="cog-outline"
                                        color={color}
                                        size={size}
                                    />
                                )}
                                label="Settings"
                                onPress={() => { this.props.navigation.navigate(RouteName.SettingsPage) }}
                            />
                        </Drawer.Section >
                    </View>
                </DrawerContentScrollView>
                <Drawer.Section style={styles.bottomDrawerSection}>
                    <DrawerItem
                        label="Sign Out"
                        onPress={() => { this.doLogOut() }}
                        icon={({ focused, color, size }) => {
                            return (
                                <Image
                                    source={require('../assets/logout.png')}
                                    style={{ height: size, width: size }}
                                    resizeMode="contain"
                                />
                            );
                        }}
                    />
                </Drawer.Section>
            </View>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _authUserInfo = state.api?.user?.authUser
    return ({
        currentUser: _authUserInfo.response
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logOutUser: () => logOutUser(dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContent)
