import React from 'react'
import ReactModal from 'react-modal'
import { View } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import { ActivityIndicator, Button, List, Title } from 'react-native-paper'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { sellItem, SellItemState } from '../../reducers/Items/sellItem'
import { Portfolio } from '../../reducers/Portfolio'
import { User } from '../../reducers/User'
import { Alert, ArtText, ArtTitle, ArtView, Form, OtpProvider } from '../../ReusableComponents'
import { OtpProperties } from '../../ReusableComponents/OtpProvider/OtpProvider'
import { ReduxAppState } from '../../utils/types'
import * as _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { getPortfolio } from '../../reducers/Portfolio/getPortfolio'
import { Items } from '../../reducers/Items'
import { getPortfolioOfUser } from '../../reducers/User/getPortfolioOfUser'
import { getItemInfo } from '../../reducers/Items/getItemInfo'
import { StackNavigationProp } from '@react-navigation/stack'
import { RouteName } from '../../navigation/types'
import { SystemProperties } from '../../reducers/SystemProperty'
import { validateFields } from '../../utils/systemValidator'

export const modalCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxHeight: '100%',
        backgroundColor: '#E5E4E2',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: 'black'
    }
}

export interface SellModalState {
    shareValue: string | undefined
    isError: boolean
}

export interface OwnProps {
    showModal: boolean
    onClose: () => void
    onHandleSuccess: (isSuccess: boolean) => void
    navigation: StackNavigationProp<any>
}

export interface DispatchProps {
    onSellShare: (data: any, portfolioId: string) => any
    onGetPortfolio: (params: any) => any
    getPortolioItem: (userId: string) => any
    getItemInfo: (id: string) => any
}

export interface StateProps {
    user: User
    portfolio: Portfolio[]
    sellApi: SellItemState
    item: Items
    systemFields: SystemProperties[]
}

export type SellModalProps = OwnProps & DispatchProps & StateProps

class SellModal extends React.Component<SellModalProps, SellModalState> {
    otpProvider?: OtpProperties
    constructor(props: any) {
        super(props)
        this.state = {
            shareValue: '0',
            isError: false
        }
    }

    handleShareValue = (value: string | undefined) => {
        const { portfolio } = this.props
        const _portfolio = _.first(portfolio)
        this.setState({ shareValue: value }, () => {
            console.log('share:', Number(this.state.shareValue))
            console.log('no:', _portfolio?.numberOfShare)
            console.log('isSell total', (Number(this.state.shareValue) - (_portfolio?.numberOfShare ?? 0)))
            console.log('isSell', (_portfolio?.numberOfShare ?? 0 - Number(this.state.shareValue)) <= 0)
        })
    }

    onSellItem = () => {
        const { user, portfolio } = this.props
        const _portfolio = _.first(portfolio)
        this.props.onSellShare({ userId: user.id, email: user.email, sharesToSell: Number(this.state.shareValue)}, _portfolio?.id ?? '')
        .then(async () => {
            this.props.onGetPortfolio({ userId: this.props.user.id, itemId: this.props.item.id })
            this.props.onHandleSuccess(true)
            this.props.getPortolioItem(user.id)
            await this.props.getItemInfo(this.props.item.id)
                .then((resp) => {
                    const _item = JSON.parse(resp.data.message)
                    this.props.navigation.navigate(RouteName.ItemDetailPage, { isSoldOut: _item?.isSoldOut })
                })
            this.props.onClose()
        })
        .catch((err) => {
            console.error(err)
            this.setState({ isError: true})
            this.props.onHandleSuccess(false)
        })
    }

    isSellDisabled = () => {
        const { portfolio } = this.props
        const _portfolio = _.first(portfolio)
        return Number(this.state.shareValue) <= 1 || _portfolio?.numberOfShare === 0 || (Number(this.state.shareValue) > (_portfolio?.numberOfShare ?? 0))
    }


    render() {
        const isAllowed = validateFields('Sell', this.props.systemFields)
        return (
            <ReactModal isOpen={this.props.showModal} style={modalCustomStyles} onRequestClose={!this.props.sellApi?.loading ? this.props.onClose : undefined}>
                <OtpProvider flow='Sell' onProceedFlow={this.onSellItem} exposedProperties={(otpProperties: OtpProperties) => this.otpProvider = otpProperties}>
                    <Alert autoClose visible={this.state.isError} type='error' style={{ width: '100%' }}>
                        <ArtText style={{ fontSize: 20, textAlignVertical: 'center' }} label='Something went wrong. Please try again.' />
                    </Alert>
                    <ArtView direction='column' style={{ paddingHorizontal: 10, paddingVertical: 20 }}>
                        <View><ArtTitle label={'SHARES TO BE SOLD'} labelThickness='mid' /></View>
                        {/* <Button onPress={this.otpProvider?.onRequestOtp}>Sell</Button> */}
                        <ArtView direction='column' style={{ marginLeft: 10, marginTop: 10 }}>
                            <ArtText label={`\u2022 Must be more than 1`} labelStyle='italic' style={{ marginBottom: 5 }} />
                            <ArtText label={`\u2022 Should not exceed owned shares`} labelStyle='italic' />
                        </ArtView>
                        <TextInputMask placeholder='(Amount of Shares)' type='only-numbers' value={Number(this.state.shareValue) >= 1 ? this.state.shareValue : ''} onChangeText={this.handleShareValue} style={{ marginTop: 15, minWidth: 400, backgroundColor: '#F6F6F6', borderRadius: 10, borderColor: 'black', borderWidth: 1, padding: 10 }} />
                        <ArtView justifyAlignment='flex-end' style={{ marginTop: 20 }}>
                            <Button disabled={this.props.sellApi?.loading || this.isSellDisabled() || !isAllowed} onPress={this.otpProvider?.onRequestOtp} style={{ backgroundColor: '#F68C2999', marginRight: 10, borderRadius: 10 }} color='black'>{this.props.sellApi?.loading ? <ActivityIndicator size={'small'} color='black' /> : <ArtText label='Confirm' labelThickness='bold' />}</Button>
                            <Button disabled={this.props.sellApi?.loading} onPress={this.props.onClose} style={{ backgroundColor: '#BDBDBD', borderRadius: 10 }} color='black'><ArtText label='Cancel' labelThickness='bold' /></Button>
                        </ArtView>
                    </ArtView>
                </OtpProvider>
            </ReactModal>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => ({
    user: state.api?.user?.authUser?.response,
    portfolio: state.api?.portfolio?.getPortfolio.response,
    sellApi: state.api?.items?.sellItem,
    item: state.api?.items?.getItemInfo?.response,
    systemFields: state.api?.systemParam?.getAllSysParams?.response ?? []
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onSellShare: (data: any, portfolioId: string) => sellItem(dispatch, data, portfolioId),
    onGetPortfolio: (params: any) => getPortfolio(dispatch, { filter: `userId==${params.userId}&itemId==${params.itemId}` }),
    getPortolioItem: (userId: string) => getPortfolioOfUser(dispatch, userId),
    getItemInfo: ((id: string) => getItemInfo(dispatch, id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(SellModal))