import React from 'react'
import { Audit } from '../../reducers/Audit'
import { FormattedNumber } from 'react-intl'
import moment from 'moment'

export interface ComponentProps {
    selectedTransaction: Audit
    resetData: () => void
}
export interface DispatchProps {}
export interface StateProps {}

export type TransHistoryDetailsProps = ComponentProps & DispatchProps & StateProps

export interface TransHistoryDetailsState {}

export class TransHistoryDetailsComponent extends React.Component<
    TransHistoryDetailsProps,
    TransHistoryDetailsState
> {
    onGetTitleHeader = () => {
        switch (this.props.selectedTransaction?.tranType) {
            case 'Deposit':
                return 'Incoming/Credit'
            case 'Withdraw':
                return 'Outgoing/Debit'
            case 'Buy':
                return 'Action'
            default:
                return ''
        }
    }

    render() {
        const { selectedTransaction } = this.props
        return (
            <div id="TransHistoryDetails" className="transHistoryDetails">
                <h4 className="transHistoryDetails__header">{this.onGetTitleHeader()}</h4>
                <div className="transHistoryDetails__view">
                    <div className="d-flex justify-content-between">
                        <p>{selectedTransaction?.tranType}</p>
                        <p></p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Remarks</p>
                        <p></p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Mode of Payment</p>
                        <p>
                            {selectedTransaction?.tranType === 'Buy'
                                ? 'ArtShare Wallet'
                                : selectedTransaction?.paymentMethod}
                        </p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Amount</p>
                        <p>
                            <FormattedNumber
                                currency="USD"
                                value={Number(selectedTransaction?.amt.toFixed(2))}
                            />
                        </p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Updated Balance</p>
                        <p>
                            <FormattedNumber
                                currency="USD"
                                value={Number(selectedTransaction?.balance.toFixed(2))}
                            />
                        </p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Date & Time</p>
                        <p>{moment(selectedTransaction?.tranDate).format('MM/DD/YYYY h:mm a')}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Transaction ID</p>
                        <p>{selectedTransaction?.tranId}</p>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <button className="transHistoryDetails__backBtn" onClick={this.props.resetData}>
                        Back
                    </button>
                </div>
            </div>
        )
    }
}
