import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Wallet } from '.'

export const BEGIN_CALCULATE_FEE = 'API/ITEM/BEGIN_CALCULATE_FEE'
export const SUCCESS_CALCULATE_FEE = 'API/ITEM/SUCCESS_CALCULATE_FEE'
export const ERROR_CALCULATE_FEE = 'API/ITEM/ERROR_CALCULATE_FEE'
export const CLEAR_CALCULATE_FEE = 'API/ITEM/CLEAR_CALCULATE_FEE'

export const calculateFeeInitialState = {
    response: undefined,
    loading: false
}

export interface CalculateFeeState {
    response: Wallet | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface CalculateFeeAction {
    type: string
    [item: string]: any
}

export const reducer = (newState: CalculateFeeState = calculateFeeInitialState, action: CalculateFeeAction) => {
    switch (action.type) {
        case BEGIN_CALCULATE_FEE:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_CALCULATE_FEE:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_CALCULATE_FEE:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        case CLEAR_CALCULATE_FEE:
            return calculateFeeInitialState
        default:
            return newState
    }
}

export const beginCalculateFee = () => ({
    type: BEGIN_CALCULATE_FEE,
})

export const successCalculateFee = (response: AxiosResponse) => ({
    type: SUCCESS_CALCULATE_FEE,
    data: response.data.payload,
    status: response.status
})

export const errorCalculateFee = (error: AxiosError) => ({
    type: ERROR_CALCULATE_FEE,
    status: error.response?.status,
})

export const calculateFee = (dispatch: Dispatch, data: any) => {
    const config: AxiosRequestConfig = {
        method: methods.POST,
        url: `/wallet/topup/calculate-fee`,
        data
    }

    dispatch(beginCalculateFee())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successCalculateFee(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorCalculateFee(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer