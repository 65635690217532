import changePasswordReducer, { changePasswordInitialState, ChangePasswordState } from './changePassword'
import verifyEmailReducer, { verifyEmailInitialState, VerifyEmailState } from './verifyEmail'
import forgotPwdOtpReqReducer, { forgotPwdOtpReqInitialState, ForgotPwdOTPReqState } from './otpRequest'
import verifyOTPReducer, { verifyOTPInitialState, VerifyOTPState } from './verifyOTP'

import { combineReducers } from 'redux'

export const forgotPwdInitialState: ForgotPwdInitialState = {
    changePassword: changePasswordInitialState,
    verifyEmail: verifyEmailInitialState,
    forgotPwdOtpReq: forgotPwdOtpReqInitialState,
    verifyOTP: verifyOTPInitialState
}

export interface ForgotPwdInitialState {
    changePassword: ChangePasswordState,
    verifyEmail: VerifyEmailState,
    forgotPwdOtpReq: ForgotPwdOTPReqState,
    verifyOTP: VerifyOTPState
}

const forgotPwdReducer = combineReducers({
    changePassword: changePasswordReducer,
    verifyEmail: verifyEmailReducer,
    forgotPwdOtpReq: forgotPwdOtpReqReducer,
    verifyOTP: verifyOTPReducer
})

export default forgotPwdReducer

