import React from 'react'
import { TouchableOpacity, View, Text, Image, Pressable } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { loginStyles as styles } from './styles'
import { RouteName } from '../../navigation/types'
import { FormattedMessage } from 'react-intl'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { CLEAR_USER, fbLoginUser, googleLoginUser, loginUser } from '../../reducers/User/user'
import { clearUserRegister } from '../../reducers/User/register'
import { ReduxAppState } from '../../utils/types'
import { BackgroundTemplate, Form, OtpProvider, Screen } from '../../ReusableComponents'
import * as yup from 'yup'
import { CLEAR_MODAL } from '../../reducers/Modal/showModal'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { verifyEmail } from '../../reducers/ForgotPassword/verifyEmail'
import { CLEAR_FORGOT_PWD_OTP_REQ, forgotPwdOtpReq } from '../../reducers/ForgotPassword/otpRequest'
import * as _ from 'lodash'
import ReactModal from 'react-modal'
import OtpModal from '../Modal/OtpModal'
import { modalCustomStyles } from '../../ReusableComponents/GlobalModal/Modal'
import { OtpProperties } from '../../ReusableComponents/OtpProvider/OtpProvider'
import { responseCode } from '../../utils/response'
import { SystemProperties } from '../../reducers/SystemProperty'
import { validateFields } from '../../utils/systemValidator'

export interface LoginPageProps {
    navigation: StackNavigationProp<any>
}

export interface StateProps {
    isLoading: boolean
    isRegistered: boolean
    user: any
    isRegisterSuccess: boolean
    isError: boolean
    isSignedIn: boolean
    isFederatedError: boolean
    errorMsg: string
    otpStatus: string
    isOtpModalOpen: boolean
    systemFields: SystemProperties[]
}

export interface DispatchProps {
    loginUser: (username: string, password: string) => any
    fbLoginUser: () => any
    googleLoginUser: () => any
    onClearRegister: () => any
    onClearUser: () => any
    onClearModal: () => any
    onVerifyEmail: (data: any) => any
    requestOTP: (data: any) => any
    onClearOTP: () => void
    onOpenOTPModal: (value: boolean) => void
    onSetGlobalLoading: (value: boolean) => void
}

export interface UserDetailState {
    value: any
    emailError: string
    passwordError: string
    rememberMe: boolean
    federatedSignInErr: string
    basicSignInErr: string
    isLoading: boolean
    isLockedAcc: boolean
    isAlertMessage: boolean
    errMsg: string
    credential: any
}

export type UserLoginDetailsProps = LoginPageProps & StateProps & DispatchProps

const FormSchema = yup.object({
    username: yup
        .string()
        .required('Username is required')
        .matches(/^\s*\S[\s\S]*$/, 'Username is required'),
    password: yup
        .string()
        .required('Password is required')
        .matches(/^(?!\s+$).*/, 'Password is required')
})

class LoginPage extends React.Component<UserLoginDetailsProps, UserDetailState> {
    _isMounted = false
    otpProvider?: OtpProperties
    constructor(props: UserLoginDetailsProps) {
        super(props)
        this.state = {
            federatedSignInErr: 'User+is+not+enabled',
            basicSignInErr: 'User is disabled.',
            value: {},
            emailError: '',
            passwordError: '',
            rememberMe: false,
            isLoading: false,
            isLockedAcc: false,
            isAlertMessage: false,
            errMsg: '',
            credential: {}
        }
    }

    componentDidMount() {
        this.props.onClearUser()
        this.props.onClearModal()
    }

    componentDidUpdate(
        prevProps: Readonly<UserLoginDetailsProps>,
        prevState: Readonly<UserDetailState>,
        snapshot?: any
    ): void {
        if (
            prevProps?.otpStatus !== this.props.otpStatus &&
            this.props.otpStatus === responseCode.OTP_SUCCESSFUL
        ) {
            // this.props.onOpenOTPModal(true)
        }
    }

    onChangeValue = (key: string, value: string) => {
        const _value = { ...this.state.value }
        _value[key] = value
        this.setState({ value: _value })
    }

    onLoginPressed = async (data: any) => {
        this.setState({ credential: data }, () => this.otpProvider?.onRequestOtp(data))
        // try {
        //     const todaysDate = new Date()
        //     this.props.onSetGlobalLoading(true)
        //     const _resp = await this.props.onVerifyEmail({ email: data.username })
        //     const _selectedUser = _resp?.data?.message?.selectedUser
        //     const _userResponse = _resp?.data?.message?.userResponse
        //     if (_userResponse === 'User found' && _selectedUser?.isTwoFactorEnabled) {
        //         let dtLastSentOTP = new Date()
        //         if (_selectedUser?.hasOwnProperty('dtLastAttemptOTP')) {
        //             dtLastSentOTP = new Date(_selectedUser?.dtLastAttemptOTP)
        //         }

        //         if (dtLastSentOTP.setHours(0, 0, 0, 0) >= todaysDate.setHours(0, 0, 0, 0)
        //         && _selectedUser?.otpAttemptCount >= 3) {
        //             this.setState({
        //                 isLoading: false,
        //                 isLockedAcc: true,
        //                 isAlertMessage: true,
        //                 errMsg: 'Maximum invalid attempt. Your account has been locked.'
        //             })
        //             this.props.onSetGlobalLoading(false)
        //         } else {
        //             const _data = {
        //                 userId: _selectedUser?.id,
        //                 flow: 'Login',
        //                 isUpdate: false,
        //                 template: 'email-2fa-otp'
        //             }
        //             this.setState({ credential: data })
        //             this.props.onClearOTP()
        //             this.props.requestOTP(_data)
        //                 .then(() => {
        //                     if (this.props.otpStatus === 'Successfully sent') {
        //                         this.props.onOpenOTPModal(true)
        //                     } else {
        //                         this.setState({ errMsg: this.props.otpStatus })
        //                     }
        //                     this.props.onSetGlobalLoading(false)
        //                 })
        //                 .catch(() => this.props.onSetGlobalLoading(false))
        //         }

        //     } else {
        //         this.props.loginUser(data.username, data.password)
        //         this.props.onSetGlobalLoading(false)
        //     }
        //     // if (_selectedUser?.isTwoFactorEnabled) {

        //     // } else {
        //     //     this.props.loginUser(data.username, data.password)
        //     // }
        //     console.log(_resp)
        // } catch (error) {
        //     console.error(error)
        //     this.props.onSetGlobalLoading(false)
        // }
    }

    onForgotPassword = () => {
        this.setState({ value: {} })
        this.props.onClearUser()
        this.props.navigation.navigate(RouteName.VerifyEmailPage)
    }

    componentWillUnmount() {
        this.props.onClearRegister()
    }

    render() {
        const isAllowed = validateFields('Login', this.props.systemFields)
        return (
            <BackgroundTemplate>
                <OtpProvider
                    exposedProperties={(otpProperties: OtpProperties) =>
                        (this.otpProvider = otpProperties)
                    }
                    flow="Login"
                    onProceedFlow={async () =>
                        await this.props.loginUser(
                            this.state.credential?.username,
                            this.state.credential?.password
                        )
                    }>
                    {/* {this.state.errMsg && <Pressable style={{ backgroundColor: '#F36564', paddingHorizontal: 20, paddingVertical: 10, borderRadius: 10, marginBottom: 20 }}>
                    <Text style={{ fontFamily: 'Roboto', fontSize: 16, color: 'white', textAlign: 'center' }}>{this.state.errMsg}</Text>
                </Pressable>}
                <OtpModal proceedFlow={() => this.props.loginUser(this.state.credential?.username, this.state.credential?.password)} flow='Login' /> */}
                    <Form
                        mode="onSubmit"
                        formSchema={FormSchema}
                        formStyle={styles.formStyle}
                        onSubmit={this.onLoginPressed}>
                        {this.props.isRegisterSuccess && (
                            <View style={styles.successMessage}>
                                <Text style={{ color: '#ffff', textAlign: 'center' }}>
                                    <FormattedMessage id="Login.msg.successRegister" />
                                </Text>
                            </View>
                        )}
                        {this.props.isFederatedError &&
                            this.props.errorMsg !== this.state.federatedSignInErr && (
                                <View style={styles.successMessage}>
                                    <Text style={{ color: '#ffff', textAlign: 'center' }}>
                                        <FormattedMessage id="Login.msg.relog" />
                                    </Text>
                                </View>
                            )}
                        {this.props.isError && this.props.errorMsg !== this.state.basicSignInErr ? (
                            <View style={styles.errorMessage}>
                                <Text style={{ color: 'white', textAlign: 'center' }}>
                                    Username or password is incorrect.
                                </Text>
                            </View>
                        ) : this.props.errorMsg === this.state.basicSignInErr ||
                          this.props.errorMsg === this.state.federatedSignInErr ? (
                            <View style={styles.errorMessage}>
                                <Text style={{ color: 'white', textAlign: 'center' }}>
                                    Your account has been locked
                                </Text>
                            </View>
                        ) : null}
                        <Screen minWidth={450}>
                            <Form.Field
                                style={styles.input}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="username"
                                type="textinput"
                                placeholder="Username"
                                rules={(value: any) => value.replace(/\s/g, '')}
                            />
                        </Screen>
                        <Screen maxWidth={449}>
                            <Form.Field
                                style={[styles.input, { width: 350, alignSelf: 'center' }]}
                                formGroupStyle={[
                                    styles.fieldFormGroupStyle,
                                    { alignSelf: 'center' }
                                ]}
                                labelStyle={styles.fieldLabelStyle}
                                name="username"
                                type="textinput"
                                placeholder="Username"
                                rules={(value: any) => value.replace(/\s/g, '')}
                            />
                        </Screen>
                        <Screen minWidth={450}>
                            <Form.Field
                                style={styles.input}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="password"
                                type="password"
                                placeholder="Password"
                                eyeIconSize={24}
                            />
                        </Screen>
                        <Screen maxWidth={449}>
                            <Form.Field
                                style={[styles.input, { width: 350, alignSelf: 'center' }]}
                                formGroupStyle={[
                                    styles.fieldFormGroupStyle,
                                    { alignSelf: 'center' }
                                ]}
                                labelStyle={styles.fieldLabelStyle}
                                name="password"
                                type="password"
                                placeholder="Password"
                                eyeIconSize={24}
                            />
                        </Screen>
                        <Form.Submit
                            loading={this.props.isLoading}
                            onPress={this.onLoginPressed}
                            title="LOGIN"
                            style={[styles.loginBtn]}
                            labelStyle={styles.textWhite}
                            disabled={!isAllowed}
                        />
                    </Form>

                    <Screen minWidth={450}>
                        <View style={{ marginLeft: 20, marginTop: 10 }}>
                            <TouchableOpacity onPress={() => this.onForgotPassword()}>
                                <Text
                                    style={[
                                        {
                                            textDecorationLine: 'underline',
                                            fontFamily: "'Montserrat', sans-serif",
                                            fontSize: 13,
                                            marginBottom: 20
                                        }
                                    ]}>
                                    Forgot Password?
                                </Text>
                            </TouchableOpacity>
                            <Text
                                style={{
                                    textAlign: 'justify',
                                    marginBottom: 20,
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontSize: 12,
                                    width: 400
                                }}>
                                Use your email or another service to continue with ArtShare (it's
                                free)!
                            </Text>
                            <TouchableOpacity
                                disabled={!isAllowed}
                                onPress={() => this.props.googleLoginUser()}
                                style={[styles.socialMediaBtns, { backgroundColor: '#F1F2F4' }]}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        flex: 1,
                                        justifyContent: 'center'
                                    }}>
                                    <Image
                                        source={require('../../assets/google.png')}
                                        style={{ width: 30, height: 30, marginLeft: 10 }}
                                        resizeMode="stretch"
                                    />
                                    <Text style={styles.btnText}>Continue with Google</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => this.props.fbLoginUser()}
                                disabled={!isAllowed}
                                style={[styles.socialMediaBtns, { backgroundColor: '#F1F2F4' }]}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        flex: 1,
                                        justifyContent: 'center'
                                    }}>
                                    <Icon
                                        name="facebook"
                                        color={'#14429C'}
                                        size={30}
                                        style={{ marginLeft: 10, width: 30 }}
                                    />
                                    <Text style={styles.btnText}>Continue with Facebook</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </Screen>

                    <Screen maxWidth={449}>
                        <View style={{ marginTop: 20 }}>
                            <Text
                                style={{
                                    marginLeft: 20,
                                    textAlign: 'justify',
                                    marginBottom: 20,
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontSize: 12,
                                    width: 220
                                }}>
                                Use your email or another service to continue with ArtShare (it's
                                free)!
                            </Text>
                            <TouchableOpacity
                                disabled={!isAllowed}
                                onPress={() => this.props.googleLoginUser()}
                                style={[
                                    styles.socialMediaBtns,
                                    { backgroundColor: '#F1F2F4', width: 220, alignSelf: 'center' }
                                ]}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        flex: 1,
                                        justifyContent: 'center'
                                    }}>
                                    <Image
                                        source={require('../../assets/google.png')}
                                        style={{ width: 30, height: 30, marginLeft: 10 }}
                                        resizeMode="stretch"
                                    />
                                    <Text style={styles.btnText}>Continue with Google</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity
                                disabled={!isAllowed}
                                onPress={() => this.props.fbLoginUser()}
                                style={[
                                    styles.socialMediaBtns,
                                    { backgroundColor: '#F1F2F4', width: 220, alignSelf: 'center' }
                                ]}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        flex: 1,
                                        justifyContent: 'center'
                                    }}>
                                    <Icon
                                        name="facebook"
                                        color={'#14429C'}
                                        size={30}
                                        style={{ marginLeft: 10 }}
                                    />
                                    <Text style={styles.btnText}>Continue with Facebook</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </Screen>
                </OtpProvider>
            </BackgroundTemplate>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        user: state.api?.user?.authUser,
        errorMsg: state.api?.user?.authUser?.error,
        isSignedIn: state.api?.user?.authUser?.isSignedIn,
        isRegisterSuccess: state.api?.user?.registerUser?.statusText === 'success',
        isLoading: state.api?.user.authUser.loading,
        isError: state.api?.user.authUser.statusText === 'error',
        isFederatedError: state.api?.user.authUser.statusText === 'relogin',
        otpStatus: state.api?.forgotPwd?.forgotPwdOtpReq?.statusText,
        isOtpModalOpen: state?.custom?.otpModal,
        systemFields: state.api?.systemParam?.getAllSysParams?.response ?? []
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loginUser: (username: string, password: string) => loginUser(dispatch, { username, password }),
    fbLoginUser: () => fbLoginUser(dispatch),
    googleLoginUser: () => googleLoginUser(dispatch),
    onClearUser: () => dispatch({ type: CLEAR_USER }),
    onClearModal: () => dispatch({ type: CLEAR_MODAL }),
    onClearRegister: () => dispatch(clearUserRegister()),
    onVerifyEmail: (data: any) => verifyEmail(dispatch, data),
    requestOTP: (data: any) => forgotPwdOtpReq(dispatch, data),
    onClearOTP: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ }),
    onOpenOTPModal: (value: boolean) => dispatch({ type: 'custom.otpModal', value }),
    onSetGlobalLoading: (value: boolean) => dispatch({ type: 'custom.isGlobalLoading', value })
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(LoginPage as any))
