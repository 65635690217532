import { AppRegistry, Platform } from 'react-native';
import { name as appName } from './app.json';
import './index.scss'
import 'react-image-gallery/styles/css/image-gallery.css'
import App from './src/App';
if (module.hot) {
    module.hot.accept();
}
if (Platform.OS === 'web') {
    AppRegistry.registerComponent(appName, () => App);
    AppRegistry.runApplication(appName, {
        initialProps: {},
        rootTag: document.getElementById('react-root'),
    });
}
