import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { View, Button, ScrollView, TextInput, Text, Image, Platform } from 'react-native'
import * as yup from 'yup'
import { debounce, isError, max } from 'lodash'
import { signUpStyles as styles } from './styles'

import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ConfigValue, ReduxAppState } from '../../utils/types'
import {
    ActivityIndicator,
    Avatar,
    Checkbox,
    HelperText,
    Modal,
    Paragraph,
    Portal,
    RadioButton,
    Title
} from 'react-native-paper'
import Logo from '../Login/components/Logo'
import { StackNavigationProp } from '@react-navigation/stack'
import { RouteName } from '../../navigation/types'
import * as _ from 'lodash'

import { registerUser, clearUserRegister } from '../../reducers/User/register'

import { Alert, BackgroundTemplate, Form, ResponsiveView, Screen } from '../../ReusableComponents/'
import { createWallet } from '../../reducers/Wallet/createWallet'
import Field from '../../ReusableComponents/Form/Field'
import Terms from '../Terms/Terms'
import recaptchaCreds from '../../utils/recaptcha'
import ReCAPTCHA from 'react-google-recaptcha'
import { validateFields } from '../../utils/systemValidator'
import { SystemProperties } from '../../reducers/SystemProperty'

const emailFormat =
    '^[a-zA-Z0-9]+([a-zA-Z0-9_.]+)*@[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*\\.([a-zA-Z0-9]{2,4})$'
const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const FormSchema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup
        .string()
        .required('Email Address is required')
        .test('email', 'Invalid email format', (value: any) => !!value.match(emailFormat))
        .min(16, 'Email Address must be between 16 and 40 characters long')
        .max(40, 'Email Address must be between 16 and 40 characters long'),
    confirmEmail: yup
        .string()
        .when('email', (_email, schema) => {
            return schema.test({
                test: () => Boolean(_email),
                message: 'Email Address field must have a value to verify'
            })
        })
        .oneOf([yup.ref('email'), null], "Emails don't match")
        .required('Confirm Email Address is required'),
    country: yup.string().required('Country is required'),
    region: yup.string().required('Region is required'),
    cityMunicipality: yup.string().required('City/Municipality is required'),
    mobileCode: yup.string().required(''),
    mobileNo: yup
        .string()
        .required('Mobile No. is required')
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(10, 'Mobile No. is too short')
        .max(10, 'Mobile No. is too long'),
    terms: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    privacy: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    recaptcha: yup.string().required()
})

export interface DispatchProps {
    onRegister: (data: any) => any
    onClearRegister: () => any
    onCreateWallet: (data: any) => any
}

export interface StateProps {
    config: ConfigValue
    isError: boolean
    isLoading: boolean
    errorMessage: string | undefined
    systemFields: SystemProperties[]
}

export interface OwnProps {
    intl: any
    navigation: StackNavigationProp<any>
}

export interface RegisterState {
    checkbox: CheckBox
    isLoading: boolean
    resetData: any
    country: string
    email: string
    isModal: boolean
    isVerified: boolean
    isSubmitted: boolean
    reCaptchatchaResponse: any
    selectedNewArtCollection: string
}

export type RegisterStateValue =
    | 'checkbox'
    | 'isShowValidIds'
    | 'isLoading'
    | 'userType'
    | 'country'
    | 'region'
    | 'province'
    | 'cityMunicipality'
    | 'barangay'
    | 'mobileCode'

export type RegisterProps = DispatchProps & StateProps & OwnProps

class RegisterComponent extends React.Component<RegisterProps, RegisterState> {
    reCaptcha: React.RefObject<any>
    constructor(props: RegisterProps) {
        super(props)
        this.state = {
            checkbox: {
                privacy: 'unchecked',
                terms: 'unchecked'
            },
            isLoading: false,
            isModal: false,
            isVerified: false,
            isSubmitted: false,
            country: 'Philippines',
            resetData: {},
            email: '',
            reCaptchatchaResponse: '',
            selectedNewArtCollection: 'YES'
        }
        this.reCaptcha = React.createRef()
    }

    componentDidMount() {
        this.props.onClearRegister()
    }

    onSubmit = (data: any) => {
        this.setState({ isSubmitted: true })

        if (
            !this.state.isVerified ||
            this.state.checkbox.terms === 'unchecked' ||
            this.state.checkbox.privacy === 'unchecked'
        ) {
            return
        }

        this.props
            .onRegister(data)
            .then((resp: any) => {
                this.props.navigation.push(RouteName.LoginPage)
                setTimeout(() => this.props.onClearRegister(), 10000)
            })
            .catch(() => {
                // error
            })
    }

    onChangeCheckbox = (key: 'privacy' | 'terms', onChange?: (value: any) => any) => {
        const _cb = { ...this.state.checkbox }
        _cb[key] = _cb[key] === 'unchecked' ? 'checked' : 'unchecked'
        this.setState({ checkbox: _cb })

        const _value = _cb[key] === 'unchecked' ? false : true
        if (onChange) {
            onChange(_value)
        }
    }

    clearFields = (key: RegisterStateValue) => {
        let fieldsToClear = {}
        if (key === 'country') {
            fieldsToClear = {
                region: '',
                province: '',
                cityMunicipality: '',
                barangay: ''
            }
        }

        return fieldsToClear
    }

    onChangeValue = (key: RegisterStateValue, value: any) => {
        if (this.state.country === 'Philippines' || value === 'Philippines') {
            const _newState: any = {}
            _newState[key] = value
            const fieldsToClear = this.clearFields(key)
            this.setState({ ..._newState, ...fieldsToClear, resetData: fieldsToClear })
        }
    }

    onSelectNewArtCollection = (value: string, onChange?: (value: any) => any) => {
        this.setState({ selectedNewArtCollection: value })
        if (onChange) {
            onChange(value)
        }
    }

    fetchDropDowns = (picker: string) => {
        let _ddl: any[] = []
        const { country, locations, numCode } = this?.props?.config
        if (picker === 'country') {
            _ddl = country?.map((item: any) => ({
                label: item.name,
                value: item.name
            }))
        } else if (picker === 'region') {
            _.forIn(locations, (value: string, key: string) => {
                const _temp = {
                    label: key,
                    value: key
                }
                _ddl.push(_temp)
            })
        } else if (picker === 'mobileCode') {
            _ddl = numCode?.map((item: any) => ({
                label: item.code,
                value: item.code
            }))
        }
        return _ddl
    }

    onClickModal = () => {
        this.setState({ isModal: !this.state.isModal })
    }

    onChangeRecaptcha = (result: any, onChange?: (value: any) => any) => {
        this.setState({
            isVerified: true,
            reCaptchatchaResponse: result
        })

        if (onChange) {
            onChange(result)
        }
    }

    renderTermOfUse = () => (
        <React.Fragment>
            <Text
                style={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    fontFamily: "'Montserrat', sans-serif"
                }}>
                Terms of Use
            </Text>
            <Paragraph
                style={{
                    fontWeight: '400',
                    fontSize: 15,
                    textAlign: 'justify',
                    fontFamily: "'Montserrat', sans-serif"
                }}>
                These Terms of Use (“Terms”) govern your use of the websites and/or mobile
                applications provided by ArtShare (Tokenized Art) (“Platforms”). By accessing and
                using the Platforms, you agree that you have read, understood and accepted the Terms
                including any additional terms and conditions and any policies referenced herein,
                available on the Platforms. If you do not agree or fall within the Terms, please do
                not use the Platforms. The Platforms may be used by natural and juridical persons.
                Where applicable, these Terms shall be subject to country-specific provisions as set
                out herein. Users below the age of 18 must obtain consent from a parent or legal
                guardian, who shall agree to take responsibility for your actions and any charges
                associated with your use of the Platforms and/or purchase of Goods and engagement of
                medical Services. ArtShare reserves the right to change or modify these Terms at any
                time. You will be deemed to have agreed to the amended Terms by your continued use
                of the Platforms following the date on which the amended Terms are posted.
            </Paragraph>
        </React.Fragment>
    )

    render() {
        const props = this.props
        const isAllowed = validateFields('Registration', props.systemFields)
        return (
            <BackgroundTemplate>
                <Form
                    formSchema={FormSchema}
                    formStyle={[styles.formStyle, {}]}
                    resetData={this.state.resetData}
                    onSubmit={this.onSubmit}>
                    {this.props.isError && (
                        <View style={styles.errorMessage}>
                            <Text>
                                {this.props.errorMessage || (
                                    <FormattedMessage id="SignUp.msg.somethingWentWrong" />
                                )}
                            </Text>
                        </View>
                    )}
                    <Alert
                        type="error"
                        visible={!isAllowed}
                        labelStyle={{ fontSize: 16 }}
                        style={{ alignSelf: 'center', width: '100%' }}>
                        This flow is not allowed. Please contact customer support.
                    </Alert>

                    {/* Web */}
                    <Screen
                        minWidth={450}
                        style={{
                            backgroundColor: '#E4E3E1',
                            borderRadius: 10,
                            marginHorizontal: 10
                        }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                paddingHorizontal: '5%',
                                paddingTop: '5%'
                            }}>
                            <View style={{ width: '50%' }}>
                                <Form.Field
                                    style={styles.input}
                                    formGroupStyle={styles.fieldFormGroupStyle}
                                    labelStyle={styles.fieldLabelStyle}
                                    name="firstName"
                                    label={props.intl.formatMessage({
                                        id: 'SignUp.msg.firstName'
                                    })}
                                    type="textinput"
                                />
                            </View>
                            <View style={{ width: '50%' }}>
                                <Form.Field
                                    style={styles.input}
                                    formGroupStyle={styles.fieldFormGroupStyle}
                                    labelStyle={styles.fieldLabelStyle}
                                    name="lastName"
                                    label={props.intl.formatMessage({
                                        id: 'SignUp.msg.lastName'
                                    })}
                                    type="textinput"
                                />
                            </View>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                paddingHorizontal: '5%'
                            }}>
                            <View style={{ width: '50%' }}>
                                <Form.Dropdown
                                    style={styles.input}
                                    formGroupStyle={styles.fieldFormGroupStyle}
                                    labelStyle={styles.fieldLabelStyle}
                                    name="country"
                                    label={props.intl.formatMessage({
                                        id: 'SignUp.msg.country'
                                    })}
                                    data={this.fetchDropDowns('country')}
                                    onChangeValue={(data) => this.onChangeValue('country', data)}
                                    defaultValue="Philippines"
                                />
                            </View>
                            <View style={{ width: '50%' }}>
                                <Form.Dropdown
                                    style={styles.input}
                                    formGroupStyle={styles.fieldFormGroupStyle}
                                    labelStyle={styles.fieldLabelStyle}
                                    name="region"
                                    label={props.intl.formatMessage({
                                        id: 'SignUp.msg.region'
                                    })}
                                    data={this.fetchDropDowns('region')}
                                    onChangeValue={(data) => this.onChangeValue('region', data)}
                                    defaultValue="NCR"
                                />
                            </View>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                paddingHorizontal: '5%'
                            }}>
                            <View style={{ width: '50%' }}>
                                <Form.Field
                                    style={styles.input}
                                    formGroupStyle={styles.fieldFormGroupStyle}
                                    labelStyle={styles.fieldLabelStyle}
                                    name="unitNo"
                                    label={props.intl.formatMessage({
                                        id: 'SignUp.msg.unitNo'
                                    })}
                                    type="textinput"
                                />
                            </View>
                            <View style={{ width: '50%' }}>
                                <Form.Field
                                    style={styles.input}
                                    formGroupStyle={styles.fieldFormGroupStyle}
                                    labelStyle={styles.fieldLabelStyle}
                                    name="street"
                                    label={props.intl.formatMessage({
                                        id: 'SignUp.msg.streetName'
                                    })}
                                    type="textinput"
                                />
                            </View>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                paddingHorizontal: '5%'
                            }}>
                            <View style={{ width: '50%' }}>
                                <Form.Field
                                    style={styles.input}
                                    formGroupStyle={styles.fieldFormGroupStyle}
                                    labelStyle={styles.fieldLabelStyle}
                                    name="cityMunicipality"
                                    label={props.intl.formatMessage({
                                        id: 'SignUp.msg.cityMunicipality'
                                    })}
                                    type="textinput"
                                />
                            </View>
                            <View style={{ width: '50%' }}>
                                <Form.Field
                                    style={styles.input}
                                    formGroupStyle={styles.fieldFormGroupStyle}
                                    labelStyle={styles.fieldLabelStyle}
                                    name="barangay"
                                    label={props.intl.formatMessage({
                                        id: 'SignUp.msg.barangay'
                                    })}
                                    type="textinput"
                                />
                            </View>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                paddingHorizontal: '5%'
                            }}>
                            <View style={{ width: '50%' }}>
                                <Form.Field
                                    style={styles.input}
                                    formGroupStyle={styles.fieldFormGroupStyle}
                                    labelStyle={styles.fieldLabelStyle}
                                    name="email"
                                    label={props.intl.formatMessage({ id: 'SignUp.msg.email' })}
                                    type="textinput"
                                    rules={(value: any) => value.replace(/\s/g, '')}
                                    onChangeValue={(text: string) => this.setState({ email: text })}
                                />
                            </View>
                            <View style={{ width: '50%' }}>
                                <Form.Field
                                    style={styles.input}
                                    formGroupStyle={styles.fieldFormGroupStyle}
                                    labelStyle={styles.fieldLabelStyle}
                                    name="confirmEmail"
                                    label={props.intl.formatMessage({
                                        id: 'SignUp.msg.confirmEmail'
                                    })}
                                    type="textinput"
                                    rules={(value: any) => value.replace(/\s/g, '')}
                                />
                            </View>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                paddingHorizontal: '5%'
                            }}>
                            <View style={{ width: '50%', flexDirection: 'row' }}>
                                <View style={{ width: '40%', marginLeft: '4%' }}>
                                    <Form.Dropdown
                                        style={[styles.input]}
                                        formGroupStyle={[styles.fieldFormGroupStyle]}
                                        labelStyle={styles.fieldLabelStyle}
                                        name="mobileCode"
                                        label={props.intl.formatMessage({
                                            id: 'SignUp.msg.mobileNo'
                                        })}
                                        data={this.fetchDropDowns('mobileCode')}
                                        onChangeValue={(data) =>
                                            this.onChangeValue('mobileCode', data)
                                        }
                                        defaultValue="63"
                                    />
                                </View>
                                <View style={{ width: '55%' }}>
                                    <Form.Field
                                        style={[styles.input]}
                                        formGroupStyle={[styles.fieldFormGroupStyle]}
                                        labelStyle={[styles.fieldLabelStyle, { color: '#E4E3E1' }]}
                                        label={'*'}
                                        name="mobileNo"
                                        type="textinput"
                                        rules={(value: any) => value.replace(/\s/g, '')}
                                        onChangeValue={(text: string) =>
                                            this.setState({ email: text })
                                        }
                                    />{' '}
                                </View>
                            </View>
                            <View style={{ width: '50%', marginLeft: '4%' }}>
                                <Form.CustomField
                                    name="newToArt"
                                    defaultValue={this.state.selectedNewArtCollection}
                                    customField={(value, onChange, error) => {
                                        return (
                                            <View style={{ width: '90%' }}>
                                                <Text
                                                    style={[
                                                        styles.fieldLabelStyle,
                                                        { marginBottom: 8, color: '#003849' }
                                                    ]}>
                                                    <FormattedMessage id="SignUp.msg.newToArt" />
                                                </Text>
                                                <RadioButton.Group
                                                    value={this.state.selectedNewArtCollection}
                                                    onValueChange={(val) =>
                                                        this.onSelectNewArtCollection(val, onChange)
                                                    }>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            backgroundColor: 'white',
                                                            paddingVertical: 5,
                                                            paddingHorizontal: 15,
                                                            borderRadius: 15,
                                                            marginBottom: 5
                                                        }}>
                                                        <RadioButton value={'YES'} color="black" />
                                                        <Text
                                                            style={{
                                                                alignSelf: 'center',
                                                                marginRight: 20
                                                            }}>
                                                            YES
                                                        </Text>

                                                        <RadioButton value={'NO'} color="black" />
                                                        <Text style={{ alignSelf: 'center' }}>
                                                            NO
                                                        </Text>
                                                    </View>
                                                </RadioButton.Group>
                                            </View>
                                        )
                                    }}
                                />
                            </View>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                paddingHorizontal: '5%'
                            }}>
                            <View style={{ width: '50%', marginLeft: '4%' }}>
                                <Form.CustomField
                                    name="recaptcha"
                                    customField={(value, onChange, error) => {
                                        return (
                                            <View style={{ marginLeft: 5 }}>
                                                <Text
                                                    style={[
                                                        styles.policyAndTerms,
                                                        {
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            color: '#003849',
                                                            marginBottom: 10
                                                        }
                                                    ]}>
                                                    Please check the box below to proceed.
                                                </Text>
                                                <ReCAPTCHA
                                                    ref={this.reCaptcha}
                                                    sitekey={recaptchaCreds.siteKey}
                                                    onChange={(val) =>
                                                        this.onChangeRecaptcha(val, onChange)
                                                    }
                                                    onExpired={() => {
                                                        this.reCaptcha.current.reset()
                                                    }}
                                                />
                                                {error && (
                                                    <Text
                                                        style={[
                                                            styles.policyAndTerms,
                                                            {
                                                                fontSize: 12,
                                                                color: '#AD001F',
                                                                marginTop: 5
                                                            }
                                                        ]}>
                                                        reCaptcha is required
                                                    </Text>
                                                )}
                                            </View>
                                        )
                                    }}
                                />
                            </View>
                            <View style={{ width: '50%' }}>
                                <Form.CustomField
                                    name="terms"
                                    defaultValue={false}
                                    customField={(value, onChange, error) => {
                                        return (
                                            <React.Fragment>
                                                <View
                                                    style={[
                                                        styles.rowContainer,
                                                        { marginTop: 20 }
                                                    ]}>
                                                    <Checkbox
                                                        uncheckedColor={
                                                            error ? `#F15927` : '#848485'
                                                        }
                                                        color="#14429C"
                                                        onPress={() =>
                                                            this.onChangeCheckbox('terms', onChange)
                                                        }
                                                        status={this.state.checkbox.terms}
                                                    />
                                                    <Text
                                                        style={[
                                                            styles.policyAndTerms,
                                                            {
                                                                fontSize: 14,
                                                                fontWeight: 'bold',
                                                                color: '#003849'
                                                            }
                                                        ]}>
                                                        I agree to the{' '}
                                                        <Text
                                                            style={[
                                                                styles.policyAndTerms,
                                                                {
                                                                    fontSize: 14,
                                                                    fontWeight: 'bold',
                                                                    color: '#003849',
                                                                    textDecorationLine: 'underline'
                                                                }
                                                            ]}
                                                            onPress={() => this.onClickModal()}>
                                                            Terms and Conditions
                                                        </Text>
                                                    </Text>
                                                </View>
                                                {error && (
                                                    <Text
                                                        style={[
                                                            styles.policyAndTerms,
                                                            {
                                                                color: '#AD001F',
                                                                marginTop: 5,
                                                                marginLeft: 10
                                                            }
                                                        ]}>
                                                        Terms and Conditions is required
                                                    </Text>
                                                )}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                                <Form.CustomField
                                    name="privacy"
                                    defaultValue={false}
                                    customField={(value, onChange, error) => {
                                        return (
                                            <React.Fragment>
                                                <View style={[styles.rowContainer]}>
                                                    <Checkbox
                                                        uncheckedColor={
                                                            error ? `#FF0000` : '#848485'
                                                        }
                                                        color="#14429C"
                                                        onPress={() =>
                                                            this.onChangeCheckbox(
                                                                'privacy',
                                                                onChange
                                                            )
                                                        }
                                                        status={this.state.checkbox.privacy}
                                                    />
                                                    <Text
                                                        style={[
                                                            styles.policyAndTerms,
                                                            {
                                                                fontSize: 14,
                                                                fontWeight: 'bold',
                                                                color: '#003849'
                                                            }
                                                        ]}>
                                                        I understand the{' '}
                                                        <Text
                                                            style={[
                                                                styles.policyAndTerms,
                                                                {
                                                                    fontSize: 14,
                                                                    fontWeight: 'bold',
                                                                    textDecorationLine: 'underline',
                                                                    color: '#003849'
                                                                }
                                                            ]}
                                                            onPress={() => this.onClickModal()}>
                                                            <FormattedMessage id="SignUp.msg.privacyPolicy" />
                                                        </Text>
                                                    </Text>
                                                </View>
                                                {error && (
                                                    <View>
                                                        <Text
                                                            style={[
                                                                styles.policyAndTerms,
                                                                {
                                                                    color: '#AD001F',
                                                                    marginTop: 5,
                                                                    marginLeft: 10
                                                                }
                                                            ]}>
                                                            Privacy Policy is required
                                                        </Text>
                                                    </View>
                                                )}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            </View>
                        </View>
                        <Form.Submit
                            loading={this.props.isLoading}
                            onPress={this.onSubmit}
                            title="Create Account"
                            style={styles.registerBtn}
                            labelStyle={styles.textWhite}
                            disabled={!isAllowed}
                        />
                    </Screen>

                    {/* Tablet and Mobile */}
                    <Screen
                        maxWidth={449}
                        style={{
                            backgroundColor: '#E4E3E1',
                            padding: '5%',
                            borderRadius: 10,
                            marginHorizontal: 10,
                            marginVertical: 20
                        }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 20
                                // display: 'flex'
                            }}>
                            <Form.Field
                                style={[styles.input, { width: 320 }]}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="firstName"
                                label={props.intl.formatMessage({
                                    id: 'SignUp.msg.firstName'
                                })}
                                type="textinput"
                            />

                            <Form.Field
                                style={[styles.input, { width: 320 }]}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="lastName"
                                label={props.intl.formatMessage({
                                    id: 'SignUp.msg.lastName'
                                })}
                                type="textinput"
                            />

                            <Form.Dropdown
                                style={[styles.input, { width: 320 }]}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="country"
                                label={props.intl.formatMessage({
                                    id: 'SignUp.msg.country'
                                })}
                                data={this.fetchDropDowns('country')}
                                onChangeValue={(data) => this.onChangeValue('country', data)}
                                defaultValue="Philippines"
                            />

                            <Form.Dropdown
                                style={[styles.input, { width: 320 }]}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="region"
                                label={props.intl.formatMessage({
                                    id: 'SignUp.msg.region'
                                })}
                                data={this.fetchDropDowns('region')}
                                onChangeValue={(data) => this.onChangeValue('region', data)}
                                defaultValue="NCR"
                            />
                            <Form.Field
                                style={[styles.input, { width: 320 }]}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="unitNo"
                                label={props.intl.formatMessage({
                                    id: 'SignUp.msg.unitNo'
                                })}
                                type="textinput"
                            />
                            <Form.Field
                                style={[styles.input, { width: 320 }]}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="street"
                                label={props.intl.formatMessage({
                                    id: 'SignUp.msg.streetName'
                                })}
                                type="textinput"
                            />

                            <Form.Field
                                style={[styles.input, { width: 320 }]}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="cityMunicipality"
                                label={props.intl.formatMessage({
                                    id: 'SignUp.msg.cityMunicipality'
                                })}
                                type="textinput"
                            />
                            <Form.Field
                                style={[styles.input, { width: 320 }]}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="barangay"
                                label={props.intl.formatMessage({
                                    id: 'SignUp.msg.barangay'
                                })}
                                type="textinput"
                            />

                            <View
                                style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    marginLeft: '6%'
                                }}>
                                <View style={{ width: '30%' }}>
                                    <Form.Dropdown
                                        style={[styles.input]}
                                        formGroupStyle={[styles.fieldFormGroupStyle]}
                                        labelStyle={styles.fieldLabelStyle}
                                        name="mobileCode"
                                        label={props.intl.formatMessage({
                                            id: 'SignUp.msg.mobileNo'
                                        })}
                                        data={this.fetchDropDowns('mobileCode')}
                                        onChangeValue={(data) =>
                                            this.onChangeValue('mobileCode', data)
                                        }
                                        defaultValue="63"
                                    />
                                </View>
                                <View style={{ width: '55%' }}>
                                    <Form.Field
                                        style={[styles.input]}
                                        formGroupStyle={[styles.fieldFormGroupStyle]}
                                        labelStyle={[styles.fieldLabelStyle, { color: '#E4E3E1' }]}
                                        label={'*'}
                                        name="mobileNo"
                                        type="textinput"
                                        rules={(value: any) => value.replace(/\s/g, '')}
                                        onChangeValue={(text: string) =>
                                            this.setState({ email: text })
                                        }
                                    />{' '}
                                </View>
                            </View>

                            <Form.Field
                                style={[styles.input, { width: 320 }]}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="email"
                                label={props.intl.formatMessage({ id: 'SignUp.msg.email' })}
                                type="textinput"
                                rules={(value: any) => value.replace(/\s/g, '')}
                                onChangeValue={(text: string) => this.setState({ email: text })}
                            />

                            <Form.Field
                                style={[styles.input, { width: 320 }]}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                labelStyle={styles.fieldLabelStyle}
                                name="confirmEmail"
                                label={props.intl.formatMessage({
                                    id: 'SignUp.msg.confirmEmail'
                                })}
                                type="textinput"
                                rules={(value: any) => value.replace(/\s/g, '')}
                            />
                        </View>

                        <View style={{ marginLeft: '8%' }}>
                            <Form.CustomField
                                name="newToArt"
                                defaultValue={this.state.selectedNewArtCollection}
                                customField={(value, onChange, error) => {
                                    return (
                                        <View style={{ width: '90%' }}>
                                            <Text
                                                style={[
                                                    styles.fieldLabelStyle,
                                                    { marginBottom: 8, color: '#003849' }
                                                ]}>
                                                <FormattedMessage id="SignUp.msg.newToArt" />
                                            </Text>
                                            <RadioButton.Group
                                                value={this.state.selectedNewArtCollection}
                                                onValueChange={(val) =>
                                                    this.onSelectNewArtCollection(val, onChange)
                                                }>
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        backgroundColor: 'white',
                                                        paddingVertical: 5,
                                                        paddingHorizontal: 15,
                                                        borderRadius: 15,
                                                        marginBottom: 5
                                                    }}>
                                                    <RadioButton value={'YES'} color="black" />
                                                    <Text
                                                        style={{
                                                            alignSelf: 'center',
                                                            marginRight: 20
                                                        }}>
                                                        YES
                                                    </Text>

                                                    <RadioButton value={'NO'} color="black" />
                                                    <Text style={{ alignSelf: 'center' }}>NO</Text>
                                                </View>
                                            </RadioButton.Group>
                                        </View>
                                    )
                                }}
                            />
                        </View>

                        <View style={{ marginLeft: '15%', marginTop: 20 }}>
                            <Form.CustomField
                                name="terms"
                                defaultValue={false}
                                customField={(value, onChange, error) => {
                                    return (
                                        <React.Fragment>
                                            <View style={[styles.rowContainer]}>
                                                <Checkbox
                                                    uncheckedColor={error ? `#F15927` : '#848485'}
                                                    color="#14429C"
                                                    onPress={() =>
                                                        this.onChangeCheckbox('terms', onChange)
                                                    }
                                                    status={this.state.checkbox.terms}
                                                />
                                                <Text style={styles.policyAndTerms}>
                                                    I agree to the{' '}
                                                    <Text
                                                        style={[
                                                            styles.policyAndTerms,
                                                            {
                                                                textDecorationLine: 'underline'
                                                            }
                                                        ]}
                                                        onPress={() => this.onClickModal()}>
                                                        Terms and Conditions
                                                    </Text>
                                                </Text>
                                            </View>
                                            {error && (
                                                <Text
                                                    style={[
                                                        styles.policyAndTerms,
                                                        {
                                                            color: '#AD001F',
                                                            marginTop: 5,
                                                            marginLeft: 10
                                                        }
                                                    ]}>
                                                    Terms and Conditions is required
                                                </Text>
                                            )}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </View>

                        <View style={{ marginLeft: '15%' }}>
                            <Form.CustomField
                                name="privacy"
                                defaultValue={false}
                                customField={(value, onChange, error) => {
                                    return (
                                        <React.Fragment>
                                            <View style={[styles.rowContainer]}>
                                                <Checkbox
                                                    uncheckedColor={error ? `#FF0000` : '#848485'}
                                                    color="#14429C"
                                                    onPress={() =>
                                                        this.onChangeCheckbox('privacy', onChange)
                                                    }
                                                    status={this.state.checkbox.privacy}
                                                />
                                                <Text style={styles.policyAndTerms}>
                                                    I understand the{' '}
                                                    <Text
                                                        style={[
                                                            styles.policyAndTerms,
                                                            {
                                                                textDecorationLine: 'underline'
                                                            }
                                                        ]}
                                                        onPress={() => this.onClickModal()}>
                                                        <FormattedMessage id="SignUp.msg.privacyPolicy" />
                                                    </Text>
                                                </Text>
                                            </View>
                                            {error && (
                                                <View>
                                                    <Text
                                                        style={[
                                                            styles.policyAndTerms,
                                                            {
                                                                color: '#AD001F',
                                                                marginTop: 5,
                                                                marginLeft: 10
                                                            }
                                                        ]}>
                                                        Privacy Policy is required
                                                    </Text>
                                                </View>
                                            )}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </View>

                        <View style={{ alignSelf: 'center' }}>
                            <Form.CustomField
                                name="recaptcha"
                                customField={(value, onChange, error) => {
                                    return (
                                        <View style={{ marginLeft: 5 }}>
                                            <Text
                                                style={[
                                                    styles.policyAndTerms,
                                                    {
                                                        marginTop: 20,
                                                        fontSize: 13,
                                                        marginBottom: 10
                                                    }
                                                ]}>
                                                Please check the box below to proceed.
                                            </Text>
                                            <ReCAPTCHA
                                                ref={this.reCaptcha}
                                                sitekey={recaptchaCreds.siteKey}
                                                onChange={(val) =>
                                                    this.onChangeRecaptcha(val, onChange)
                                                }
                                                onExpired={() => {
                                                    this.reCaptcha.current.reset()
                                                }}
                                            />
                                            {error && (
                                                <Text
                                                    style={[
                                                        styles.policyAndTerms,
                                                        { color: '#AD001F', marginTop: 5 }
                                                    ]}>
                                                    reCaptcha is required
                                                </Text>
                                            )}
                                        </View>
                                    )
                                }}
                            />
                        </View>

                        <Form.Submit
                            loading={this.props.isLoading}
                            onPress={this.onSubmit}
                            title="Create Account"
                            style={[styles.registerBtn, { marginBottom: 20, width: '60%' }]}
                            labelStyle={styles.textWhite}
                            disabled={!isAllowed}
                        />
                    </Screen>
                </Form>
                <Terms showModal={this.state.isModal} closeModal={this.onClickModal} />
            </BackgroundTemplate>
        )
    }
}

export interface CheckBox {
    privacy: 'unchecked' | 'checked' | 'indeterminate'
    terms: 'unchecked' | 'checked' | 'indeterminate'
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        config: state.config,
        isLoading: state.api.user.registerUser.loading,
        isError: state.api?.user?.registerUser?.statusText === 'error',
        errorMessage: state.api?.user?.registerUser?.error,
        systemFields: state.api?.systemParam?.getAllSysParams?.response ?? []
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onRegister: (data: any) => registerUser(dispatch, data),
    onClearRegister: () => dispatch(clearUserRegister()),
    onCreateWallet: (data: any) => createWallet(dispatch, data)
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegisterComponent))
