import React from 'react'

import { View, Text, TextInput } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { RouteName } from '../../navigation/types'

import { Button, IconButton, Title } from 'react-native-paper'

import { forgotPwdStyles as styles } from './styles'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import * as _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { AxiosPromise } from 'axios'

import { BackgroundTemplate, Screen } from '../../ReusableComponents'
import { changeUserPassword, CLEAR_USER_CHANGE_PASSWORD, setTempUserPassword } from '../../reducers/ForgotPassword/changePassword'
import { User } from '../../reducers/User'
import { CLEAR_FORGOT_PWD_OTP_REQ, forgotPwdOtpReq } from '../../reducers/ForgotPassword/otpRequest'
import { AlertMessage } from './component/AlertMessage'
import { responseCode, responseMsg } from '../../utils/response'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean,
    route: any
}

export interface StateProps {
    currentUser?: User
    tempData?: any
    changePwdResp: any
    isLoadingOTPReq: boolean
    otpReqStatus: string
}

export interface DispatchProps {
    changeUserPassword: (data: any) => AxiosPromise,
    setTempUserPassword: (data: any) => any
    onClearChangePwd: () => any,
    forgotPwdOtpReq: (data: any) => any
    onClearForgotPwdOtpReq: () => any
}

export interface ChangePasswordPageState {
    value: any,
    isSubmit: boolean,
    validationValue: any,
    isLoading: boolean,
    isError: boolean,
    errMsg: string,
    isShowNewPassword: boolean
    isShowVerifyPassword: boolean
    isAlertMessage: boolean
}
export const notRequiredFields = []

export type ChangePasswordPageProps = ComponentProps & StateProps & DispatchProps

class ChangePasswordPage extends React.Component<ChangePasswordPageProps, ChangePasswordPageState> {
    passwordInput: TextInput | null | undefined
    verifyPasswordInput: TextInput | null | undefined
    constructor(props: ChangePasswordPageProps) {
        super(props)
        this.state = {
            isSubmit: false,
            isLoading: false,
            isError: false,
            isShowNewPassword: false,
            isShowVerifyPassword: false,
            isAlertMessage: false,
            value: {
                password: '',
                verifyPassword: '',
            },
            validationValue: {},
            errMsg: ''
        }
    }

    componentDidMount() {
        this.props.onClearForgotPwdOtpReq()
        this.props.onClearChangePwd()
    }

    onChangeValue = (key: string, value: string) => {
        const _value = { ...this.state.value }
        _value[key] = value
        this.setState({ value: _value })
    }

    passwordResultColor = (key: string) => {
        const password = this.state.value?.password
        if (!password) {
            return styles.plainColor
        }
        let isSuccess = false
        if (key === 'lengthValidation') {
            isSuccess = password.length >= 8
        } else if (key === 'lowercaseValidation') {
            isSuccess = Boolean(password.match(/[a-z]/g))
        } else if (key === 'uppercaseValidation') {
            isSuccess = Boolean(password.match(/[A-Z]/g))
        } else if (key === 'specialcharValidation') {
            isSuccess = Boolean(password.match(/[!@#$%^&*]/g))
        } else {
            isSuccess = Boolean(password.match(/[0-9]/g))
        }

        if (isSuccess) {
            return styles.successColor
        }

        return styles.errorColor
    }

    fieldValidator = (inputValue: string, fieldName: string) => {
        if (!inputValue) return `${fieldName} can't be empty.`
        return ''
    }

    onValidateField = (key: string, value?: string | undefined) => {
        const _validationValue = { ...this.state.validationValue }

        if (key === 'verifyPassword') {
            _validationValue[key] = {
                error: Boolean(this.state.value?.verifyPassword) && this.state.value.verifyPassword !== this.state.value.password
            }
        } else {
            _validationValue[key] = {
                error: Boolean(value)
            }
        }

        this.setState({ validationValue: _validationValue })
    }

    onSubmit = () => {
        this.setState({ isSubmit: true })
        const _value = { ...this.state.value }
        let isSuccessValidation: boolean = true
        _.forIn(_value, (value: any, key: any) => {
            if (!Boolean(value)) {
                isSuccessValidation = Boolean(notRequiredFields.find((item: string) => item === key))
            }
        })

        const password = _value?.password
        const verifyPassword = _value?.verifyPassword

        if (password.length < 8 || verifyPassword.length < 8) {
            isSuccessValidation = false
        }

        if (!Boolean(password.match(/[a-z]/g)) || !Boolean(password.match(/[A-Z]/g)) ||
            !Boolean(password.match(/[!@#$%^&*]/g)) || !Boolean(password.match(/[0-9]/g))) {
            isSuccessValidation = false
        }

        if (Boolean(verifyPassword) && verifyPassword !== password) {
            isSuccessValidation = false
        }

        if (isSuccessValidation) {
            const todaysDate = new Date()
            let dtLastSentOTP = new Date()
            if (this.props.currentUser?.hasOwnProperty('dtLastAttemptOTPChangePwd')) {
                dtLastSentOTP = new Date(this.props.currentUser?.dtLastAttemptOTPChangePwd)
            }

            if (dtLastSentOTP.setHours(0, 0, 0, 0) >= todaysDate.setHours(0, 0, 0, 0)
                && this.props.currentUser?.otpAttemptCountChangePwd >= 3) {
                this.setState({
                    isLoading: false,
                    isAlertMessage: true,
                    errMsg: 'Maximum invalid attempt. \nYour may try again tomorrow.'
                })
            } else {
                const data = {
                    userId: this.props.currentUser?.id,
                    isUpdate: false,
                    isChangePwd: true
                }
                this.props.onClearForgotPwdOtpReq()
                this.props.forgotPwdOtpReq(data)
                    .then(async () => {
                        if (!this.props.isLoadingOTPReq && this.props.otpReqStatus !== responseCode.OTP_SUCCESSFUL) {
                            // Max OTP sent count or Max Invalid Attempt
                            this.setState({
                                isLoading: false,
                                isAlertMessage: true,
                                errMsg: responseMsg[this.props.otpReqStatus]
                            })
                        } else {
                            this.setState({
                                isLoading: false,
                                isAlertMessage: false,
                                errMsg: ''
                            })

                            /// set change password data
                            const tempData = {
                                email: this.props?.currentUser?.email,
                                name: this.props?.currentUser?.name,
                                password
                            }

                            await this.props.setTempUserPassword(tempData)
                            if (this.props.otpReqStatus === responseCode.OTP_SUCCESSFUL) {
                                this.props.navigation.navigate(RouteName.VerifyOTPPage)
                            }
                        }
                    })
            }
        }
    }

    onNavigateToDashboard = () => {
        this.setState({ isAlertMessage: false, isSubmit: false, isLoading: false })
        this.props.navigation.navigate(RouteName.DashboardPage)
    }

    render() {
        const { validationValue, isSubmit, value, isShowNewPassword, isShowVerifyPassword, isAlertMessage } = this.state
        const eyeIconNewPw = isShowNewPassword ? 'eye' : 'eye-off'
        const eyeIconVerifyPw = isShowVerifyPassword ? 'eye' : 'eye-off'
        return (
            <BackgroundTemplate>
                {!isAlertMessage ? (
                    <View style={[styles.formStyle]}>
                        <View style={{ marginLeft: 10, marginBottom: 20 }}>
                            <Title style={[styles.textStyle, styles.title, { fontSize: 24 }]}>Change Password</Title>
                        </View>

                        {this.state.isError && (
                            <View style={{ backgroundColor: 'red', padding: 10, maxWidth: 250, alignSelf: 'center', width: '100%', marginBottom: 10 }}>
                                <Text style={{ color: '#ffff', textAlign: 'center' }}>{this.state.errMsg}</Text>
                            </View>
                        )}
                        <View style={{ alignSelf: 'flex-start' }}>
                            <Screen minWidth={400} style={styles.formGroup}>
                                <View style={{ flexDirection: 'row', position: 'relative' }}>
                                    <TextInput
                                        ref={input => { this.passwordInput = input }}
                                        style={isSubmit && !value?.password ? [styles.input, styles.errorBorder] : styles.input}
                                        secureTextEntry={!isShowNewPassword}
                                        onChangeText={(_value: string) => this.onChangeValue('password', _value)}
                                        placeholder="New Password"
                                        placeholderTextColor='#C0C1C2'
                                        onSubmitEditing={this.onSubmit}
                                        clearButtonMode="always"
                                    />
                                    <IconButton
                                        icon={eyeIconNewPw}
                                        onPress={() => this.setState({ isShowNewPassword: !isShowNewPassword })}
                                        style={{ position: 'absolute', right: 0 }}
                                        size={24}
                                    />
                                </View>

                            </Screen>
                            <Screen maxWidth={399} style={styles.formGroup}>
                                <View style={{ flexDirection: 'row', position: 'relative' }}>
                                    <TextInput
                                        ref={input => { this.passwordInput = input }}
                                        style={[styles.input, isSubmit && !value?.password && styles.errorBorder, { alignSelf: 'center', width: 250 }]}
                                        secureTextEntry={!isShowNewPassword}
                                        onChangeText={(_value: string) => this.onChangeValue('password', _value)}
                                        placeholder="New Password"
                                        placeholderTextColor='#C0C1C2'
                                        onSubmitEditing={this.onSubmit}
                                        clearButtonMode="always"
                                    />
                                    <IconButton
                                        icon={eyeIconNewPw}
                                        onPress={() => this.setState({ isShowNewPassword: !isShowNewPassword })}
                                        style={{ position: 'absolute', right: 0 }}
                                        size={24}
                                    />
                                </View>
                            </Screen>
                            <Screen minWidth={400} style={styles.formGroup}>
                                <View style={{ flexDirection: 'row', position: 'relative' }}>
                                    <TextInput
                                        ref={input => { this.verifyPasswordInput = input }}
                                        secureTextEntry={!isShowVerifyPassword}
                                        style={isSubmit && !value?.verifyPassword ? [styles.input, styles.errorBorder] : styles.input}
                                        onBlur={() => this.onValidateField('verifyPassword')}
                                        onChangeText={(_value: string) => this.onChangeValue('verifyPassword', _value)}
                                        placeholder="Verify Password"
                                        placeholderTextColor='#C0C1C2'
                                        onSubmitEditing={this.onSubmit}
                                        clearButtonMode="always"
                                    />
                                    <IconButton
                                        icon={eyeIconVerifyPw}
                                        onPress={() => this.setState({ isShowVerifyPassword: !isShowVerifyPassword })}
                                        style={{ position: 'absolute', right: 0 }}
                                        size={24}
                                    />
                                </View>

                                {validationValue?.verifyPassword?.error && (
                                    <View style={styles.errorContainer}>
                                        <Text style={styles.errorColor}><FormattedMessage id='ForgotPwd.msg.passwordNotMatch' /></Text>
                                    </View>
                                )}
                            </Screen>
                            <Screen maxWidth={399} style={styles.formGroup}>
                                <View style={{ flexDirection: 'row', position: 'relative' }}>
                                    <TextInput
                                        ref={input => { this.verifyPasswordInput = input }}
                                        secureTextEntry={!isShowVerifyPassword}
                                        style={[styles.input, isSubmit && !value?.verifyPassword && styles.errorBorder, { alignSelf: 'center', width: 250 }]}
                                        onBlur={() => this.onValidateField('verifyPassword')}
                                        onChangeText={(_value: string) => this.onChangeValue('verifyPassword', _value)}
                                        placeholder="Verify Password"
                                        placeholderTextColor='#C0C1C2'
                                        onSubmitEditing={this.onSubmit}
                                        clearButtonMode="always"
                                    />
                                    <IconButton
                                        icon={eyeIconVerifyPw}
                                        onPress={() => this.setState({ isShowVerifyPassword: !isShowVerifyPassword })}
                                        style={{ position: 'absolute', right: 0 }}
                                        size={24}
                                    />
                                </View>

                                {validationValue?.verifyPassword?.error && (
                                    <View style={styles.errorContainer}>
                                        <Text style={styles.errorColor}><FormattedMessage id='ForgotPwd.msg.passwordNotMatch' /></Text>
                                    </View>
                                )}
                            </Screen>
                            <View style={styles.requirementsContainer}>
                                <Text style={[styles.text, this.passwordResultColor('lengthValidation')]}><FormattedMessage id='ForgotPwd.msg.minimunPasswordLengthValidation' /></Text>
                                <Text style={[styles.text, this.passwordResultColor('uppercaseValidation')]}><FormattedMessage id='ForgotPwd.msg.uppercaseValidation' /></Text>
                                <Text style={[styles.text, this.passwordResultColor('lowercaseValidation')]}><FormattedMessage id='ForgotPwd.msg.lowercaseValidation' /></Text>
                                <Text style={[styles.text, this.passwordResultColor('specialcharValidation')]}><FormattedMessage id='ForgotPwd.msg.specialcharValidation' /></Text>
                                <Text style={[styles.text, this.passwordResultColor('numberValidation')]}><FormattedMessage id='ForgotPwd.msg.numberValidation' /></Text>
                            </View>
                            <View style={[styles.formGroup2]}>
                                <Button loading={this.state.isLoading} onPress={this.onSubmit} style={[styles.registerBtn, { width: 250 }]} labelStyle={styles.textWhite}>Reset Password</Button>
                            </View>
                        </View>
                    </View>
                ) : (
                    <AlertMessage
                        navigateToDashboard={() => this.onNavigateToDashboard()}
                        isSuccess={false}
                        message={this.state.errMsg}
                    />
                )}
            </BackgroundTemplate>
        )
    }
}

const mapStateToProps = (state: any) => {
    const _authUserInfo = state.api?.user?.authUser
    const _otpReq = state.api?.forgotPwd?.forgotPwdOtpReq
    const _changePwd = state.api?.forgotPwd?.changePassword
    return ({
        otpReqStatus: _otpReq?.statusText,
        isLoadingOTPReq: _otpReq?.loading,
        changePwdResp: state.api?.forgotPwd?.changePassword?.response,
        currentUser: _authUserInfo.response,
        tempData: _changePwd?.tempData
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setTempUserPassword: (data: any) => dispatch(setTempUserPassword(data)),
    onClearChangePwd: () => dispatch({ type: CLEAR_USER_CHANGE_PASSWORD }),
    forgotPwdOtpReq: (data: any) => forgotPwdOtpReq(dispatch, data),
    onClearForgotPwdOtpReq: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ }),
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(ChangePasswordPage))