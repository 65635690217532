import React from 'react'
import { Pressable } from 'react-native'
import { Text } from 'react-native-paper'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import OtpModal from '../../components/Modal/OtpModal'
import { CLEAR_FORGOT_PWD_OTP_REQ, forgotPwdOtpReq } from '../../reducers/ForgotPassword/otpRequest'
import { CLEAR_VERIFY_EMAIL, verifyEmail } from '../../reducers/ForgotPassword/verifyEmail'
import { CLEAR_VERIFY_OTP, verifyOTP } from '../../reducers/ForgotPassword/verifyOTP'
import { SystemProperties } from '../../reducers/SystemProperty'
import { AuthUserInfoState } from '../../reducers/User/user'
import { responseCode, responseMsg } from '../../utils/response'
import { validateFields } from '../../utils/systemValidator'
import { ReduxAppState } from '../../utils/types'
import * as _ from 'lodash'
import { Alert } from '..'

export interface OtpProviderOwnProps {
    exposedProperties: (otpProperty: any) => any
    onProceedFlow: () => any
    flow: string
}

export interface OtpProviderDispatchProps {
    onSetGlobalLoading: (value: boolean) => void
    onVerifyEmail: (data: any) => any
    onClearOTP: () => void
    requestOTP: (data: any) => any
    onOpenOTPModal: (value: boolean) => void
}

export interface OtpProviderStateProps {
    user: AuthUserInfoState
    otpStatus: string
    systemFields: SystemProperties[]
    isOtpModalOpen: boolean
}

export type OtpProviderProps = OtpProviderOwnProps &
    OtpProviderStateProps &
    OtpProviderDispatchProps

export interface OtpProviderState {
    isLoading: boolean
    isLockedAcc: boolean
    isAlertMessage: boolean
    errMsg: string
    credential: any
    isNotAllowed: boolean
}

export interface OtpProperties {
    onRequestOtp: (data?: any) => void
    isNotAllowed: boolean
}

class OtpProvider extends React.Component<OtpProviderProps, OtpProviderState> {
    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: false,
            isLockedAcc: false,
            isAlertMessage: false,
            errMsg: '',
            credential: {},
            isNotAllowed: false
        }

        if (this.props.exposedProperties) {
            this.props.exposedProperties(this.exposedProperties)
        }
    }

    private get exposedProperties(): OtpProperties {
        return {
            onRequestOtp: this.onRequestOTP,
            isNotAllowed: this.state.isNotAllowed
        }
    }

    componentDidMount(): void {
        const isAllowed = validateFields(this.props.flow ?? '', this.props.systemFields)
        this.setState({ isNotAllowed: !isAllowed }, () =>
            this.props.exposedProperties(this.exposedProperties)
        )
    }

    componentDidUpdate(
        prevProps: Readonly<OtpProviderProps>,
        prevState: Readonly<OtpProviderState>,
        snapshot?: any
    ): void {
        if (
            !_.isEqual(prevProps.systemFields, this.props.systemFields) ||
            this.props.flow !== prevProps.flow ||
            (this.props.isOtpModalOpen !== prevProps.isOtpModalOpen && this.props.isOtpModalOpen)
        ) {
            const isAllowed = validateFields(this.props.flow ?? '', this.props.systemFields)
            this.setState({ isNotAllowed: !isAllowed }, () =>
                this.props.exposedProperties(this.exposedProperties)
            )
        }
    }

    onRequestOTP = async (data?: any) => {
        try {
            const todaysDate = new Date()
            this.props.onSetGlobalLoading(true)
            const isOtpEnabled = validateFields('OtpValidation', this.props.systemFields)
            const user = this.props.user
            let _selectedUser
            let _userResponse
            if (user?.isSignedIn) {
                _selectedUser = user?.response
            } else {
                const _resp = await this.props.onVerifyEmail({ email: data.username })
                _selectedUser = _resp?.data?.message?.selectedUser
                _userResponse = _resp?.data?.message?.userResponse
            }

            if (
                (_userResponse === responseCode.USER_FOUND || user?.isSignedIn) &&
                _selectedUser?.isTwoFactorEnabled &&
                isOtpEnabled
            ) {
                const otpAttempts = validateFields('OtpAttempts', this.props.systemFields)
                let dtLastSentOTP = new Date()
                if (_selectedUser?.hasOwnProperty('dtLastAttemptOTP')) {
                    dtLastSentOTP = new Date(_selectedUser?.dtLastAttemptOTP)
                }

                if (
                    dtLastSentOTP.setHours(0, 0, 0, 0) >= todaysDate.setHours(0, 0, 0, 0) &&
                    _selectedUser?.otpAttemptCount >= otpAttempts?.value
                ) {
                    this.setState({
                        isLoading: false,
                        isLockedAcc: true,
                        isAlertMessage: true,
                        errMsg: 'Your account has been locked.'
                    })
                    this.props.onSetGlobalLoading(false)
                } else {
                    const _data = {
                        userId: _selectedUser?.id,
                        flow: this.props.flow ?? 'Two Factor Authentication',
                        isUpdate: false,
                        template: 'email-2fa-otp'
                    }
                    this.setState({ credential: data })
                    this.props.onClearOTP()
                    this.props
                        .requestOTP(_data)
                        .then(() => {
                            if (this.props.otpStatus === responseCode.OTP_SUCCESSFUL) {
                                this.props.onOpenOTPModal(true)
                            } else {
                                this.setState({ errMsg: responseMsg[this.props.otpStatus] })
                            }
                            this.props.onSetGlobalLoading(false)
                        })
                        .catch(() => this.props.onSetGlobalLoading(false))
                }
            } else {
                // this.props.loginUser(data.username, data.password)
                this.props.onSetGlobalLoading(false)
                this.props.onProceedFlow()
            }
            // if (_selectedUser?.isTwoFactorEnabled) {

            // } else {
            //     this.props.loginUser(data.username, data.password)
            // }
        } catch (error) {
            console.error(error)
            this.props.onSetGlobalLoading(false)
        }
    }

    addProps = (child: any) =>
        React.isValidElement(child) &&
        React.cloneElement(child, { otpProvider: this.exposedProperties })

    render() {
        return (
            <>
                {this.state.errMsg && (
                    <Pressable
                        style={{
                            backgroundColor: '#FF4B4B',
                            padding: 10,
                            maxWidth: 400,
                            width: '100%',
                            marginBottom: 10,
                            marginLeft: 20
                        }}>
                        <Text
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: 12,
                                color: 'white',
                                textAlign: 'center'
                            }}>
                            {this.state.errMsg}
                        </Text>
                    </Pressable>
                )}
                <OtpModal
                    proceedFlow={this.props.onProceedFlow}
                    flow={this.props.flow ?? 'Two Factor Authentication'}
                />
                <Alert
                    type="error"
                    visible={this.state.isNotAllowed}
                    labelStyle={{ fontSize: 16 }}
                    style={{ alignSelf: 'center', width: '100%' }}>
                    This flow is not allowed. Please contact customer support.
                </Alert>
                {React.Children.map(this.props.children, (child) => this.addProps(child))}
            </>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _otpReq = state.api?.forgotPwd?.forgotPwdOtpReq
    const _verifyOTP = state.api?.forgotPwd?.verifyOTP
    const _verifyEmail = state.api?.forgotPwd?.verifyEmail
    return {
        isLoadingOTPReq: _otpReq.loading,
        otpReqResponse: _otpReq?.response,
        otpReqStatus: _otpReq?.statusText,
        isLoadingVerifyOTP: _verifyOTP.loading,
        verifyOTPResponse: _verifyOTP.response,
        verifyEmailResp: _verifyEmail.response,
        isVerifiedEmail: _verifyEmail.response?.userResponse === responseCode.USER_FOUND,
        isOtpModalOpen: state?.custom?.otpModal,
        user: state?.api?.user?.authUser,
        otpStatus: state.api?.forgotPwd?.forgotPwdOtpReq?.statusText,
        systemFields: state.api?.systemParam?.getAllSysParams?.response ?? []
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    requestOTP: (data: any) => forgotPwdOtpReq(dispatch, data),
    verifyOTP: (data: any) => verifyOTP(dispatch, data),
    onClearVerifyOTP: () => dispatch({ type: CLEAR_VERIFY_OTP }),
    onClearVerifyEmail: () => dispatch({ type: CLEAR_VERIFY_EMAIL }),
    onOpenOTPModal: (value: boolean) => dispatch({ type: 'custom.otpModal', value }),
    onSetGlobalLoading: (isLoading: boolean) =>
        dispatch({ type: 'custom.isGlobalLoading', value: isLoading }),
    onClearOTP: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ }),
    onVerifyEmail: (data: any) => verifyEmail(dispatch, data)
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(OtpProvider))
