import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

export const forgotPwdStyles = StyleSheet.create({
    formStyle: {
        // alignItems: 'center',
        width: '100%',
        backgroundColor: '#fff',
        // padding: '4%',
        marginTop: 10
    },
    registerBtn: {
        // marginBottom: 20,
        marginTop: 20,
        marginLeft: 15,
        width: 350,
        backgroundColor: '#F7931E'
    },
    textWhite: {
        color: 'white',
        fontSize: 12,
        fontFamily: "'Montserrat', sans-serif",
    },
    input: {
        alignSelf: 'flex-start',
        color: '#003849',
        backgroundColor: '#EFEFEF',
        borderColor: '#EFEFEF',
        paddingHorizontal: 10,
        fontSize: 14,
        borderWidth: 1,
        borderRadius: 8,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: '800',
        width: 450,
        // width: '100%',
        marginBottom: 13,
        padding: 10
    },
    fieldLabelStyle: {
        fontSize: 16,
        // margin: '10%'
        fontWeight: 'bold',
        width: '10%',
        fontFamily: "'Montserrat', sans-serif"
    },
    fieldFormGroupStyle: {
        flexDirection: 'row',
        marginHorizontal: 10,
        // justifyContent: 'space-evenly',
        // justifyContent: 'space-between'
    },
    formGroup: {
        marginTop: 10,
        marginHorizontal: 10,
    },
    formGroup2: {
        marginVertical: 5,
        marginHorizontal: 5,
        alignItems: 'flex-start'
    },
    textStyle: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12
    },
    title: {
        fontSize: 20,
        marginTop: 10,
        marginBottom: 15,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    errorColor: {
        color: 'red'
    },
    successColor: {
        color: 'green'
    },
    plainColor: {
        color: cpebColors.labelColor,
    },
    errorBorder: {
        borderColor: 'red'
    },
    text: {
        fontSize: 14,
        lineHeight: 14,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 5
    },
    errorContainer: {
        alignItems: 'center',
        marginBottom: 10
    },
    requirementsContainer: {
        marginVertical: 12,
        marginHorizontal: 25,
    },
    errorMessage: {
        padding: 5,
        borderColor: 'red',
        borderWidth: 2,
        backgroundColor: '#FF3030',
        color: 'white',
        alignItems: 'center',
        width: 450,
        marginBottom: 20
    },
    successMessage: {
        padding: 10,
        backgroundColor: '#55AA55',
        color: 'white',
        alignItems: 'flex-start',
        width: 470,
        marginBottom: 20
    },
    successText: {
        color: 'white',
        fontSize: 12,
        fontFamily: "'Montserrat', sans-serif"
    },
    textInputContainer: {
        width: 50,
        height: 50,
        margin: 5,
        fontSize: 16,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#C6BEBE',
        alignContent: 'center'
    },
    roundedTextInput: {
        backgroundColor: '#F5BF32',
        fontSize: 20
    },
})


