import React, { ReactNode } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { Title } from 'react-native-paper'
import typographyStyle from './style'


export interface TitleProps {
    style?: StyleProp<ViewStyle>
    children: ReactNode
    direction?: 'row' | 'column'
    justifyAlignment?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-evenly' | 'space-around'
    [p: string]: any
}

class ArtView extends React.Component<TitleProps, any> {
    constructor(props: any) {
        super(props)
    }

    render(): React.ReactNode {
        return (
            <View {...this.props} style={[{ flexDirection: this.props.direction ?? 'row', justifyContent: this.props.justifyAlignment ?? 'flex-start', }, this.props.style]}>{this.props.children}</View>
        )
    }
}

export default ArtView