import React from 'react'
import ReactModal from 'react-modal'
import { Image, View } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import { ActivityIndicator, Button, List, Title } from 'react-native-paper'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { sellItem, SellItemState } from '../../reducers/Items/sellItem'
import { Portfolio } from '../../reducers/Portfolio'
import { User } from '../../reducers/User'
import { Alert, ArtText, ArtTitle, ArtView, Form, OtpProvider, Screen } from '../../ReusableComponents'
import { OtpProperties } from '../../ReusableComponents/OtpProvider/OtpProvider'
import { ReduxAppState } from '../../utils/types'
import * as _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { getPortfolio } from '../../reducers/Portfolio/getPortfolio'
import { Items } from '../../reducers/Items'
import { getPortfolioOfUser } from '../../reducers/User/getPortfolioOfUser'
import { getItemInfo } from '../../reducers/Items/getItemInfo'
import { StackNavigationProp } from '@react-navigation/stack'
import { RouteName } from '../../navigation/types'

export const modalCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxHeight: '100%',
        backgroundColor: '#E5E4E2',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: 'black'
    }
}

export interface ArtworkSoldModalState {

}

export interface OwnProps {
    onRequestClose: () => void
    isOpen: boolean
}

export interface DispatchProps {

}

export interface StateProps {
    item: Items
}

export type ArtworkSoldModalProps = OwnProps & DispatchProps & StateProps

class ArtworkSoldModal extends React.Component<ArtworkSoldModalProps, ArtworkSoldModalState> {
    otpProvider?: OtpProperties
    constructor(props: any) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <ReactModal isOpen={this.props.isOpen && this.props.item?.isPhysicallySold} style={modalCustomStyles} onRequestClose={this.props.onRequestClose}>
                <Screen minWidth={1070}>
                    <ArtView direction='row' style={{ paddingHorizontal: 10, paddingVertical: 20, width: 1000, height: 500 }}>
                        <View style={{ width: '50%', height: '100%' }}>
                            <Image source={{ uri: this.props.item?.imageUrl }} style={{ width: '100%', height: '100%', borderRadius: 10 }} />
                        </View>
                        <View style={{ width: '50%', height: '100%' }} >
                            <ArtText label={`ARTWORK SOLD`} labelThickness='bold' style={{ fontSize: 90, lineHeight: 80, textAlign: 'center', marginBottom: 16 }} />
                            <View style={{ backgroundColor: 'white', alignSelf: 'center', width: '100%', marginLeft: 23, padding: 16, height: 285, borderRadius: 10 }}>
                                <ArtText label='Artwork' style={{ fontSize: 20 }} />
                                <ArtText label={this.props.item?.title} labelThickness='bold' style={{ marginBottom: 16, fontSize: 25, marginLeft: 20 }} />
                                <ArtText label='Dimensions' style={{ fontSize: 20 }} />
                                <ArtText label='65.5. x 48 cm' labelThickness='bold' style={{ marginBottom: 16, fontSize: 25, marginLeft: 20 }} />
                                <ArtText label='Material' style={{ fontSize: 20 }} />
                                <ArtText label='Oil on Canvas' labelThickness='bold' style={{ marginBottom: 16, fontSize: 25, marginLeft: 20 }} />
                                <ArtText label='Technique' style={{ fontSize: 20 }} />
                                <ArtText label='' labelThickness='bold' style={{ marginBottom: 16 }} />
                            </View>
                        </View>
                    </ArtView>
                </Screen>
                <Screen maxWidth={1069}>
                    <ArtView direction='column' style={{ paddingHorizontal: 10, paddingVertical: 20, width: 500, height: 800 }}>
                        <View style={{ width: '100%', height: '65%' }} >
                            <ArtText label={`ARTWORK SOLD`} labelThickness='bold' style={{ fontSize: 80, lineHeight: 80, textAlign: 'center', marginBottom: 16 }} />
                            <View style={{ width: '100%', height: 300, marginBottom: 10 }}>
                                <Image source={{ uri: this.props.item?.imageUrl }} style={{ width: '100%', height: '100%', borderRadius: 10 }} />
                            </View>
                            <View style={{ backgroundColor: 'white', alignSelf: 'center', width: '100%', padding: 16, height: 285, borderRadius: 10 }}>
                                <ArtText label='Artwork' style={{ fontSize: 20 }} />
                                <ArtText label={this.props.item?.title} labelThickness='bold' style={{ marginBottom: 16, fontSize: 25, marginLeft: 20 }} />
                                <ArtText label='Dimensions' style={{ fontSize: 20 }} />
                                <ArtText label='65.5. x 48 cm' labelThickness='bold' style={{ marginBottom: 16, fontSize: 25, marginLeft: 20 }} />
                                <ArtText label='Material' style={{ fontSize: 20 }} />
                                <ArtText label='Oil on Canvas' labelThickness='bold' style={{ marginBottom: 16, fontSize: 25, marginLeft: 20 }} />
                                <ArtText label='Technique' style={{ fontSize: 20 }} />
                                <ArtText label='' labelThickness='bold' style={{ marginBottom: 16 }} />
                            </View>
                        </View>
                    </ArtView>
                </Screen>
            </ReactModal>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => ({
    user: state.api?.user?.authUser?.response,
    portfolio: state.api?.portfolio?.getPortfolio.response,
    sellApi: state.api?.items?.sellItem,
    item: { ...state.api?.items?.getItemInfo?.response, imageUrl: state.api?.items?.getItemInfo?.imageUrl }
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(ArtworkSoldModal))