export default class Socket {
    static ws
    static init(data) {
        this.ws = new WebSocket(process.env.WEBSOCKET_URL)
        this.ws.addEventListener('open', function (event) {
            Socket.sendMessage(JSON.stringify({
                action: 'updateConnection',
                userId: data
            }))
        })
    }
    // Receive message
    static onMessage(handler, userId) {
        if (this.ws !== undefined) {
            this.ws.addEventListener('message', handler)
        } else {
            this.init(userId)
        }
    }
    // Send message
    static sendMessage(message, userId) {
        if (this.ws !== undefined) {
            this.ws.send(message)
        } else {
            this.init(userId)
        }
    }
    static closeConnection() {
        if (this.ws !== undefined) {
            this.ws.close()
        }
    }
}