import React from 'react'

import { View, Text, TextInput } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RouteName } from '../../navigation/types'
import { Button, Title } from 'react-native-paper'
import { forgotPwdStyles as styles } from './styles'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import * as _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { BackgroundTemplate, Screen } from '../../ReusableComponents'
import { CLEAR_FORGOT_PWD_OTP_REQ, forgotPwdOtpReq } from '../../reducers/ForgotPassword/otpRequest'
import { changeUserPassword, CLEAR_USER_CHANGE_PASSWORD } from '../../reducers/ForgotPassword/changePassword'
import { CLEAR_VERIFY_OTP, verifyOTP } from '../../reducers/ForgotPassword/verifyOTP'
import AlertMessage from './component/AlertMessage'
import OtpInput from 'react-otp-input'
import { User } from '../../reducers/User'
import { AxiosPromise } from 'axios'
import { responseCode, responseMsg } from '../../utils/response'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean,
    route: any
}

export interface StateProps {
    currentUser?: User,
    otpReqResponse: any,
    tempData?: any,
    verifyOTPResponse: any
    isLoadingOTPReq: boolean
    otpReqStatus: string
}

export interface DispatchProps {
    forgotPwdOtpReq: (data: any) => any,
    verifyOTP: (data: any) => any
    onClearVerifyOTP: () => any,
    onClearForgotPwdOtpReq: () => any
    onClearVerifyEmail: () => any
    changeUserPassword: (data: any) => AxiosPromise,
    onClearChangePwd: () => any,
}

export interface VerifyOTPPageState {
    isSubmit: boolean,
    isLoading: boolean,
    isVerifyError: boolean
    isVerifiedOTP: boolean
    isResendOTP: boolean
    isSuccessResendOTP: boolean
    isReset: boolean
    isAlertMessage: boolean
    startTimer: boolean
    seconds: number
    alertMsg: string,
    otpCode: string,
    validationValue: any
}
export const notRequiredFields = []

export type VerifyOTPPageProps = ComponentProps & StateProps & DispatchProps

class VerifyOTPPage extends React.Component<VerifyOTPPageProps, VerifyOTPPageState> {
    otpInput: React.RefObject<any>
    timer!: NodeJS.Timer;
    constructor(props: VerifyOTPPageProps) {
        super(props)
        this.state = {
            isSubmit: false,
            isResendOTP: false,
            isSuccessResendOTP: false,
            isLoading: false,
            isVerifyError: false,
            isVerifiedOTP: false,
            isReset: false,
            isAlertMessage: false,
            startTimer: false,
            seconds: 1,
            otpCode: '',
            alertMsg: '',
            validationValue: {},
        }
        this.otpInput = React.createRef()
    }

    componentDidMount() {
        if (!this.props.tempData) {
            this.props.navigation.navigate(RouteName.ChangePasswordPage)
        }
        this.props.onClearVerifyOTP()
        this.setState({ startTimer: false })
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    onChangeValue = (value: string) => {
        this.setState({ otpCode: value })
    }

    tick = () => {
        if (this.state.seconds > 0) {
            this.setState({ seconds: this.state.seconds - 1 })
        } else {
            if (this.state.startTimer) {
                this.setState({ startTimer: false })
            }
            clearInterval(this.timer)
        }
    }

    onResendOTP = () => {
        const data = {
            userId: this.props.currentUser?.id,
            isUpdate: true
        }

        this.props.onClearForgotPwdOtpReq()
        this.props.forgotPwdOtpReq(data)
            .then((res) => {
                console.log(res)
                // Max OTP sent count
                if (this.props.otpReqStatus !== responseCode.OTP_SUCCESSFUL) {
                    this.setState({
                        isLoading: false,
                        isSuccessResendOTP: false,
                        isResendOTP: true,
                        isAlertMessage: true,
                        isReset: false,
                        isVerifyError: false,
                        isSubmit: false,
                        alertMsg: responseMsg[this.props.otpReqStatus]
                    })
                } else {
                    if (this.state.seconds > 0 && !this.state.startTimer) {
                        this.setState({
                            startTimer: true,
                            seconds: 30,
                            isLoading: false,
                            isSuccessResendOTP: true,
                            isResendOTP: true,
                            isReset: false,
                            isVerifyError: false,
                            isSubmit: false,
                        })
                        this.timer = setInterval(this.tick, 1000)
                    }
                }
            }).catch(() => {
                this.setState({ isVerifyError: true, isLoading: false, isVerifiedOTP: false })
            })
    }

    onGetButtonTitle = () => {
        let title = `Resend Code`
        if (this.state.startTimer) {
            title = `Resend Code (${this.state.seconds} secs)`
        }
        return title
    }

    onNavigateToDashboard = () => {
        this.setState({ isAlertMessage: false, isSubmit: false, isLoading: false, otpCode: '' })
        this.props.onClearChangePwd()
        this.props.onClearVerifyOTP()
        this.props.onClearForgotPwdOtpReq()
        this.props.navigation.navigate(RouteName.DashboardPage)
    }

    onSubmit = () => {
        this.setState({
            isSubmit: true,
            isReset: false,
            isLoading: true,
            isSuccessResendOTP: false,
            isResendOTP: false,
            isVerifyError: false
        })

        if (!this.state.otpCode || this.state.otpCode.length !== 6) {
            this.setState({ isLoading: false, alertMsg: 'OTP is required' })
            return
        }

        const data = {
            otpId: this.props.otpReqResponse?.id,
            userId: this.props.currentUser?.id,
            otp: this.state.otpCode,
            isChangePwd: true
        }

        this.props.verifyOTP(data)
            .then(() => {
                if (this.props.verifyOTPResponse === responseCode.OTP_VERIFY_FAIL) {
                    this.setState({
                        alertMsg: responseMsg[this.props.verifyOTPResponse],
                        isVerifiedOTP: false,
                        isLoading: false,
                        isAlertMessage: true
                    }, () => {
                        this.props.onClearVerifyOTP()
                        this.props.onClearForgotPwdOtpReq()
                    })
                } else if (this.props.verifyOTPResponse !== responseCode.SUCCESS) {
                    this.setState({
                        alertMsg: responseMsg[this.props.verifyOTPResponse],
                        isVerifiedOTP: false,
                        isLoading: false,
                        isReset: true
                    })
                } else {
                    //  Update password
                    if (this.props.tempData) {
                        this.props.changeUserPassword(this.props.tempData)
                        this.setState({ isLoading: false, isAlertMessage: true, isVerifiedOTP: true, alertMsg: `Successful Reset Password` })
                    }
                }
            }).catch(() => {
                this.setState({ isLoading: false, isVerifyError: true })
            })
    }

    render() {
        const { alertMsg, isSubmit, isLoading, isVerifiedOTP, otpCode, isVerifyError, isResendOTP, isSuccessResendOTP, isReset, isAlertMessage } = this.state
        return (
            <BackgroundTemplate>
                {!isAlertMessage ? (
                    <View style={[styles.formStyle]}>
                        {isVerifyError && <View style={{ alignItems: 'flex-start', marginLeft: 10, marginRight: 20 }}><View style={styles.errorMessage} ><Text style={{ color: 'white', fontSize: 12, fontFamily: "'Montserrat', sans-serif" }}><FormattedMessage id='Buy.msg.somethingWentWrong' /></Text></View></View>}
                        {!isLoading && isResendOTP && isSuccessResendOTP && (
                            <View style={{ alignItems: 'flex-start', marginLeft: 10, marginRight: 20 }}>
                                <View style={styles.successMessage} >
                                    <Text style={styles.successText}>{`Your One-Time Pin (OTP) has been sent to your registered email address.`}</Text>
                                    <Text style={styles.successText}>{`Please be reminded that you only have `}<Text style={{ fontWeight: 'bold' }}>{`5 minutes`}</Text>{` to enter the code.`}</Text>
                                    <Text style={styles.successText}>{`Otherwise, you need to request for a new one.`}</Text>
                                </View>
                            </View>
                        )}
                        <View style={{ marginLeft: 10, marginRight: 20 }}>
                            <Title style={[styles.textStyle, styles.title, { fontSize: 24, marginBottom: 20 }]}>Verify OTP</Title>
                            <Text style={[styles.textStyle, { fontSize: 14, marginBottom: 30 }]}>{`We emailed you a 6-digit code to `}
                                <Text style={{ fontWeight: 'bold' }}>{`${this.props.currentUser?.email}.`}</Text>{` Enter the code below to confirm your email address.`}
                            </Text>
                        </View>
                        <Screen minWidth={400} style={styles.formGroup}>
                            <View style={{ width: 400 }}>
                                <OtpInput
                                    value={this.state.otpCode}
                                    onChange={(val: string) => this.onChangeValue(val)}
                                    numInputs={6}
                                    isInputNum={true}
                                    inputStyle={{
                                        width: 50,
                                        height: 60,
                                        margin: 5,
                                        fontSize: 18,
                                        borderRadius: 4,
                                        borderWidth: 1,
                                        borderColor: '#C6BEBE',
                                        fontWeight: 'bold',
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontColor: '#C6BEBE'
                                    }}
                                    containerStyle={{
                                        padding: 10,
                                        borderRadius: 4,
                                        backgroundColor: '#F5F5F5',
                                        width: 400,
                                        height: 70
                                    }}
                                />
                            </View>
                        </Screen>
                        <Screen maxWidth={399} style={styles.formGroup}>
                            <OtpInput
                                value={this.state.otpCode}
                                onChange={(val: string) => this.onChangeValue(val)}
                                numInputs={6}
                                isInputNum={true}
                                inputStyle={{
                                    width: 50,
                                    height: 60,
                                    margin: 5,
                                    fontSize: 18,
                                    borderRadius: 4,
                                    borderWidth: 1,
                                    borderColor: '#C6BEBE',
                                    fontWeight: 'bold',
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontColor: '#C6BEBE'
                                }}
                                containerStyle={{
                                    padding: 10,
                                    borderRadius: 4,
                                    backgroundColor: '#F5F5F5',
                                    width: 400,
                                    height: 70
                                }}
                            />
                        </Screen>

                        {(isSubmit && (!otpCode || otpCode.length !== 6)) || (!isLoading && isReset && isSubmit && !isVerifiedOTP) ? (
                            <View style={{ marginVertical: 10, marginLeft: 10 }}>
                                <Text style={[styles.errorColor, styles.textStyle]}>{alertMsg}</Text>
                            </View>
                        ) : null}

                        <View style={[styles.formGroup2, { flexDirection: 'row' }]}>
                            <Button loading={this.props.isLoadingOTPReq} disabled={this.state.startTimer || this.props.isLoadingOTPReq} onPress={this.onResendOTP} style={[styles.registerBtn, { width: 230, backgroundColor: this.state.startTimer ? '#C6BEBE' : '#F7931E', marginLeft: 0 }]} labelStyle={styles.textWhite}>{this.onGetButtonTitle()}</Button>
                            <Button loading={isLoading} disabled={isLoading} onPress={this.onSubmit} style={[styles.registerBtn, { width: 200 }]} labelStyle={styles.textWhite}>Verify</Button>
                        </View>
                    </View>
                ) : (
                    <AlertMessage
                        navigateToDashboard={() => this.onNavigateToDashboard()}
                        isSuccess={this.state.isVerifiedOTP}
                        message={this.state.alertMsg}
                    />
                )}
            </BackgroundTemplate>
        )
    }
}

const mapStateToProps = (state: any) => {
    const _authUserInfo = state.api?.user?.authUser
    const _otpReq = state.api?.forgotPwd?.forgotPwdOtpReq
    const _changePwd = state.api?.forgotPwd?.changePassword
    const _verifyOTP = state.api?.forgotPwd?.verifyOTP
    return ({
        isLoadingOTPReq: _otpReq.loading,
        otpReqResponse: _otpReq?.response,
        otpReqStatus: _otpReq?.statusText,
        isLoadingVerifyOTP: _verifyOTP.loading,
        verifyOTPResponse: _verifyOTP.response,
        currentUser: _authUserInfo.response,
        tempData: _changePwd?.tempData
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    forgotPwdOtpReq: (data: any) => forgotPwdOtpReq(dispatch, data),
    verifyOTP: (data: any) => verifyOTP(dispatch, data),
    changeUserPassword: (data: any) => changeUserPassword(dispatch, data),
    onClearForgotPwdOtpReq: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ }),
    onClearVerifyOTP: () => dispatch({ type: CLEAR_VERIFY_OTP }),
    onClearChangePwd: () => dispatch({ type: CLEAR_USER_CHANGE_PASSWORD }),
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(VerifyOTPPage))