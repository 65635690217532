import React from 'react'
import * as yup from 'yup'
import * as _ from 'lodash'
import Modal from 'react-modal'
import Socket from '../../websocket'
import Script from 'react-load-script'
import { StackNavigationProp } from '@react-navigation/stack'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { View, Text, Image, TextInput, Platform, Linking } from 'react-native'
import {
    RadioButton,
    Subheading,
    Title,
    Button,
    ActivityIndicator,
    IconButton
} from 'react-native-paper'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { ReduxAppState } from '../../utils/types'
import { Alert, ArtText, OtpProvider, Screen } from '../../ReusableComponents'
import { User } from '../../reducers/User'
import { getFunds } from '../../reducers/Wallet/getFunds'
import { Wallet } from '../../reducers/Wallet'
import {
    CLEAR_UPDATE_WALLET,
    updateWallet,
    UpdateWalletState
} from '../../reducers/Wallet/updateWallet'
import { validateReservedItem } from '../../reducers/Items/validateReservedItem'
import { handleLoadScript, handleTopUp } from '../Wallet/Topup/Topup'
import { OtpProperties } from '../../ReusableComponents/OtpProvider/OtpProvider'
import { validateFields } from '../../utils/systemValidator'
import { SystemProperties } from '../../reducers/SystemProperty'
import { setNotificationCount } from '../../reducers/Notification/getNotificationWithLimit'
import { walletDetailStyles as styles } from './styles'
import { generatePaymentCode } from '../../reducers/Wallet/generatePaymentCode'
import { Popover } from 'react-native-popper'
import { PaymentChannel } from '../../utils'
import { CLEAR_GENERATE_PAYMENT_CODE } from '../../reducers/Wallet/generatePaymentCode'
import PostPaymentModal from '../Modal/PostPaymentModal.web'
import { getAuditLogs } from '../../reducers/Audit/getAuditLogs'
import { RouteName } from '../../navigation/types'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean
}

export interface DispatchProps {
    onGetFunds: (id: string) => any
    onAddFunds: (id: string, data: any) => any
    clearUpdateWallet: () => any
    onValidateReservedItem: () => any
    onSetGlobalLoading: (isLoading: boolean) => void
    onSetGlobalError: (isShowError: boolean) => void
    setNotifCount: (count: number) => any
    onGeneratePaymentUrl: (data: any) => any
    onClearGeneratePayment: () => void
    onGetRefCode: (refCode: string) => any
}

export interface StateProps {
    isLoading: boolean
    isError: boolean
    user: User
    wallet: Wallet
    updateWalletLoading: boolean
    updateWallet: UpdateWalletState
    walletError: boolean
    isWalletLoading: boolean
    sgdValue: number
    systemFields: SystemProperties[]
    generatePaymentLoading: boolean
    generatePaymentError: boolean
    phpValue: number
}

export interface WalletDetailPageState {
    selectedPaymentType: string
    newValue: string
    isOpenPaymentModal: boolean
    funds: string
    isNextPage: boolean
    selectedAmount: string
    selectedPaymentSubtype: string
    isPopoverOpen?: boolean
    isPostPaymentModalOpen: boolean
    paymentStatus: string | undefined
    postPaymentFlow: string
}

export type WalletDetailPageProps = ComponentProps & DispatchProps & StateProps

Modal.setAppElement('#react-root')

const FormSchema = yup.object({
    username: yup.string().required('username is required'),
    password: yup.string().required('password is required')
})

const initialState = {
    selectedPaymentType: '',
    selectedPaymentSubtype: '',
    newValue: '',
    isOpenPaymentModal: false,
    funds: '',
    isNextPage: false,
    selectedAmount: '',
    isPopoverOpen: undefined,
    isPostPaymentModalOpen: false,
    paymentStatus: undefined,
    postPaymentFlow: ''
}

class WalletDetailComponent extends React.Component<WalletDetailPageProps, WalletDetailPageState> {
    otpProvider?: OtpProperties
    constructor(props: WalletDetailPageProps) {
        super(props)
        this.state = initialState
    }

    componentDidMount() {
        const _params = _.get(this.props, 'route.params', {})
        if (_params?.refno) {
            this.props.onGetRefCode(_params.refno)
            this.setState({
                paymentStatus: _params?.status,
                postPaymentFlow: _params?.status
                    ? _params?.status === 'S'
                        ? 'SuccessPayment'
                        : 'FailedPayment'
                    : '',
                isPostPaymentModalOpen: true
            })
        }
        this.handleReceive()
        this.props
            .onValidateReservedItem()
            .then(() => {
                this.props.onGetFunds(this.props.user.sub)
                this.props.clearUpdateWallet()
            })
            .catch(() => {
                this.props.onGetFunds(this.props.user.sub)
                this.props.clearUpdateWallet()
            })
    }

    componentDidUpdate(
        prevProps: Readonly<WalletDetailPageProps>,
        prevState: Readonly<WalletDetailPageState>,
        snapshot?: any
    ): void {
        if (!_.isEqual(this.props.systemFields, prevProps.systemFields)) {
            const isGcashEnabled = validateFields('Gcash', this.props.systemFields)
            const isCardEnabled = validateFields('Card', this.props.systemFields)
            const isOnlineBankingEnabled = validateFields('OnlineBanking', this.props.systemFields)
            const isOTCEnabled = validateFields('OverTheCounter', this.props.systemFields)
            if (this.state.selectedPaymentSubtype === 'gcash' && !isGcashEnabled) {
                this.setState({ selectedPaymentSubtype: '' })
            }
            if (this.state.selectedPaymentType === PaymentChannel.Card && !isCardEnabled) {
                this.setState({ selectedPaymentType: '' })
            }

            if (
                (this.state.selectedPaymentType === 'over the counter' && !isOTCEnabled) ||
                (this.state.selectedPaymentType === 'online banking' && !isOnlineBankingEnabled)
            ) {
                this.setState({ selectedPaymentType: '', selectedPaymentSubtype: '' })
            }
        }
    }

    // setOverTheCounter = () => {
    //   this.setState({ isOverTheCounter:  !this.state.isOverTheCounter})
    // }

    setDropdown = (
        dropdown: 'isOverTheCounter' | 'isOnlineBanking' | 'isEWallets',
        value?: boolean
    ) => {
        this.setState({ [dropdown]: value !== undefined ? value : !_.get(this.state, dropdown) })
    }

    handleReceive = () => {
        if (Socket.ws?.readyState === WebSocket.OPEN) {
            Socket.onMessage((event: any) => {
                if (event.data !== 'Updated Successfully' && event.data !== 'updateSystemParams') {
                    const _data = JSON.parse(event.data)
                    const _notificationCount = _data.notificationCount
                    this.props.setNotifCount(_notificationCount)
                }
            }, this.props.user.sub)
        } else {
            // Queue a retry
            setTimeout(() => {
                this.handleReceive()
            }, 1000)
        }
    }

    onSelectPayment = (value: string) => {
        this.setState({
            selectedPaymentType: value,
            selectedPaymentSubtype: '',
            isPopoverOpen: undefined
        })
    }

    onSelectPaymentSubtype = (value: string) => this.setState({ selectedPaymentSubtype: value })

    onToggleModal = (isOpen: boolean) => {
        this.setState({ isOpenPaymentModal: isOpen })
    }

    componentWillUnmount() {
        this.props.clearUpdateWallet()
    }

    onChangeAmount = (value: string) =>
        !isNaN(Number(value)) && this.setState({ selectedAmount: value })

    onNextPage = () => {
        this.setState({ isNextPage: true })
    }

    onPrevPage = () => {
        this.setState({ isNextPage: false })
    }

    onResetState = () => {
        this.setState(initialState)
        this.props.navigation.replace(RouteName.DashboardPage)
    }

    onDisplayBalanceMobile = () => (
        <Screen
            maxWidth={987}
            style={{ marginTop: this.state.selectedPaymentType && this.state.isNextPage ? 25 : 0 }}>
            <View
                style={{
                    alignItems:
                        !this.state.isNextPage || this.state.selectedPaymentType === 'gcash'
                            ? 'center'
                            : 'flex-start'
                }}>
                <Title numberOfLines={1} style={styles.title}>
                    <FormattedMessage id="WalletDetail.msg.balance" />
                </Title>
                <Text style={{ fontSize: 30, fontWeight: '600' }}>
                    <FormattedNumber
                        format={'USD'}
                        value={Number(this.props.wallet?.balance ?? 0)}
                    />
                </Text>
            </View>
        </Screen>
    )

    isButtonDisabled = () => {
        if (this.state.selectedAmount && !isNaN(Number(this.state.selectedAmount))) {
            return (
                this.state.selectedPaymentType !== PaymentChannel.Card &&
                !this.state.selectedPaymentSubtype
            )
        }
        return true
    }

    onGenerateCode = async () => {
        const { id, name, email } = this.props.user
        const resp = await this.props.onGeneratePaymentUrl({
            userId: id,
            usdAmount: this.state.selectedAmount,
            description: 'deposit',
            contact: this.props.user?.mobileNo ?? 'N/A',
            channel:
                this.state.selectedPaymentType === PaymentChannel.Card
                    ? this.state.selectedPaymentType
                    : this.state.selectedPaymentSubtype,
            name,
            email
        })
        console.log(resp)
        if (resp?.status === 200) {
            if (this.state.selectedPaymentType === 'over the counter') {
                this.setState({ isPostPaymentModalOpen: true, postPaymentFlow: 'OTC' })
            } else {
                Platform.OS === 'web'
                    ? window.location.replace(resp?.data?.payload?.payment_url)
                    : Linking.openURL(resp?.data?.payload?.payment_url)
            }
        }
    }

    handlePostPaymentModal = (isOpen: boolean = false) =>
        this.setState({ isPostPaymentModalOpen: isOpen })

    renderCard = () => (
        <>
            <Image
                source={require('../../assets/background/am-card-2.png')}
                resizeMode="stretch"
                style={{ height: '100%', width: '100%' }}
            />
            <Screen minWidth={576} style={{ position: 'absolute', bottom: 60, right: 50 }}>
                <Text
                    style={{
                        color: 'black',
                        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                        fontSize: 15,
                        textAlign: 'right',
                        marginRight: 5
                    }}>
                    ACCOUNT BALANCE
                </Text>
                <Subheading
                    style={{
                        color: 'black',
                        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                        fontSize: 64,
                        fontWeight: '700',
                        marginTop: 30
                    }}>
                    <FormattedNumber
                        currency={'USD'}
                        style="currency"
                        value={this.props.wallet?.balance ?? 0}
                    />
                </Subheading>
            </Screen>
            <Screen maxWidth={575} style={{ position: 'absolute', bottom: 60, right: 50 }}>
                <Text
                    style={{
                        color: 'black',
                        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                        fontSize: 15,
                        textAlign: 'center',
                        marginRight: 5
                    }}>
                    ACCOUNT BALANCE
                </Text>
                <Subheading
                    style={{
                        color: 'black',
                        fontFamily: 'Roboto',
                        fontSize: 50,
                        fontWeight: '700',
                        marginTop: 30
                    }}>
                    <FormattedNumber
                        currency={'USD'}
                        style="currency"
                        value={this.props.wallet?.balance ?? 0}
                    />
                </Subheading>
            </Screen>
        </>
    )

    renderContent = () => {
        const depositFee = validateFields('DepositFee', this.props.systemFields)
        const isDepositAllowed = validateFields('Deposit', this.props.systemFields)
        const isGcashEnabled = validateFields('Gcash', this.props.systemFields)
        const isCardEnabled = validateFields('Card', this.props.systemFields)
        const isOnlineBankingEnabled = validateFields('OnlineBanking', this.props.systemFields)
        const isOTCEnabled = validateFields('OverTheCounter', this.props.systemFields)

        const renderOnlineBanking = () => (
            <>
                <View style={{ flexDirection: 'row' }}>
                    <RadioButton
                        value="online banking"
                        color="black"
                        disabled={!isOnlineBankingEnabled}
                    />
                    <Text
                        style={{
                            fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                            fontSize: 18,
                            marginTop: 8,
                            marginLeft: 10
                        }}>
                        Online Banking
                    </Text>
                </View>
                {isOnlineBankingEnabled ? (
                    <View style={{ flexDirection: 'row', position: 'relative' }}>
                        <Screen minWidth={576}>
                            <Image
                                source={require('../../assets/icon/bank-icon.png')}
                                resizeMode="stretch"
                                style={{
                                    height: 30,
                                    width: 30,
                                    alignSelf: 'center',
                                    marginRight: 10
                                }}
                            />
                        </Screen>
                        <Popover
                            placement="right"
                            isOpen={
                                this.state.isPopoverOpen !== undefined
                                    ? this.state.isPopoverOpen
                                    : this.state.selectedPaymentType === 'online banking'
                            }
                            shouldCloseOnOutsideClick
                            trigger={
                                <View>
                                    <IconButton
                                        style={{ paddingBottom: 15 }}
                                        icon="play"
                                        onPress={() => this.onSelectPayment('online banking')}
                                    />
                                </View>
                            }>
                            <Popover.Backdrop
                                onPress={() => this.setState({ isPopoverOpen: false })}
                            />
                            <Popover.Content>
                                <RadioButton.Group
                                    value={this.state.selectedPaymentSubtype}
                                    onValueChange={this.onSelectPaymentSubtype}>
                                    <View
                                        style={{
                                            paddingHorizontal: 20,
                                            paddingTop: 15,
                                            backgroundColor: '#F69220',
                                            width: 173,
                                            height: 188,
                                            borderRadius: 10
                                        }}>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                marginBottom: 5
                                            }}>
                                            <RadioButton
                                                value={PaymentChannel.Instapay}
                                                color="black"
                                                disabled
                                            />
                                            <Image
                                                source={require('../../assets/logo/instapay.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 18,
                                                    width: 54,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                marginBottom: 5
                                            }}>
                                            <RadioButton
                                                value={PaymentChannel.UnionBank}
                                                color="black"
                                            />
                                            <Image
                                                source={require('../../assets/logo/ub.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 20,
                                                    width: 62,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                marginBottom: 5
                                            }}>
                                            <RadioButton
                                                value={PaymentChannel.Rcbc}
                                                color="black"
                                            />
                                            <Image
                                                source={require('../../assets/logo/rcbc.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 23,
                                                    width: 33,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10
                                            }}>
                                            <RadioButton value={PaymentChannel.Bpi} color="black" />
                                            <Image
                                                source={require('../../assets/logo/bpi.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 20,
                                                    width: 38,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                    </View>
                                </RadioButton.Group>
                            </Popover.Content>
                        </Popover>
                    </View>
                ) : (
                    <View />
                )}
            </>
        )

        const renderCreditDebit = () => (
            <>
                <View style={{ flexDirection: 'row' }}>
                    <RadioButton
                        value={PaymentChannel.Card}
                        color="black"
                        disabled={!isCardEnabled}
                    />
                    <Text
                        style={{
                            fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                            fontSize: 18,
                            marginTop: 8,
                            marginLeft: 10
                        }}>
                        Credit/Debit Card
                    </Text>
                </View>
                <View style={{ flexDirection: 'row', position: 'relative' }}>
                    <Screen minWidth={576}>
                        <Image
                            source={require('../../assets/icon/credit-card.png')}
                            resizeMode="stretch"
                            style={{ height: 30, width: 30, alignSelf: 'center', marginRight: 10 }}
                        />
                    </Screen>
                    <View>
                        <IconButton
                            style={{ paddingBottom: 15 }}
                            style={{ opacity: 0 }}
                            icon="play"
                            onPress={() => this.onSelectPayment('over the counter')}
                        />
                    </View>
                </View>
            </>
        )

        const renderOverTheCounter = () => (
            <>
                <View style={{ flexDirection: 'row' }}>
                    <RadioButton value="over the counter" color="black" disabled={!isOTCEnabled} />
                    <Text
                        style={{
                            fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                            fontSize: 18,
                            marginTop: 8,
                            marginLeft: 10
                        }}>
                        Over-the-counter
                    </Text>
                </View>
                {isOTCEnabled ? (
                    <View style={{ flexDirection: 'row', position: 'relative' }}>
                        <Screen minWidth={576}>
                            <Image
                                source={require('../../assets/icon/counter-icon.png')}
                                resizeMode="stretch"
                                style={{
                                    height: 30,
                                    width: 30,
                                    alignSelf: 'center',
                                    marginRight: 10
                                }}
                            />
                        </Screen>
                        <Popover
                            placement="right"
                            isOpen={
                                this.state.isPopoverOpen !== undefined
                                    ? this.state.isPopoverOpen
                                    : this.state.selectedPaymentType === 'over the counter'
                            }
                            trigger={
                                <View>
                                    <IconButton
                                        style={{ paddingBottom: 15 }}
                                        icon="play"
                                        onPress={() => this.onSelectPayment('over the counter')}
                                    />
                                </View>
                            }>
                            <Popover.Backdrop
                                onPress={() => this.setState({ isPopoverOpen: false })}
                            />
                            <Popover.Content>
                                <RadioButton.Group
                                    value={this.state.selectedPaymentSubtype}
                                    onValueChange={this.onSelectPaymentSubtype}>
                                    <View
                                        style={{
                                            paddingHorizontal: 20,
                                            paddingTop: 15,
                                            backgroundColor: '#F69220',
                                            width: 183,
                                            height: '100%',
                                            borderRadius: 10
                                            // overflow: 'scroll'
                                        }}>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                marginBottom: 5
                                            }}>
                                            <RadioButton
                                                value={PaymentChannel.Seven11}
                                                color="black"
                                            />
                                            <Image
                                                source={require('../../assets/logo/711.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 22,
                                                    width: 25,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                marginBottom: 5
                                            }}>
                                            <RadioButton
                                                value={PaymentChannel.BayadCenter}
                                                color="black"
                                            />
                                            <Image
                                                source={require('../../assets/logo/bayadcenter.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 23,
                                                    width: 23,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                marginBottom: 5
                                            }}>
                                            <RadioButton
                                                value={PaymentChannel.Cebuana}
                                                color="black"
                                            />
                                            <Image
                                                source={require('../../assets/logo/cebuana.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 18,
                                                    width: 43,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                marginBottom: 5
                                            }}>
                                            <RadioButton value={PaymentChannel.Lbc} color="black" />
                                            <Image
                                                source={require('../../assets/logo/lbc.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 22,
                                                    width: 24,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                marginBottom: 5
                                            }}>
                                            <RadioButton
                                                value={PaymentChannel.MLhuillier}
                                                color="black"
                                            />
                                            <Image
                                                source={require('../../assets/logo/mlhuillier.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 17,
                                                    width: 75,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                marginBottom: 5
                                            }}>
                                            <RadioButton value={PaymentChannel.SM} color="black" />
                                            <Image
                                                source={require('../../assets/logo/supermarket.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 20,
                                                    width: 35,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: 'white',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                marginBottom: 5
                                            }}>
                                            <RadioButton
                                                value={PaymentChannel.USSC}
                                                color="black"
                                            />
                                            <Image
                                                source={require('../../assets/logo/ussc.png')}
                                                resizeMode="stretch"
                                                style={{
                                                    height: 15,
                                                    width: 38,
                                                    alignSelf: 'center',
                                                    marginRight: 10,
                                                    marginLeft: 5
                                                }}
                                            />
                                        </View>
                                        {/* <View style={{ flexDirection: 'row', backgroundColor: 'white', borderRadius: 5, paddingLeft: 10 }}>
                                <RadioButton value={PaymentChannel} color='black' />
                                <Image
                                  source={require('../../assets/logo/villarica.png')}
                                  resizeMode='stretch'
                                  style={{ height: 14, width: 40, alignSelf: 'center', marginRight: 10, marginLeft: 5 }}
                                />
                              </View> */}
                                    </View>
                                </RadioButton.Group>
                            </Popover.Content>
                        </Popover>
                    </View>
                ) : (
                    <View />
                )}
            </>
        )

        const renderEWallets = () => (
            <>
                <View style={{ flexDirection: 'row' }}>
                    <RadioButton value="e-wallets" color="black" disabled={!isCardEnabled} />
                    <Text
                        style={{
                            fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                            fontSize: 18,
                            marginTop: 8,
                            marginLeft: 10
                        }}>
                        E-Wallets
                    </Text>
                </View>
                <View style={{ flexDirection: 'row', position: 'relative' }}>
                    <Screen minWidth={576}>
                        <Image
                            source={require('../../assets/icon/ewallet.png')}
                            resizeMode="stretch"
                            style={{ height: 30, width: 30, alignSelf: 'center', marginRight: 10 }}
                        />
                    </Screen>
                    <Popover
                        placement="right"
                        isOpen={
                            this.state.isPopoverOpen !== undefined
                                ? this.state.isPopoverOpen
                                : this.state.selectedPaymentType === 'e-wallets'
                        }
                        trigger={
                            <View>
                                <IconButton
                                    style={{ paddingBottom: 15 }}
                                    icon="play"
                                    onPress={() => this.onSelectPayment('e-wallets')}
                                />
                            </View>
                        }>
                        <Popover.Backdrop onPress={() => this.setState({ isPopoverOpen: false })} />
                        <Popover.Content>
                            <RadioButton.Group
                                value={this.state.selectedPaymentSubtype}
                                onValueChange={this.onSelectPaymentSubtype}>
                                <View
                                    style={{
                                        paddingHorizontal: 20,
                                        paddingTop: 15,
                                        backgroundColor: '#F69220',
                                        width: 173,
                                        height: 110,
                                        borderRadius: 10
                                    }}>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            backgroundColor: 'white',
                                            borderRadius: 5,
                                            paddingLeft: 10,
                                            marginBottom: 5
                                        }}>
                                        <RadioButton
                                            disabled={!isGcashEnabled}
                                            value={PaymentChannel.Gcash}
                                            color="black"
                                        />
                                        <Image
                                            source={require('../../assets/logo/gcash.png')}
                                            resizeMode="stretch"
                                            style={{
                                                height: 20,
                                                width: 64,
                                                alignSelf: 'center',
                                                marginRight: 10,
                                                marginLeft: 5
                                            }}
                                        />
                                    </View>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            backgroundColor: 'white',
                                            borderRadius: 5,
                                            paddingLeft: 10
                                        }}>
                                        <RadioButton value={PaymentChannel.Grabpay} color="black" />
                                        <Image
                                            source={require('../../assets/logo/grabpay.png')}
                                            resizeMode="stretch"
                                            style={{
                                                height: 19,
                                                width: 34,
                                                alignSelf: 'center',
                                                marginRight: 10,
                                                marginLeft: 5
                                            }}
                                        />
                                    </View>
                                </View>
                            </RadioButton.Group>
                        </Popover.Content>
                    </Popover>
                </View>
            </>
        )

        return (
            <>
                <View>
                    <Screen minWidth={576}>
                        <Title
                            style={{
                                marginLeft: 30,
                                fontSize: 20,
                                fontWeight: '600',
                                fontFamily:
                                    'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                                marginBottom: 15
                            }}>
                            ACCOUNT BALANCE
                        </Title>
                    </Screen>
                    <Screen maxWidth={575}>
                        <Title
                            style={{
                                marginLeft: 30,
                                fontSize: 20,
                                textAlign: 'center',
                                fontWeight: '600',
                                fontFamily:
                                    'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                                marginBottom: 15
                            }}>
                            ACCOUNT BALANCE
                        </Title>
                    </Screen>
                    <Screen minWidth={576} style={{ height: 282, width: 489.9 }}>
                        {this.renderCard()}
                    </Screen>
                    <Screen maxWidth={575} style={{ height: 282, width: 300, alignSelf: 'center' }}>
                        {this.renderCard()}
                    </Screen>
                    <Screen minWidth={576} style={{ marginLeft: 18 }}>
                        <Title>Amount Of Deposit</Title>
                        <TextInput
                            value={this.state.selectedAmount}
                            onChangeText={this.onChangeAmount}
                            placeholder="Input Amount ($)"
                            placeholderTextColor="#00000080"
                            keyboardType="numeric"
                            style={{ backgroundColor: 'white', padding: 10, width: 450 }}
                        />
                        <Screen minWidth={1101} style={{ width: 450 }}>
                            <Button
                                onPress={this.otpProvider?.onRequestOtp}
                                style={{
                                    backgroundColor: '#F68C29',
                                    width: 154,
                                    alignSelf: 'flex-end',
                                    borderRadius: 10,
                                    marginTop: 10
                                }}
                                labelStyle={{ color: 'white' }}
                                disabled={
                                    this.isButtonDisabled() || this.props.generatePaymentLoading
                                }>
                                {this.props.generatePaymentLoading ? 'Loading...' : 'Deposit'}
                            </Button>
                        </Screen>
                    </Screen>
                    <Screen maxWidth={575} style={{ marginLeft: 18 }}>
                        <Title>Amount Deposit</Title>
                        <TextInput
                            value={this.state.selectedAmount}
                            onChangeText={this.onChangeAmount}
                            placeholder="Input Amount ($}"
                            placeholderTextColor="#00000080"
                            keyboardType="numeric"
                            style={{ backgroundColor: 'white', padding: 10, width: 250 }}
                        />
                    </Screen>
                </View>
                <RadioButton.Group
                    value={this.state.selectedPaymentType}
                    onValueChange={this.onSelectPayment}>
                    <Screen minWidth={576} style={styles.radioButtonStyle}>
                        {renderOnlineBanking()}
                    </Screen>
                    <Screen maxWidth={575} style={[styles.radioButtonStyle, { width: 240 }]}>
                        {renderOnlineBanking()}
                    </Screen>

                    <Screen minWidth={576} style={styles.radioButtonStyle}>
                        {renderCreditDebit()}
                    </Screen>
                    <Screen maxWidth={575} style={[styles.radioButtonStyle, { width: 240 }]}>
                        {renderCreditDebit()}
                    </Screen>

                    <Screen minWidth={576} style={styles.radioButtonStyle}>
                        {renderOverTheCounter()}
                    </Screen>
                    <Screen maxWidth={575} style={[styles.radioButtonStyle, { width: 240 }]}>
                        {renderOverTheCounter()}
                    </Screen>

                    <Screen minWidth={576} style={styles.radioButtonStyle}>
                        {renderEWallets()}
                    </Screen>
                    <Screen maxWidth={575} style={[styles.radioButtonStyle, { width: 240 }]}>
                        {renderEWallets()}
                    </Screen>
                    <Screen maxWidth={1100}>
                        <Button
                            onPress={this.otpProvider?.onRequestOtp}
                            style={{
                                backgroundColor: '#F68C29',
                                width: 154,
                                alignSelf: 'center',
                                borderRadius: 10,
                                marginTop: 10
                            }}
                            labelStyle={{ color: 'white' }}
                            disabled={this.isButtonDisabled() || this.props.generatePaymentLoading}>
                            {this.props.generatePaymentLoading ? 'Loading...' : 'Deposit'}
                        </Button>
                    </Screen>
                </RadioButton.Group>
            </>
        )
    }

    render() {
        return (
            <>
                <PostPaymentModal
                    phpAmount={this.props.phpValue * Number(this.state.selectedAmount ?? 0)}
                    flow={this.state.postPaymentFlow}
                    isOpen={this.state.isPostPaymentModalOpen}
                    handlePostPaymentModal={this.handlePostPaymentModal}
                    onResetState={this.onResetState}
                />
                {this.props.isWalletLoading ? (
                    <View style={{ width: '100%', height: 400, justifyContent: 'center' }}>
                        <ActivityIndicator animating={true} color="#F68C29" size="large" />
                    </View>
                ) : (
                    <OtpProvider
                        flow="Deposit"
                        onProceedFlow={this.onGenerateCode}
                        exposedProperties={(otpProperties: OtpProperties) =>
                            (this.otpProvider = otpProperties)
                        }>
                        {/* {this.state.selectedPaymentType && <Script
              url="https://cdn.omise.co/omise.js"
              onLoad={() => handleLoadScript(this.state.selectedPaymentType)}
            />} */}
                        {/* Overview */}
                        <Alert
                            autoClose
                            onClose={this.props.onClearGeneratePayment}
                            visible={this.props.generatePaymentError}
                            type="error"
                            style={{ width: '100%' }}>
                            <ArtText
                                style={{ fontSize: 20, textAlignVertical: 'center' }}
                                label="Something went wrong. Please try again."
                            />
                        </Alert>
                        <Screen
                            minWidth={1101}
                            style={{
                                paddingHorizontal: 10,
                                paddingVertical: 20,
                                borderColor: 'black',
                                borderWidth: 1,
                                backgroundColor: '#F6F6F6',
                                borderRadius: 10,
                                flexDirection: 'row'
                            }}>
                            {/* <Screen minWidth={1323} style={{ paddingHorizontal: 15, paddingVertical: 20, borderColor: 'black', borderWidth: 1, backgroundColor: '#F6F6F6', borderRadius: 10, flexDirection: 'row' }}> */}
                            {this.renderContent()}
                        </Screen>
                        <Screen
                            maxWidth={1100}
                            style={{
                                paddingHorizontal: 10,
                                paddingVertical: 20,
                                borderColor: 'black',
                                borderWidth: 1,
                                backgroundColor: '#F6F6F6',
                                borderRadius: 10,
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            {this.renderContent()}
                        </Screen>
                    </OtpProvider>
                )}
            </>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        user: state.api.user.authUser.response,
        wallet: state.api.wallet.getFunds.response,
        isWalletLoading: state.api?.wallet?.getFunds?.loading === true,
        walletError: state.api.wallet?.getFunds?.statusText === 'error',
        updateWalletLoading: state.api.wallet?.updateWallet?.loading,
        updateWallet: state.api.wallet?.updateWallet,
        sgdValue: state.api?.wallet?.getForex?.response?.SGD ?? 0,
        systemFields: state.api?.systemParam?.getAllSysParams?.response ?? [],
        generatePaymentLoading: state.api?.wallet?.generatePaymentCode?.loading,
        generatePaymentError: state.api?.wallet?.generatePaymentCode?.statusText === 'error',
        phpValue: state.api?.wallet?.getForex?.response?.PHP ?? 0
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onGetFunds: (id: string) => getFunds(dispatch, id),
    onAddFunds: (id: string, data: any) => updateWallet(dispatch, id, data),
    clearUpdateWallet: () => dispatch({ type: CLEAR_UPDATE_WALLET }),
    onValidateReservedItem: () => validateReservedItem(dispatch),
    setNotifCount: (count: number) => dispatch(setNotificationCount(count)),
    onSetGlobalLoading: (isLoading: boolean) =>
        dispatch({ type: 'custom.isGlobalLoading', value: isLoading }),
    onSetGlobalError: (isShowError: boolean) =>
        dispatch({ type: 'custom.isGlobalError', value: isShowError }),
    onGeneratePaymentUrl: (data: any) => generatePaymentCode(dispatch, data),
    onClearGeneratePayment: () => dispatch({ type: CLEAR_GENERATE_PAYMENT_CODE }),
    onGetRefCode: (refCode: string) => getAuditLogs(dispatch, { field: 'refCode', value: refCode })
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(injectIntl(WalletDetailComponent as any)))
