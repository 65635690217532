import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

export const notificationStyles = StyleSheet.create({
    normalText: {
        fontFamily: "'Montserrat', sans-serif",
    },
    boldText: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 'bold'
    },
    italicText: {
        fontFamily: "'Montserrat', sans-serif",
        fontStyle: 'italic'
    },
    tableCol: {
        flexDirection: 'row',
        height: 60,
        justifyContent: 'space-between',
        borderBottomColor: '#EDEDED',
        borderBottomWidth: 1,
        borderStyle: 'dashed'
    },
    tableHeader: {
        borderRightWidth: 1,
        borderColor: '#898989'
    },
    borderRight: {
        borderRightWidth: 2,
        borderColor: '#898989'
    },
    basicLabel: {
        fontSize: 13,
        textAlign: 'center',
        fontFamily: "'Montserrat', sans-serif",
    },
    orangeLabel: {
        color: '#FFFFFF',
        backgroundColor: '#F7931E'
    },
    activeTitle: {
        textTransform: 'uppercase',
        color: '#F7931E',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        marginHorizontal: 20
    },
    row: {
        width: '20%',
        flexDirection: 'row',
        padding: 10,
        height: 120,
        justifyContent: 'space-between'
    },
    rowTextContainer: {
        justifyContent: 'center',
        alignSelf: 'center',
        width: '90%'
    },
    profileIcon: {
        backgroundColor: '#FFFFFF',
        borderColor: '#F7931E',
        borderWidth: 1,
        alignSelf: 'center',
        marginRight: 10,
    },
    table: {
        backgroundColor: '#FEF4E9',
        borderRadius: 10,
        flexDirection: 'row',
        marginBottom: 12,
        flexWrap: 'wrap',
        width: '100%'
    },
    notifIcon: {
        backgroundColor: '#FFFFFF',
        borderColor: '#F7931E',
        borderWidth: 1,
        borderRadius: 40,
        alignSelf: 'center',
        marginRight: 10,
        overflow: 'hidden'
    },
})