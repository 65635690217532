import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Wallet } from '.'

export const BEGIN_GET_FOREX = 'API/ITEM/BEGIN_GET_FOREX'
export const SUCCESS_GET_FOREX = 'API/ITEM/SUCCESS_GET_FOREX'
export const ERROR_GET_FOREX = 'API/ITEM/ERROR_GET_FOREX'

export const getForexInitialState = {
    response: undefined,
    loading: false
}

export interface GetForexState {
    response: Wallet | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface GetForexAction {
    type: string
    [item: string]: any
}

export const reducer = (newState: GetForexState = getForexInitialState, action: GetForexAction) => {
    switch (action.type) {
        case BEGIN_GET_FOREX:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_FOREX:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_GET_FOREX:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}

export const beginGetForex = () => ({
    type: BEGIN_GET_FOREX,
})

export const successGetForex = (response: AxiosResponse) => ({
    type: SUCCESS_GET_FOREX,
    data: response.data.payload,
    status: response.status
})

export const errorGetForex = (error: AxiosError) => ({
    type: ERROR_GET_FOREX,
    status: error.response?.status,
})

export const getForex = (dispatch: Dispatch, pathParams: any) => {
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url: `/wallet/convert`,
        params: {
            base: pathParams.from,
            symbol: pathParams.to
        }
    }
    dispatch(beginGetForex())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successGetForex(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetForex(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer