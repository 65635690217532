import _ from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ReactModal from 'react-modal'
import { Image, Pressable, Text, View } from 'react-native'
import { Button, Title } from 'react-native-paper'
import OtpInput from 'react-otp-input'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { RouteName } from '../../navigation/types'
import { CLEAR_FORGOT_PWD_OTP_REQ, forgotPwdOtpReq } from '../../reducers/ForgotPassword/otpRequest'
import { CLEAR_VERIFY_EMAIL } from '../../reducers/ForgotPassword/verifyEmail'
import { CLEAR_VERIFY_OTP, verifyOTP } from '../../reducers/ForgotPassword/verifyOTP'
import { User } from '../../reducers/User'
import { AuthUserInfoState } from '../../reducers/User/user'
import { ArtText, ArtView, Screen } from '../../ReusableComponents'
import { responseCode, responseMsg } from '../../utils/response'
import { ReduxAppState } from '../../utils/types'
import AlertMessage from '../ForgotPassword/component/AlertMessage'
import styles from './styles'

export const modalCustomStyles = {
    content: {
        // top: '100%',
        // left: '100%',
        // right: 'auto',
        // bottom: 'auto',
        // marginRight: '-50%',
        // transform: 'translate(-50%, -50%)',
        // width: 'auto',
        // maxHeight: '100%'
        border: 0
    },
    overlay: {
        backgroundColor: 'white',
        display: 'block'
    }
}

class OtpModal extends React.Component<any, any> {
    timer!: NodeJS.Timer
    constructor(props: any) {
        super(props)
        this.state = {
            otpCode: '',
            isLoading: false,
            startTimer: false,
            seconds: 1
        }
    }

    onChangeValue = (value: string) => {
        this.setState({ otpCode: value })
    }

    tick = () => {
        if (this.state.seconds > 0) {
            this.setState({ seconds: this.state.seconds - 1 })
        } else {
            if (this.state.startTimer) {
                this.setState({ startTimer: false })
            }
            clearInterval(this.timer)
        }
    }

    onResendOTP = () => {
        const user: AuthUserInfoState = this.props.user
        const data = {
            userId: user?.isSignedIn
                ? user?.response?.id
                : this.props.verifyEmailResp?.selectedUser?.id,
            isUpdate: true,
            flow: this.props.flow,
            template: 'email-2fa-otp'
        }

        this.props.onClearForgotPwdOtpReq()
        this.props
            .requestOTP(data)
            .then(() => {
                // Max OTP sent count
                if (this.props.otpReqStatus !== responseCode.OTP_SUCCESSFUL) {
                    this.setState(
                        {
                            isLoading: false,
                            isSuccessResendOTP: false,
                            isResendOTP: true,
                            isAlertMessage: true,
                            isReset: false,
                            isVerifyError: false,
                            isSubmit: false,
                            errMsg: responseMsg[this.props.otpReqStatus]
                        },
                        () => (this.timer = setInterval(this.tick, 1000))
                    )
                } else {
                    if (this.state.seconds > 0 && !this.state.startTimer) {
                        this.setState(
                            {
                                startTimer: true,
                                seconds: 30,
                                isLoading: false,
                                isSuccessResendOTP: true,
                                isResendOTP: true,
                                isReset: false,
                                isVerifyError: false,
                                isSubmit: false
                            },
                            () => (this.timer = setInterval(this.tick, 1000))
                        )
                    }
                }
            })
            .catch(() => {
                this.setState({ isVerifyError: true, isLoading: false, isVerifiedOTP: false })
            })
    }

    onSubmit = () => {
        this.setState({
            isSubmit: true,
            isReset: false,
            isLoading: true,
            isSuccessResendOTP: false,
            isResendOTP: false,
            isVerifyError: false
        })

        if (!this.state.otpCode || this.state.otpCode.length !== 6) {
            this.setState({ isLoading: false })
            return
        }
        const user: AuthUserInfoState = this.props.user
        const data = {
            otpId: this.props.otpReqResponse?.id,
            userId: user?.isSignedIn
                ? user?.response?.id
                : this.props.verifyEmailResp?.selectedUser?.id,
            otp: this.state.otpCode
        }

        this.props.onSetGlobalLoading(true)
        this.props
            .verifyOTP(data)
            .then(async () => {
                if (this.props.verifyOTPResponse === responseCode.LOCKED_ACC) {
                    this.setState(
                        {
                            errMsg: responseMsg[this.props.verifyOTPResponse],
                            isVerifiedOTP: false,
                            isLoading: false,
                            isAlertMessage: true
                        },
                        () => {
                            this.props.onClearVerifyOTP()
                            this.props.onClearForgotPwdOtpReq()
                            this.props.onClearVerifyEmail()
                            this.props.onSetGlobalLoading(false)
                        }
                    )
                } else if (this.props.verifyOTPResponse !== responseCode.SUCCESS) {
                    this.setState({
                        errMsg: responseMsg[this.props.verifyOTPResponse],
                        isVerifiedOTP: false,
                        isLoading: false,
                        isReset: true
                    })
                    this.props.onSetGlobalLoading(false)
                } else {
                    this.setState({ isLoading: false })
                    this.props.onOpenOTPModal(false)
                    await this.props.proceedFlow()
                    this.props.onSetGlobalLoading(false)
                }
            })
            .catch(() => {
                this.props.onSetGlobalLoading(false)
                this.setState({ isLoading: false, isVerifyError: true })
            })
    }

    onGetButtonTitle = () => {
        let title = `Resend Code`
        if (this.state.startTimer) {
            title = `Resend Code (${this.state.seconds} secs)`
        }
        return title
    }

    render(): React.ReactNode {
        const user: AuthUserInfoState = this.props.user
        let selectedUser
        let _email = ''
        if (user.isSignedIn) {
            selectedUser = user?.response
        } else if (!_.isEmpty(this.props.verifyEmailResp?.selectedUser)) {
            selectedUser = this.props.verifyEmailResp?.selectedUser
        } else {
            selectedUser = undefined
        }
        if (selectedUser) {
            const splitEmail = selectedUser.email.split('@')
            const name = splitEmail[0].split('')
            const emailDomain = splitEmail[1]
            const _numberToHide = Math.round(name.length / 4)
            const splitName = name?.map((_user: string, i: number) =>
                i >= _numberToHide ? '*' : _user
            )
            _email = splitName.join('') + '@' + emailDomain
        }
        // const _email = '123'

        return (
            <ReactModal
                isOpen={this.props.isOtpModalOpen}
                style={modalCustomStyles}
                onRequestClose={() => this.props.onOpenOTPModal(false)}>
                <ArtView style={{ flexWrap: 'wrap-reverse' }}>
                    <View
                        style={{
                            height: 2.5,
                            backgroundColor: '#9D9D9D',
                            marginHorizontal: 20,
                            width: '80%',
                            marginBottom: 25
                        }}
                    />
                    <View style={{ flexDirection: 'row' }}>
                        <Image
                            source={require('../../assets/artshare-logo.png')}
                            style={{ height: 50, width: 50 }}
                            resizeMode="stretch"
                        />
                        <View style={{ marginLeft: 10 }}>
                            <ArtText
                                style={styles.titleText}
                                label={<FormattedMessage id="HeaderContent.msg.title" />}
                            />
                        </View>
                    </View>
                </ArtView>
                <View style={styles.formGroup}>
                    {this.state.errMsg && (
                        <Pressable
                            style={{
                                backgroundColor: '#FF4B4B',
                                paddingHorizontal: 20,
                                paddingVertical: 10,
                                borderRadius: 10,
                                marginBottom: 20
                            }}>
                            <Text
                                style={{
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontSize: 16,
                                    color: 'white'
                                }}>
                                {this.state.errMsg}
                            </Text>
                        </Pressable>
                    )}
                    <View style={{ marginLeft: 10, marginRight: 20 }}>
                        <Title
                            style={[
                                styles.textStyle,
                                styles.title,
                                { fontSize: 24, marginBottom: 20 }
                            ]}>
                            {this.props.flow} Request
                        </Title>
                        <Text style={[styles.textStyle, { fontSize: 14, marginBottom: 30 }]}>
                            {`We emailed a 6-digit code to `}
                            <Text style={{ fontWeight: 'bold' }}>{`${_email}.`}</Text>
                            {` Enter the code below to confirm your transaction.`}
                        </Text>
                    </View>
                    <View style={{ width: 400 }}>
                        <OtpInput
                            value={this.state.otpCode}
                            onChange={(val: string) => this.onChangeValue(val)}
                            numInputs={6}
                            isInputNum={true}
                            inputStyle={{
                                width: 50,
                                height: 60,
                                margin: 5,
                                fontSize: 18,
                                borderRadius: 4,
                                borderWidth: 1,
                                borderColor: '#C6BEBE',
                                fontWeight: 'bold',
                                fontFamily: "'Montserrat', sans-serif",
                                fontColor: '#C6BEBE'
                            }}
                            containerStyle={{
                                padding: 10,
                                borderRadius: 4,
                                backgroundColor: '#F5F5F5',
                                width: 400,
                                height: 70
                            }}
                        />
                    </View>
                    <Button
                        loading={this.props.isLoadingOTPReq}
                        disabled={this.state.startTimer || this.props.isLoadingOTPReq}
                        onPress={this.onResendOTP}
                        style={[
                            {
                                backgroundColor: '#C6BEBE',
                                maxWidth: 230,
                                borderRadius: 10,
                                marginVertical: 20
                            }
                        ]}
                        labelStyle={{
                            color: 'black',
                            fontFamily: "'Montserrat', sans-serif",
                            textAlign: 'left',
                            fontSize: 12,
                            padding: 0
                        }}>
                        {this.onGetButtonTitle()}
                    </Button>
                    <View style={[styles.formGroup2, { flexDirection: 'row' }]}>
                        <Button
                            loading={this.state.isLoading}
                            disabled={this.state.isLoading}
                            onPress={this.onSubmit}
                            style={[styles.registerBtn, { width: 200 }]}
                            labelStyle={styles.textWhite}>
                            Verify
                        </Button>
                        <Button
                            onPress={() => this.props.onOpenOTPModal(false)}
                            style={[
                                styles.registerBtn,
                                { width: 230, backgroundColor: '#BDBDBD', marginLeft: 20 }
                            ]}
                            labelStyle={{
                                color: 'black',
                                fontFamily: "'Montserrat', sans-serif",
                                textAlign: 'left',
                                fontSize: 12,
                                padding: 0
                            }}>
                            Cancel
                        </Button>
                    </View>
                </View>
            </ReactModal>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _otpReq = state.api?.forgotPwd?.forgotPwdOtpReq
    const _verifyOTP = state.api?.forgotPwd?.verifyOTP
    const _verifyEmail = state.api?.forgotPwd?.verifyEmail
    return {
        isLoadingOTPReq: _otpReq.loading,
        otpReqResponse: _otpReq?.response,
        otpReqStatus: _otpReq?.statusText,
        isLoadingVerifyOTP: _verifyOTP.loading,
        verifyOTPResponse: _verifyOTP.response,
        verifyEmailResp: _verifyEmail.response,
        isVerifiedEmail: _verifyEmail.response?.userResponse === responseCode.USER_FOUND,
        isOtpModalOpen: state?.custom?.otpModal,
        user: state?.api?.user?.authUser
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    requestOTP: (data: any) => forgotPwdOtpReq(dispatch, data),
    verifyOTP: (data: any) => verifyOTP(dispatch, data),
    onClearForgotPwdOtpReq: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ }),
    onClearVerifyOTP: () => dispatch({ type: CLEAR_VERIFY_OTP }),
    onClearVerifyEmail: () => dispatch({ type: CLEAR_VERIFY_EMAIL }),
    onOpenOTPModal: (value: boolean) => dispatch({ type: 'custom.otpModal', value }),
    onSetGlobalLoading: (isLoading: boolean) =>
        dispatch({ type: 'custom.isGlobalLoading', value: isLoading })
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(OtpModal))
