import { combineReducers } from "redux"
import getPortfolioReducer, { getPortfolioInitialState, GetPortfolioState } from "./getPortfolio"


export interface Portfolio {
    numberOfShare: number
    userId: string
    status: number
    itemId: string
    id: string
    valuePerShare: number
    totalValue: number
    [a: string]: any
}

export const portfolioInitialState: PortfolioInitialState = {
    getPortfolio: getPortfolioInitialState
}

export interface PortfolioInitialState {
    getPortfolio: GetPortfolioState
}

const portfolioReducer = combineReducers({
    getPortfolio: getPortfolioReducer
})

export default portfolioReducer
