import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

export const signUpStyles = StyleSheet.create({
    title: {
        alignSelf: 'center',
        fontSize: Platform.OS === 'web' ? 40 : 30,
        marginTop: 15,
        marginBottom: 45
    },
    formStyle: {
        // alignItems: 'center',
        width: '100%',
        backgroundColor: '#fff',
        height: '100%',
        // padding: '4%',
        marginTop: -10
    },
    formShadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 5
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,
        elevation: 10,
        marginTop: 100
    },
    formGroup: {
        marginBottom: 12,
        marginHorizontal: 20
    },
    input: {
        flex: 1,
        alignSelf: 'flex-start',
        color: '#003849',
        marginLeft: 5,
        backgroundColor: '#FFFFFF',
        borderColor: '#FFFFFF',
        paddingHorizontal: 10,
        fontSize: 14,
        borderWidth: 1,
        borderRadius: 15,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: '800',
        width: '100%',
        marginBottom: 13,
        padding: 10
    },
    dropdown: {
        backgroundColor: '#D1D3D4',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#fff',
        fontSize: 16,
        height: 50
        // width: 300
    },
    fields: {
        alignSelf: 'flex-start',
        fontWeight: '900'
    },
    greenBtn: {
        backgroundColor: '#AFCD37',
        borderRadius: 8,
        alignSelf: 'center',
        width: '90%',
        fontSize: 20,
        marginVertical: 15,
        borderWidth: 1,
        borderColor: 'black'
    },
    checkboxContainer: {
        flexDirection: 'row'
    },
    checkbox: {
        flex: 1
    },
    registerBtn: {
        // marginBottom: 20,
        marginTop: 20,
        marginBottom: 30,
        width: '40%',
        backgroundColor: '#F7931E',
        alignSelf: 'center'
    },
    textWhite: {
        color: 'white',
        fontSize: 15,
        fontFamily: "'Montserrat', sans-serif",
        textTransform: 'none'
    },
    textBlack: {
        color: 'black'
    },
    rowContainer: {
        flexDirection: 'row',
        position: 'relative',
        alignItems: 'center'
    },
    floatRight: {
        width: '30%',
        position: 'absolute',
        right: 0
    },
    policyAndTerms: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12
    },
    requirementsContainer: {
        marginLeft: 8
    },
    requirementText: {
        fontSize: 15,
        color: cpebColors.labelColor,
        fontWeight: '900'
    },
    errorColor: {
        color: 'red'
    },
    successColor: {
        color: 'green'
    },
    plainColor: {
        color: cpebColors.labelColor
    },
    fileName: {
        fontSize: 10,
        marginBottom: 10
    },
    alignSelfCenter: {
        alignSelf: 'center'
    },
    requiredIcon: {
        color: 'red'
    },
    errorBorder: {
        borderColor: 'red'
    },
    textCenter: {
        textAlign: 'center'
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        alignContent: 'center',
        width: '100%'
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorMessage: {
        padding: 10,
        borderColor: 'red',
        borderWidth: 2,
        backgroundColor: 'white',
        color: 'black',
        alignSelf: 'center',
        width: '100%',
        alignItems: 'center',
        marginBottom: 25
    },
    fieldLabelStyle: {
        fontSize: 14,
        // margin: '10%'
        fontWeight: 'bold',
        width: '100%',
        fontFamily: "'Montserrat', sans-serif"
    },
    fieldFormGroupStyle: {
        flexDirection: 'column',
        marginHorizontal: 10,
        width: '90%'
        // justifyContent: 'space-evenly'
        // justifyContent: 'space-between'
    }
})
