import uuid from 'react-native-uuid';


export const generateRandomId = () => String(uuid.v4())

export enum PaymentChannel {
    Seven11 = 'SEVEN11',
    BayadCenter = 'BAYD',
    Cebuana = 'CEBL',
    Lbc = 'LBC',
    MLhuillier = 'MLH',
    SM = 'SMR',
    Robinson = 'RDS',
    ECPay = 'ECPY',
    RDPawnshop = 'RDP',
    Palawan = 'PLWN',
    Ruralnet = 'RLNT',
    Posible = 'POSSIBLE',
    PartnerPay = 'PARNERPAY',
    PayNGo = 'PAYNGO',
    Da5 = 'DA5',
    USSC = 'USSC',
    Rustan = 'Rustan',
    Shopwise = 'SHOPWISE',
    Tambunting = 'TAMBUNTING',
    Cvm = 'CVM',
    Wellcome = 'WELLCOME',
    Expresspay = 'EXPY',
    Bpi = 'BPI',
    UnionBank = 'UNIONBANK',
    Gcash = 'GCASH',
    Rcbc = 'RCBC',
    Grabpay = 'GRABPAY',
    Card = 'CARD',
    Billease = 'BNPL',
    Instapay = 'INSTAPAY'
}