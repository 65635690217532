import React from 'react'
import ReactModal from 'react-modal'
import { modalCustomStyles } from './OtpModal'
import { connect } from 'react-redux'
import { Dispatch, compose } from 'redux'
import { FormattedNumber, injectIntl } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import { ActivityIndicator } from 'react-native-paper'
import { GetAuditLogState } from '../../reducers/Audit/getAuditLogs'
import { Audit } from '../../reducers/Audit'
import { isDecimal } from '../../utils/math'
import moment from 'moment'
import { Wallet } from '../../reducers/Wallet'


export interface StateProps {
    getAuditLogs: GetAuditLogState
    paymentCode: Wallet
 }

export interface DispatchProps { }

export interface OwnProps {
    isOpen: boolean
    handlePostPaymentModal: (isOpen: boolean) => void
    onResetState: () => void
    flow: string
    phpAmount: number
}

export type PostPaymentModalProps = OwnProps & DispatchProps & StateProps

export class PostPaymentModal extends React.Component<PostPaymentModalProps, any> {

    renderOverTheCounter = (payment: Wallet) => (
        <>
            <div className='postPaymentModal__logo justify-content-end'>
                <img src='Logo/artshare-logo.png' />
            </div>
            <div className="postPaymentModal__body postPaymentModal__body--grayArea">
                <p className='postPaymentModal__body--medium mt-5'>Reference Number</p>
                <h1 className='postPaymentModal__body--highlight postPaymentModal__body--w90'>{payment?.ref_code}</h1>
                <p className='postPaymentModal__body--small mt-4'>You can pay this reference number on the selected <strong>Counter</strong></p>

                <div className="postPaymentModal__body--borderTop postPaymentModal__body--tableRow mt-5">
                    <p>Amount</p>
                    <p>Php <FormattedNumber value={isDecimal(this.props.phpAmount) ? Number(this.props.phpAmount.toFixed(2)) : this.props.phpAmount} /> </p>
                </div>
                <div className="postPaymentModal__body--borderTop postPaymentModal__body--tableRow">
                    <p>Transaction Fee</p>
                    <p>Php 0.00</p>
                </div>
                <div className="postPaymentModal__body--borderVertical postPaymentModal__body--tableRow">
                    <p>Total Amount</p>
                    <p>Php <FormattedNumber value={isDecimal(this.props.phpAmount) ? Number(this.props.phpAmount.toFixed(2)) : this.props.phpAmount} /></p>
                </div>

                <div className="postPaymentModal__btnContainer mt-3 justify-content-end">
                    <button className="postPaymentModal__btn postPaymentModal__btn--primary">Download</button>
                </div>
            </div>
        </>
    )

    renderFailedMessage = () => (
        <>
            <div className='postPaymentModal__logo justify-content-center mb-5'>
                <img src='Logo/artshare-logo.png' />
            </div>
            <div className="postPaymentModal__body">
                <h1 className=''>Failed to Deposit</h1>
                <p className='postPaymentModal__body--small mb-5'>Your request to deposit has failed.</p>
                <img src='Icons/failed.png' />
            </div>
        </>
    )

    renderSuccessPayment = (audit: Audit | undefined) => (
        <>
            <div className='postPaymentModal__logo justify-content-end'>
                <img src='Logo/artshare-logo.png' />
            </div>
            <div className="postPaymentModal__body postPaymentModal__body--grayArea justify-content-start align-items-start px-5 pt-4">
                <h1 className='postPaymentModal__body--w90'>Successful Payment!</h1>
                <p>Your payment via {audit?.paymentMethod} is complete! Your reference no. is {audit?.refCode}. Please check the payment details below.</p>
                <h3 className='postPaymentModal__body--highlight postPaymentModal__body--textLeft postPaymentModal__body--pl2 w-100 mt-3'>Payment Details</h3>
                <div className="mx-5 w-100">
                    <div className="postPaymentModal__body--tableRow py-2">
                        <p>Reference Number</p>
                        <p>{audit?.refCode}</p>
                    </div>
                    <div className="postPaymentModal__body--tableRow py-2">
                        <p>Merchant Name</p>
                        <p>TRION TRADE INC.</p>
                    </div>
                    <div className="postPaymentModal__body--tableRow py-2">
                        <p>Date Purchased</p>
                        <p>{moment(audit?.tranDate).format('MMMM Do, YYYY, h:mm A')}</p>
                    </div>
                    <div className="postPaymentModal__body--tableRow postPaymentModal__body--borderTop py-2">
                        <p>Description</p>
                        {/* <p>MAY 30, 2023, 00:00 AM</p> */}
                    </div>
                    <div className="postPaymentModal__body--tableRow py-2">
                        <p>Deposit</p>
                        <p><FormattedNumber currency='USD' style='currency' value={Number(audit?.amt ?? 0)}/></p>
                    </div>
                    <div className="postPaymentModal__body--tableRow postPaymentModal__body--borderTop align-items-end justify-content-end pt-2">
                        <p>Amount Paid: PHP <FormattedNumber value={isDecimal(audit?.phpAmount + audit?.fee) ? (audit?.phpAmount + audit?.fee).toFixed(2) : audit} /></p>
                        {/* <p>MAY 30, 2023, 00:00 AM</p> */}
                    </div>
                </div>
            </div>

        </>
    )

    renderContent = () => {
        const audit: Audit | undefined = _.first(this.props.getAuditLogs?.response)
        const payment: Wallet = this.props.paymentCode
        switch(this.props.flow) {
            case 'SuccessPayment':
                return this.renderSuccessPayment(audit)
            case 'FailedPayment':
                return this.renderFailedMessage()
            case 'OTC':
                return this.renderOverTheCounter(payment)
            default:
                return <div />
        }
    }

    render() {
        const isLoading = this.props.getAuditLogs?.loading
        return (
            <ReactModal isOpen={this.props.isOpen} style={modalCustomStyles} id='PostPaymentModal' className='postPaymentModal'>
                <div className='postPaymentModal__content'>
                    {isLoading ? (
                        <>
                            <ActivityIndicator animating={true} color='#AFCD37' size='large' />
                            <h3 className='mt-4'>Loading...</h3>
                        </>
                    ) : (
                        this.renderContent()
                    )}
                    <div className="postPaymentModal__btnContainer mt-5 justify-content-center">
                        <button onClick={this.props.onResetState} className="postPaymentModal__btn postPaymentModal__btn--primary">Return to Dashboard</button>
                    </div>
                </div>
            </ReactModal>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        getAuditLogs: state.api?.audit?.getAuditLogs,
        paymentCode: state.api?.wallet?.generatePaymentCode?.response
    }
  }

  const mapDispatchToProps = (dispatch: Dispatch) => ({
  })

  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(compose(injectIntl(PostPaymentModal as any)))
