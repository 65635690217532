import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native'

import { IntlProvider as MessageProvider } from 'react-intl'

import { DefaultTheme as PaperDefaultTheme, Provider as PaperProvider, Colors } from 'react-native-paper'
import { DefaultTheme as NavigationDefaultTheme } from '@react-navigation/native'

import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import { Provider as StoreProvider, } from 'react-redux'

import AsyncStorage from '@react-native-async-storage/async-storage'
import thunkMiddleware from 'redux-thunk'
import merge from 'deepmerge'

import Routes from './navigation'

import { persistReducer, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import amplify, { Hub } from '@aws-amplify/core'

import formats from './utils/formats'
import config from './config'

import Modal from 'react-modal'

import ApiReducer, { apiInitialState } from './reducers/api'
import CustomReducer, { customInitialState } from './reducers/custom'

const messages = require('../dist/en-us.json')
const staticConfig = require('../static/default.json')
import sessionStorage from 'redux-persist/lib/storage/session'
import navigation from './navigation'
import { composeWithDevTools } from 'redux-devtools-extension'
import { OverlayProvider } from 'react-native-popper';
import { SafeAreaProvider } from 'react-native-safe-area-context'

Modal.setAppElement('#react-root');

// amplify.configure(awsConfig)

const App = () => {
  const reducer = combineReducers({
    api: ApiReducer,
    config: () => Object.assign({}, staticConfig),
    custom: CustomReducer
  })
  const CombinedDefaultTheme = merge(PaperDefaultTheme, NavigationDefaultTheme);
  const persistConfig = {
    key: 'root',
    storage: Platform.OS === 'web' ? sessionStorage : AsyncStorage,
    blacklist: ['custom']
  }
  const persistedReducer = persistReducer(persistConfig, reducer)

  const store = createStore(persistedReducer, {
    api: apiInitialState,
    custom: customInitialState
  }, composeWithDevTools(applyMiddleware(thunkMiddleware)))
  const persistor = persistStore(store)
  const theme = {
    ...CombinedDefaultTheme,
    colors: {
      ...CombinedDefaultTheme.colors,
      background: Platform.OS === 'web' ? '#FFFFFF' : '#EAEAEA'
    }
  }

  return (
    <MessageProvider formats={formats} messages={messages} locale="en" defaultLocale='en'>
      <OverlayProvider>
        <SafeAreaProvider>
          <PaperProvider theme={theme}>
            <StoreProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <Routes theme={theme} />
              </PersistGate>
            </StoreProvider>
          </PaperProvider>
        </SafeAreaProvider>
      </OverlayProvider>
    </MessageProvider>
  )
}

export default App;