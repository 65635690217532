import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import DashboardScreen from '../../components/Dashboard/Dashboard'
import ResetPasswordScreen from '../../components/ResetPassword/ResetPassword'
import WalletScreen from '../../components/Wallet/Wallet'
import SettingsScreen from '../../components/Settings/Settings'
import ListingScreen from '../../components/Listing/Listing'
import ListScreen from '../../components/Listing/List'
import DetailScreen from '../../components/ItemDetails/ItemDetails'
import ActiveListScreen from '../../components/ActiveList/ActiveList'
import ProfileScreen from '../../components/Profile/Profile'
import NotificationScreen from '../../components/Notification/Notification'
import ChangePasswordScreen from '../../components/ChangePassword/ChangePassword'
import VerifyOTPScreen from '../../components/ChangePassword/VerifyOTP'
import { RouteName } from '../types'

const MainStack = createStackNavigator();

export const MainStackScreen = ({ isResetPwd, defaultPage }: { isResetPwd: string, defaultPage?: string }) => {
    const initialParams = {}
    if (window?.location?.search) {
        const tempParams = new URLSearchParams(window.location.search)
        for (const [key, value] of tempParams) {
            initialParams[key] = value
        }
    }
    return (
        <MainStack.Navigator
            initialRouteName={isResetPwd === '1' ? RouteName.ResetPwdPage : defaultPage}
            screenOptions={{
                headerShown: false,
                animationEnabled: false
            }}
        >
            {isResetPwd === '1' ? <MainStack.Screen name={RouteName.ResetPwdPage} component={ResetPasswordScreen} /> : <MainStack.Screen name={RouteName.DashboardPage} component={DashboardScreen} />}
            <MainStack.Screen initialParams={initialParams} name={RouteName.WalletPage} component={WalletScreen} />
            <MainStack.Screen name={RouteName.SettingsPage} component={SettingsScreen} />
            {/* <MainStack.Screen name={RouteName.ListingPage} component={ListingScreen} /> */}
            <MainStack.Screen name={RouteName.ListPage} component={ListScreen} />
            <MainStack.Screen name={RouteName.ItemDetailPage} component={DetailScreen} />
            <MainStack.Screen name={RouteName.ActiveListPage} component={ActiveListScreen} />
            <MainStack.Screen name={RouteName.ProfilePage} component={ProfileScreen} />
            <MainStack.Screen name={RouteName.NotificationPage} component={NotificationScreen} />
            <MainStack.Screen name={RouteName.ChangePasswordPage} component={ChangePasswordScreen} />
            <MainStack.Screen name={RouteName.VerifyOTPPage} component={VerifyOTPScreen} />
        </MainStack.Navigator>

    )
}