import React, { ReactNode } from 'react'
import { StyleProp, Text, TextStyle } from 'react-native'
import { Title } from 'react-native-paper'
import typographyStyle from './style'


export interface TitleProps {
    style?: StyleProp<TextStyle>
    label: string | ReactNode
    labelThickness?: 'bold' | 'normal' | 'mid'
    labelStyle?: 'italic' | 'normal'
    [p: string]: any
}

class ArtText extends React.Component<TitleProps, any> {
    constructor(props: any) {
        super(props)
    }

    render(): React.ReactNode {
        const _fontWeight = this.props.labelThickness ?? 'normal'
        return (
            <Text {...this.props} style={[typographyStyle.text, typographyStyle[_fontWeight], { fontStyle: this.props.labelStyle ?? 'normal' }, this.props.style]}>{this.props.label}</Text>
        )
    }
}

export default ArtText