import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import awsInstance, { methods } from '../../core/axios.config'

export const BEGIN_VERIFY_EMAIL = 'API/USER/BEGIN_VERIFY_EMAIL'
export const SUCCESS_VERIFY_EMAIL = 'API/USER/SUCCESS_VERIFY_EMAIL'
export const ERROR_VERIFY_EMAIL = 'API/USER/ERROR_VERIFY_EMAIL'
export const CLEAR_VERIFY_EMAIL = 'API/USER/CLEAR_VERIFY_EMAIL'

export const verifyEmailInitialState = {
    response: [],
    loading: false
}

export interface VerifyEmailState {
    response: any
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface VerifyEmailActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: VerifyEmailState = verifyEmailInitialState, action: VerifyEmailActions) => {
    switch (action.type) {
        case BEGIN_VERIFY_EMAIL:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_VERIFY_EMAIL:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status,
                error: ''
            })

        case ERROR_VERIFY_EMAIL:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status,
                error: action.message
            })
        case CLEAR_VERIFY_EMAIL:
            return verifyEmailInitialState
        default:
            return newState
    }
}

export const beginVerifyEmail = () => ({
    type: BEGIN_VERIFY_EMAIL,
})

export const successVerifyEmail = (response: any) => ({
    type: SUCCESS_VERIFY_EMAIL,
    data: response.data?.message,
    status: response.status,
})

export const errorVerifyEmail = (error: any) => ({
    type: ERROR_VERIFY_EMAIL,
    message: error.code
})

export const clearVerifyEmail = () => ({
    type: CLEAR_VERIFY_EMAIL
})

export const verifyEmail = async (dispatch: Dispatch, data: any) => {
    const config: AxiosRequestConfig = {
        method: methods.POST,
        url: `/user/verifyemail`,
        data
    }
    dispatch(beginVerifyEmail())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successVerifyEmail(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorVerifyEmail(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })
}

export default reducer