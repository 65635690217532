import getArtShareListReducer, { getArtShareListInitialState, GetArtShareListState } from './getArtShareList'
import getItemListReducer, { getItemListInitialState, GetItemListState } from './getItems'
import getItemInfoReducer, { getItemInfoInitialState, GetItemInfoState } from './getItemInfo'
import addItemReducer, { addItemInitialState, AddItemState } from './addItem'
import sellItemReducer, { sellItemInitialState, SellItemState } from './sellItem'
import addItemToPoolReducer, { addItemToPoolInitialState, AddItemToPoolState } from './addItemToPool'
import buyItemReducer, { buyItemInitialState, BuyItemState } from './buyItem'
import deleteItemReducer, { deleteItemInitialState, DeleteItemState } from './deleteItem'
import validateReservedItemReducer, { validateReservedItemInitialState, ValidateReservedItemState } from './validateReservedItem'
import getReservedItemByUserReducer, { getReservedItemByUserInitialState, GetReservedItemByUserState } from './getReservedItemByUser'
import { combineReducers } from 'redux'

export interface Items {
    id: string
    artist: string
    description: string
    displayLocation: string
    dmt: string
    listedValue: string
    listingDate: Date
    saleDate: Date
    shareOnSale: string
    title: string
    year: string
    numberOfShare: number
    valuePerShare: number
    createdAt: number
    [p: string]: any
}

export const itemInitialState: ItemInitialState = {
    getArtShareList: getArtShareListInitialState,
    getItemList: getItemListInitialState,
    getItemInfo: getItemInfoInitialState,
    addItem: addItemInitialState,
    addItemToPool: addItemToPoolInitialState,
    buyItem: buyItemInitialState,
    deleteItem: deleteItemInitialState,
    validateReservedItem: validateReservedItemInitialState,
    getReservedItemByUser: getReservedItemByUserInitialState,
    sellItem: sellItemInitialState
}

export interface ItemInitialState {
    getArtShareList: GetArtShareListState
    getItemList: GetItemListState
    getItemInfo: GetItemInfoState
    addItem: AddItemState
    buyItem: BuyItemState
    deleteItem: DeleteItemState
    addItemToPool: AddItemToPoolState
    validateReservedItem: ValidateReservedItemState
    getReservedItemByUser: GetReservedItemByUserState
    sellItem: SellItemState
}

const itemReducer = combineReducers({
    getArtShareList: getArtShareListReducer,
    getItemList: getItemListReducer,
    getItemInfo: getItemInfoReducer,
    addItem: addItemReducer,
    buyItem: buyItemReducer,
    deleteItem: deleteItemReducer,
    addItemToPool: addItemToPoolReducer,
    validateReservedItem: validateReservedItemReducer,
    getReservedItemByUser: getReservedItemByUserReducer,
    sellItem: sellItemReducer
})

export default itemReducer

