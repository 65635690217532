import React from 'react'
import { injectIntl } from 'react-intl'
import Modal from 'react-modal'
import { View } from 'react-native'
import { Title } from 'react-native-paper'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import OtpModal from '../../components/Modal/OtpModal'
import { ReduxAppState } from '../../utils/types'


export const modalCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxHeight: '100%'
    }
}

class GlobalModal extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isGlobalModalOpen}
                // isOpen={!!this.state.selectedItemId}
                // onRequestClose={() => this.setState({ selectedItemId: '' })}
                // onRequestClose={this.props.onCloseGlobalModal}
                style={modalCustomStyles}
            >
                {this.props.isOtpModalOpen && <OtpModal />}
            </Modal>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => ({
    isGlobalModalOpen: state.custom?.isGlobalModal,
    isOtpModalOpen: state?.custom?.otpModal
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onCloseGlobalModal: () => dispatch({ type: 'custom.isGlobalModal', value: false }),
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(injectIntl(GlobalModal as any)))
