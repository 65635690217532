import React from 'react'
import { StyleProp } from 'react-native'
import { Title } from 'react-native-paper'
import typographyStyle from './style'


export interface TitleProps {
    style?: StyleProp<any>
    label: string
    labelThickness?: 'bold' | 'normal' | 'mid'
    [p: string]: any
}

class ArtTitle extends React.Component<TitleProps, any> {
    constructor(props: any) {
        super(props)
    }

    render(): React.ReactNode {
        const _fontWeight = this.props.labelThickness ?? 'normal'
        return (
            <Title {...this.props} style={[typographyStyle.title, typographyStyle[_fontWeight], this.props.style]}>{this.props.label}</Title>
        )
    }
}

export default ArtTitle