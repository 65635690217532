import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Wallet } from '.'

export const BEGIN_GENERATE_PAYMENT_CODE = 'API/ITEM/BEGIN_GENERATE_PAYMENT_CODE'
export const SUCCESS_GENERATE_PAYMENT_CODE = 'API/ITEM/SUCCESS_GENERATE_PAYMENT_CODE'
export const ERROR_GENERATE_PAYMENT_CODE = 'API/ITEM/ERROR_GENERATE_PAYMENT_CODE'
export const CLEAR_GENERATE_PAYMENT_CODE = 'API/ITEM/CLEAR_GENERATE_PAYMENT_CODE'

export const generatePaymentCodeInitialState = {
    response: undefined,
    loading: false
}

export interface GeneratePaymentCodeState {
    response: Wallet | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface GeneratePaymentCodeAction {
    type: string
    [item: string]: any
}

export const reducer = (newState: GeneratePaymentCodeState = generatePaymentCodeInitialState, action: GeneratePaymentCodeAction) => {
    switch (action.type) {
        case BEGIN_GENERATE_PAYMENT_CODE:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GENERATE_PAYMENT_CODE:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_GENERATE_PAYMENT_CODE:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        case CLEAR_GENERATE_PAYMENT_CODE:
            return generatePaymentCodeInitialState
        default:
            return newState
    }
}

export const beginGeneratePaymentCode = () => ({
    type: BEGIN_GENERATE_PAYMENT_CODE,
})

export const successGeneratePaymentCode = (response: AxiosResponse) => ({
    type: SUCCESS_GENERATE_PAYMENT_CODE,
    data: response.data.payload,
    status: response.status
})

export const errorGeneratePaymentCode = (error: AxiosError) => ({
    type: ERROR_GENERATE_PAYMENT_CODE,
    status: error.response?.status,
})

export const generatePaymentCode = (dispatch: Dispatch, data: any) => {
    const config: AxiosRequestConfig = {
        method: methods.POST,
        url: `/wallet/topup/generate-code`,
        data
    }

    dispatch(beginGeneratePaymentCode())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successGeneratePaymentCode(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGeneratePaymentCode(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer