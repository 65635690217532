import moment from 'moment'
import React, { useState } from 'react'
import { View, StyleSheet, Text, TextInput as Input, Pressable, Image, TouchableOpacity } from 'react-native'
import { ActivityIndicator, Button, HelperText, IconButton, Paragraph, TextInput } from 'react-native-paper'
import { modalCustomStyles, itemStyles as styles } from './styles'
import Modal from 'react-modal'
import { Alert, Form } from '../../ReusableComponents'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { Items } from '../../reducers/Items'
import { RouteName } from '../../navigation/types'
import { NavigationStackProp } from 'react-navigation-stack'
import * as _ from 'lodash'
import { User } from '../../reducers/User'
import { buyItem } from '../../reducers/Items/buyItem'
import { getFunds } from '../../reducers/Wallet/getFunds'
import { Wallet } from '../../reducers/Wallet'
import { addItemToPool } from '../../reducers/Items/addItemToPool'
import { convertDateToUnixTime } from '../../utils/dateFormatter'
import { validateReservedItem, ValidateReservedItemState } from '../../reducers/Items/validateReservedItem'
import { getReservedItemByUser } from '../../reducers/Items/getReservedItemByUser'
import Socket from '../../websocket'
import OtpProvider, { OtpProperties } from '../../ReusableComponents/OtpProvider/OtpProvider'
import { getPortfolioOfUser } from '../../reducers/User/getPortfolioOfUser'
import { validateFields } from '../../utils/systemValidator'
import { SystemProperties } from '../../reducers/SystemProperty'

export interface ComponentProps {
  navigation: NavigationStackProp<any>
  key: string
  showModal: boolean
  isDetailsPage: boolean
  closeModal: () => any
  getItemInfo: (id: string, item: any) => void,
  getFunds: (id: string) => any
  buyItem: (data: any, id: string) => any
  intl: any
  onToggleReservedModal: (isOpen: boolean) => any
}

export interface StateProps {
  currentUser?: User
  itemInfo: Items
  funds: Wallet
  isLoading: boolean
  imageUrl: string
  errorMessage: string
  isLoadingFunds: boolean
  validateReservedItem: ValidateReservedItemState
  onGetReservedItemByUser: (accountId: string) => any
  systemFields: SystemProperties[]
}

export interface DispatchProps {
  onAddItemToPool: (data: any) => any
  onValidateReservedItem: () => any
  getPortolioItem: (userId: string) => any
}

export interface BuyModalState {
  isNextPage: boolean
  isSubmit: boolean
  isSuccess: boolean,
  isError: boolean,
  isFundError: boolean,
  noOfShares: string,
  totalValue: number,
  valuePerShare: number,
  networkFee: number
  errorNumberOfShare: string
  isOpenReserveModal: boolean
  isDisabledBtn: boolean
}

export type BuyModalProps = ComponentProps & StateProps & DispatchProps

Modal.setAppElement('#react-root');

class BuyModalComponent extends React.Component<BuyModalProps, BuyModalState> {
  otpProvider?: OtpProperties
  constructor(props: BuyModalProps) {
    super(props)
    this.state = {
      isSubmit: false,
      isNextPage: false,
      isSuccess: false,
      isError: false,
      isFundError: false,
      noOfShares: '',
      valuePerShare: 0,
      totalValue: 0,
      networkFee: 10,
      errorNumberOfShare: '',
      isOpenReserveModal: false,
      isDisabledBtn: false
    }
  }

  componentDidMount() {
    this.setState({ valuePerShare: parseInt(this.props.itemInfo?.listedValue, 10) / Number(process.env.VALUE_PER_SHARE) })
  }

  onToggleReservedModal = (isOpen: boolean) => {
    this.setState({ isOpenReserveModal: isOpen, noOfShares: '0' })
  }

  onSubmit = () => {
    this.setState({ isDisabledBtn: true })

    const _data = {
      ...this.props?.itemInfo,
      listingDate: Date.parse(String(this.props.itemInfo.listingDate)),
      saleDate: Date.parse(String(this.props.itemInfo.saleDate)),
      id: `${this.props?.itemInfo?.id}_${this.props.currentUser?.id}`,
      type: 'Reserved',
      reservedUser: this.props.currentUser?.id,
      reservedShares: Number(this.state.noOfShares),
      expirationDate: moment().add(90, 'd').format(),
      reservedItemId: this.props?.itemInfo?.id,
      email: this.props.currentUser?.email
    }

    this.props.onAddItemToPool(_data)
      .then(() => {
        // this.sendNotification()
        // Reserve
        this.setState({
          isOpenReserveModal: true,
          isNextPage: false,
          isDisabledBtn: false
          // isSubmit: true,
          // isSuccess: true
        }, () => {
          this.props.onValidateReservedItem()
          // .then((res: any) => {
          //   // Buy
          //   if (res?.data?.message === 'Buy') {
          //     this.sendNotification()
          //     this.props.getPortolioItem(this.props.currentUser?.id ?? '')
          //   }
          // })
          this.props.closeModal()
          this.props.onGetReservedItemByUser(String(this.props.currentUser?.cognitoId))
        })
      })
      .catch(() => this.setState({ isError: true }))

    // this.props.buyItem(_data, this.props.itemInfo?.id)
    //   .then(() => {
    //     this.setState({
    //       isNextPage: false,
    //       isSubmit: true,
    //       isSuccess: true
    //     })
    //   }).catch(() => {
    //     this.setState({ isError: true })
    //   })
  }

  sendNotification = () => {
    if (Socket.ws?.readyState === WebSocket.OPEN) {
      Socket.sendMessage(JSON.stringify({
        action: 'sendToOne',
        userId: this.props.currentUser?.cognitoId,
        isUpdate: true,
        isAdd: true
      }), this.props.currentUser?.cognitoId)
    }
  }

  goToListing = () => {
    this.setState({
      isNextPage: false,
      isSubmit: false,
      isSuccess: false
    })

    this.props.closeModal()
    if (this.props.isDetailsPage) {
      this.props.navigation.push(RouteName.ListPage)
    }
  }

  getDetailsPage = (id: string, itemInfo: any) => {
    this.setState({
      isNextPage: false,
      isSubmit: false,
      isSuccess: false
    })

    if (this.props.isDetailsPage) {
      this.props.closeModal()
    } else {
      this.props.getItemInfo(id, itemInfo)
    }
  }

  onChangeShares = (_value: string) => {
    const _itemInfo = this.props.itemInfo
    const value = _value.replace(/,/g, '')
    const availableShareToReserve = Math.floor(Number(_itemInfo?.availableReservedShare) * 0.05)
    if (Number(value) > availableShareToReserve) {
      this.setState({ noOfShares: value, errorNumberOfShare: 'Invalid no. of share', isDisabledBtn: true })
    } else if (Number(value) > Number(_itemInfo?.availableReservedShare)) {
      this.setState({ noOfShares: value, errorNumberOfShare: 'Invalid no. of share', isDisabledBtn: true })
    } else if (value === '' || Number(value) > 0) {
      if (/^\d+$/.test(value)) {
        this.setState({ noOfShares: value, errorNumberOfShare: '', isDisabledBtn: false })
      } else if (value === '') {
        this.setState({ noOfShares: value, errorNumberOfShare: 'Invalid no. of share', isDisabledBtn: true })
      } else {
        this.setState({ errorNumberOfShare: /^\d+$/.test(this.state.noOfShares) ? '' : 'Invalid no. of share', isDisabledBtn: true })
      }
      _.debounce(() => {
        const totalValue = this.props.itemInfo?.valuePerShare * parseInt(this.state.noOfShares === '' ? '0' : value, 10)
        this.setState({ totalValue })
      }, 300)()
    } else if (this.state.noOfShares === '') {
      this.setState({ errorNumberOfShare: 'Invalid no. of share', isDisabledBtn: true })
    }
  }

  getNextPage = () => {
    if (this.state.noOfShares === '' || this.state.noOfShares === '0') {
      this.setState({ errorNumberOfShare: 'Invalid no. of share' })
      return
    }
    const cognitoId = this.props.currentUser?.id
    this.props.getFunds(cognitoId!)
      .then(() => {
        this.setState({ isNextPage: true })
      }).catch(() => {
        this.setState({ isFundError: true })
      })
  }

  onClose = () => {
    this.setState({
      valuePerShare: 0,
      totalValue: 0,
      isFundError: false,
      isNextPage: false,
      isError: false,
      noOfShares: '',
      errorNumberOfShare: ''
    })
    this.props.closeModal()
  }

  onGetExpiryDate = () => {
    const date = new Date(new Date().setDate(new Date().getDate() + 90));
    const expiryDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    return expiryDate
  }

  render() {
    const { isNextPage, isSubmit, isSuccess, isFundError, valuePerShare, totalValue, networkFee, noOfShares } = this.state
    const { itemInfo, imageUrl, showModal, currentUser, isLoading, funds, isLoadingFunds, closeModal } = this.props
    const isAllowed = validateFields('Reserve', this.props.systemFields)
    return (
      <React.Fragment>
        <Modal
          isOpen={showModal}
          onRequestClose={this.onClose}
          style={modalCustomStyles}
        >
          <OtpProvider flow='Reserve' onProceedFlow={this.onSubmit} exposedProperties={(otpProperties: OtpProperties) => this.otpProvider = otpProperties}>
            <View style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}>
              <IconButton
                icon='close'
                size={20}
                onPress={this.onClose}
              />
            </View>
            {!isSubmit ? (
              <React.Fragment>
                {this.state.isError && <View style={{ alignItems: 'center' }}><View style={styles.errorMessage} ><Text style={{ color: 'white', fontSize: 12, fontFamily: "'Montserrat', sans-serif" }}>{this.props.errorMessage || <FormattedMessage id='Buy.msg.somethingWentWrong' />}</Text></View></View>}
                {isFundError && <View style={{ alignItems: 'center' }}><View style={styles.errorMessage} ><Text style={{ color: 'white', fontSize: 12, fontFamily: "'Montserrat', sans-serif" }}>Unable to retrieve wallet</Text></View></View>}
                {/* Title */}
                <View style={{ marginLeft: 20, marginTop: 10, marginBottom: 5, width: '50%' }} >
                  <Text style={styles.artTitle}>{`${itemInfo?.title}`}</Text>
                  <Text style={styles.artCaption}>{`${itemInfo?.artist}`}</Text>
                  <Text style={styles.artCaption}>{`${itemInfo?.year}`}</Text>
                </View>
                {/* Item Details*/}
                <View style={{ width: 500, height: 400 }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    {/* First Column Item Details*/}
                    <View style={{ flexWrap: 'nowrap', flex: 1, marginTop: 10 }}>
                      {!isNextPage ? (
                        <View style={{ alignItems: 'center' }}>
                          <TextInput
                            style={{ fontSize: 12, height: 40, width: '70%', textTransform: 'uppercase' }}
                            label="No of. Shares"
                            value={this.props.intl.formatNumber(isNaN(Number(this.state.noOfShares)) ? 0 : Number(this.state.noOfShares), { style: 'decimal' })}
                            onChangeText={(value) => this.onChangeShares(value)}
                            mode={'outlined'}
                            keyboardType='numeric'
                            autoComplete={false}
                          />
                          {this.state.errorNumberOfShare ? <View><Text style={{ fontSize: 14, color: 'red' }}>{this.state.errorNumberOfShare}</Text></View> : null}
                          <View style={{ flexDirection: 'row' }}><Paragraph style={{ fontSize: 12, color: 'black', marginBottom: 30 }}><Text style={{ fontWeight: 'bold' }}><FormattedNumber value={Math.floor(itemInfo?.availableReservedShare * 0.05)} /></Text>{' share/s remaining'}</Paragraph></View>
                          <Text style={[styles.artLabel, styles.artText]}><FormattedMessage id='Buy.msg.valuePerShare' /></Text>
                          <Text style={[styles.artCaption, { marginVertical: 10 }]}><FormattedNumber value={Number(process.env.VALUE_PER_SHARE)} format={'USD'} /></Text>
                          <Text style={[styles.artLabel, styles.artText]}><FormattedMessage id='Buy.msg.totalValue' /></Text>
                          <Text style={[styles.artCaption, { marginVertical: 10 }]}><FormattedNumber style='currency' format={'USD'} value={totalValue} /></Text>
                          <Form.Submit onPress={this.getNextPage} title='NEXT' disabled={this.state.isDisabledBtn} style={{ backgroundColor: '#F7931E', padding: 10, borderRadius: 0, width: '70%', marginTop: 20 }} labelStyle={{ color: 'white', fontSize: 25 }} />
                        </View>
                      ) : (
                        <View style={{ marginLeft: 25, marginTop: 25 }}>
                          <Text style={[styles.artSendLabel, { width: '60%' }]}><FormattedMessage id='Buy.msg.availableBalance' /></Text>
                          {isLoadingFunds ?
                            <ActivityIndicator animating={true} color='#AFCD37' size='large' />
                            : <Text style={[styles.artSendLabel, { marginTop: 5, marginBottom: 20 }]}><FormattedNumber style='currency' format={'USD'} value={funds.balance} /></Text>
                          }
                          {/* <Text style={[styles.artSendLabel, { marginTop: 15 }]}><FormattedMessage id='Buy.msg.amount' />{` $${totalValue}`}</Text> */}
                          {/* <Text style={[styles.artSendLabel, { marginVertical: 15 }]}><FormattedMessage id='Buy.msg.networkFee' />{` $${networkFee}`}</Text> */}
                          <Text style={[styles.artSendLabel, { marginTop: 20 }]}><FormattedMessage id='Buy.msg.totalAmount' /></Text>
                          <Text style={[styles.artSendValue, { marginVertical: 15 }]}><FormattedNumber style='currency' format={'USD'} value={totalValue} /></Text>
                          <Form.Submit loading={this.props.isLoading} onPress={this.otpProvider?.onRequestOtp} disabled={this.state.isDisabledBtn || Number(this.state.noOfShares) > Number(itemInfo?.availableReservedShare) || !isAllowed} loading={isLoading || this.props?.validateReservedItem?.loading} title='RESERVE' style={{ backgroundColor: '#F7931E', padding: 10, borderRadius: 0, marginBottom: 10, width: '80%', marginTop: 10 }} labelStyle={{ color: 'white', fontSize: 17 }} />
                        </View>
                      )}
                    </View>
                    {/* Second Column Item Details*/}
                    <View style={{ flexWrap: 'nowrap', flex: 1 }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text style={[styles.artLabel, { marginVertical: 10 }]}><FormattedMessage id='Buy.msg.value' /></Text>
                        <Text style={styles.artCaption}><FormattedNumber format='USD' value={Number(itemInfo?.listedValue)} /></Text>
                        <TouchableOpacity style={{ marginVertical: 10, marginHorizontal: 5 }} onPress={() => this.getDetailsPage(itemInfo.id, itemInfo)}>
                          <Text style={{ fontSize: 15, fontStyle: 'italic', color: '#3FDCFF' }}><FormattedMessage id='Buy.msg.details' /></Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ height: 270, width: 230 }}>
                        <Image
                          source={{ uri: imageUrl }}
                          style={{ height: '100%', width: '100%' }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </React.Fragment>
            ) : isSuccess ? (
              <View style={{ width: 500, height: 500, alignItems: 'center' }}>
                <View style={{ marginVertical: 10 }}>
                  <Text style={[styles.artAcknowledgement]}><FormattedMessage id='Buy.msg.successMsg' /></Text>
                </View>
                {/* Acknowledgement Image */}
                <View style={{ height: 220, width: 170 }}>
                  <Image
                    source={{ uri: imageUrl }}
                    style={{ height: '100%', width: '100%' }}
                  />
                </View>
                {/* Acknowledgement Message */}
                <View style={{ marginVertical: 20, alignItems: 'center' }}>
                  <Text style={styles.artSuccessMsg}><FormattedMessage id='Buy.msg.congratsMsg' />{` ${currentUser?.name}!`}</Text>
                  <Text style={styles.artSuccessMsg}><FormattedMessage id='Buy.msg.successMsg1' />{` ${noOfShares} `}<FormattedMessage id='Buy.msg.successMsg2' /></Text>
                  <Text style={[styles.artSendValue, { marginVertical: 15 }]}>{`$${totalValue}`}</Text>
                  <TouchableOpacity onPress={this.goToListing}>
                    <Text style={[styles.artLabel, styles.artBtn]}><FormattedMessage id='Buy.msg.back' /></Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : null}
          </OtpProvider>
        </Modal >
        <Modal
          isOpen={this.state.isOpenReserveModal}
          onRequestClose={() => this.onToggleReservedModal(false)}
          style={modalCustomStyles}
        >
          <View style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}>
            <IconButton
              icon='close'
              size={20}
              onPress={() => this.onToggleReservedModal(false)}
            />
          </View>
          <View style={{ width: 500, height: 250, alignItems: 'center' }}>
            <View style={{ flexDirection: 'column', marginTop: 40, marginHorizontal: 20, alignItems: 'center' }}>
              <Paragraph style={styles.confimationText}>You have successfully reserved <Text style={{ fontWeight: 'bold' }}><FormattedNumber value={Number(noOfShares)} /> share/s</Text> </Paragraph>
              <Paragraph style={styles.confimationText}>amounting to <Text style={{ fontWeight: 'bold' }}><FormattedNumber style='currency' format={'USD'} value={totalValue} /> USD</Text>. </Paragraph>
              <Paragraph style={styles.confimationText}>It will be deducted to your account once art share is available. </Paragraph>
              <Paragraph style={styles.confimationText}>If you do not have enough balance after <Text style={{ fontWeight: 'bold' }}>{this.onGetExpiryDate()}</Text>, </Paragraph>
              <Paragraph style={styles.confimationText}>your reservation will be invalid.</Paragraph>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 10 }}>
              <Button onPress={() => this.onToggleReservedModal(false)} mode='contained' color='#F7931E' labelStyle={{ fontSize: 15, color: '#FFFFFF', fontFamily: "'Montserrat', sans-serif", padding: 5 }} style={{ margin: 5 }}>OK</Button>
            </View>
          </View>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: ReduxAppState) => {
  const _getItemInfo = state.api?.items?.getItemInfo
  const _authUserInfo = state.api?.user?.authUser
  const _buyItem = state.api?.items?.buyItem
  const _addPool = state.api?.items?.addItemToPool
  const _getFunds = state.api?.wallet?.getFunds
  return ({
    itemInfo: _getItemInfo.response,
    imageUrl: _getItemInfo.imageUrl,
    currentUser: _authUserInfo.response,
    isLoading: _buyItem.loading || _addPool?.loading,
    errorMessage: _buyItem.error,
    funds: _getFunds.response,
    isLoadingFunds: _getFunds.loading,
    validateReservedItem: state.api?.items.validateReservedItem,
    systemFields: state.api?.systemParam?.getAllSysParams?.response ?? []
  })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  buyItem: (data: any, id: string) => buyItem(dispatch, data, id),
  getFunds: (id: string) => getFunds(dispatch, id),
  onAddItemToPool: (data: any) => addItemToPool(dispatch, data),
  onGetReservedItemByUser: (accountId: string) => getReservedItemByUser(dispatch, accountId),
  onValidateReservedItem: (() => validateReservedItem(dispatch)),
  getPortolioItem: (userId: string) => getPortfolioOfUser(dispatch, userId),
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(compose(BuyModalComponent as any)))