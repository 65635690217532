import React from 'react'

import { View, Text, TextInput } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { RouteName } from '../../navigation/types'

import { Button, IconButton, Title } from 'react-native-paper'

import { forgotPwdStyles as styles } from './styles'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import * as _ from 'lodash'

import { FormattedMessage } from 'react-intl'

import { AxiosPromise } from 'axios'
import { CLEAR_USER } from '../../reducers/User/user'

import { BackgroundTemplate, Screen } from '../../ReusableComponents'
import {
    changeUserPassword,
    CLEAR_USER_CHANGE_PASSWORD
} from '../../reducers/ForgotPassword/changePassword'
import { CLEAR_FORGOT_PWD_OTP_REQ } from '../../reducers/ForgotPassword/otpRequest'
import { CLEAR_VERIFY_OTP } from '../../reducers/ForgotPassword/verifyOTP'
import AlertMessage from './component/AlertMessage'
import { responseCode } from '../../utils/response'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean
    route: any
}

export interface StateProps {
    verifyEmailResp: any
    changePwdResp: any
    isVerifiedOTP: boolean
    isVerifiedEmail: boolean
    otpReqStatus: string
}

export interface DispatchProps {
    changeUserPassword: (data: any) => AxiosPromise
    onClearChangePwd: () => any
    onClearForgotPwdOtpReq: () => any
    onClearVerifyOTP: () => any
    onClearUser: () => any
}

export interface ForgotPwdPageState {
    value: any
    isSubmit: boolean
    validationValue: any
    isLoading: boolean
    isError: boolean
    isAlertMessage: boolean
    errMsg: string
    isShowNewPassword: boolean
    isShowVerifyPassword: boolean
}
export const notRequiredFields = []

export type ForgotPwdPageProps = ComponentProps & StateProps & DispatchProps

class ForgotPwdPage extends React.Component<ForgotPwdPageProps, ForgotPwdPageState> {
    constructor(props: ForgotPwdPageProps) {
        super(props)
        this.state = {
            isSubmit: false,
            isLoading: false,
            isError: false,
            isShowNewPassword: false,
            isShowVerifyPassword: false,
            isAlertMessage: false,
            value: {
                password: '',
                verifyPassword: ''
            },
            validationValue: {},
            errMsg: ''
        }
    }

    componentDidMount() {
        if (
            !this.props.isVerifiedOTP ||
            !this.props.isVerifiedEmail ||
            this.props.otpReqStatus !== responseCode.OTP_SUCCESSFUL
        ) {
            this.props.navigation.navigate(RouteName.VerifyEmailPage)
        }
        this.props.onClearChangePwd()
    }

    onChangeValue = (key: string, value: string) => {
        const _value = { ...this.state.value }
        _value[key] = value
        this.setState({ value: _value })
    }

    onNavigateToLogin = () => {
        this.setState({ isAlertMessage: false, isSubmit: false, isLoading: false })
        this.props.navigation.navigate(RouteName.LoginPage)
    }

    passwordResultColor = (key: string) => {
        const password = this.state.value?.password
        if (!password) {
            return styles.plainColor
        }
        let isSuccess = false
        if (key === 'lengthValidation') {
            isSuccess = password.length >= 8
        } else if (key === 'lowercaseValidation') {
            isSuccess = Boolean(password.match(/[a-z]/g))
        } else if (key === 'uppercaseValidation') {
            isSuccess = Boolean(password.match(/[A-Z]/g))
        } else if (key === 'specialcharValidation') {
            isSuccess = Boolean(password.match(/[!@#$%^&*]/g))
        } else {
            isSuccess = Boolean(password.match(/[0-9]/g))
        }

        if (isSuccess) {
            return styles.successColor
        }

        return styles.errorColor
    }

    fieldValidator = (inputValue: string, fieldName: string) => {
        if (!inputValue) return `${fieldName} can't be empty.`
        return ''
    }

    onValidateField = (key: string, value?: string | undefined) => {
        const _validationValue = { ...this.state.validationValue }

        if (key === 'verifyPassword') {
            _validationValue[key] = {
                error:
                    Boolean(this.state.value?.verifyPassword) &&
                    this.state.value.verifyPassword !== this.state.value.password
            }
        } else {
            _validationValue[key] = {
                error: Boolean(value)
            }
        }

        this.setState({ validationValue: _validationValue })
    }

    onSubmit = () => {
        this.setState({ isSubmit: true })
        const _value = { ...this.state.value }
        let isSuccessValidation: boolean = true
        _.forIn(_value, (value: any, key: any) => {
            if (!Boolean(value)) {
                isSuccessValidation = Boolean(
                    notRequiredFields.find((item: string) => item === key)
                )
            }
        })

        const password = _value?.password
        const verifyPassword = _value?.verifyPassword

        if (password.length < 8 || verifyPassword.length < 8) {
            isSuccessValidation = false
        }

        if (
            !Boolean(password.match(/[a-z]/g)) ||
            !Boolean(password.match(/[A-Z]/g)) ||
            !Boolean(password.match(/[!@#$%^&*]/g)) ||
            !Boolean(password.match(/[0-9]/g))
        ) {
            isSuccessValidation = false
        }

        if (Boolean(verifyPassword) && verifyPassword !== password) {
            isSuccessValidation = false
        }

        const { email, name } = this.props?.verifyEmailResp?.selectedUser
        if (isSuccessValidation) {
            const data = {
                email,
                name,
                password
            }

            this.props.changeUserPassword(data)
            this.props.onClearChangePwd()
            this.props.onClearVerifyOTP()
            this.props.onClearForgotPwdOtpReq()
            this.props.onClearUser()
            this.setState({ isAlertMessage: true })
            // this.props.navigation.navigate(RouteName.LoginPage)
        }
    }

    render() {
        const {
            validationValue,
            isSubmit,
            value,
            isShowNewPassword,
            isShowVerifyPassword,
            isAlertMessage
        } = this.state
        const eyeIconNewPw = isShowNewPassword ? 'eye' : 'eye-off'
        const eyeIconVerifyPw = isShowVerifyPassword ? 'eye' : 'eye-off'
        return (
            <BackgroundTemplate>
                {!isAlertMessage ? (
                    <View style={[styles.formStyle]}>
                        <View style={{ marginLeft: 10, marginBottom: 20 }}>
                            <Title style={[styles.textStyle, styles.title, { fontSize: 24 }]}>
                                Change Password
                            </Title>
                        </View>

                        {this.state.isError && (
                            <View
                                style={{
                                    backgroundColor: 'red',
                                    padding: 10,
                                    maxWidth: 250,
                                    alignSelf: 'center',
                                    width: '100%',
                                    marginBottom: 10
                                }}>
                                <Text style={{ color: '#ffff', textAlign: 'center' }}>
                                    {this.state.errMsg}
                                </Text>
                            </View>
                        )}
                        <View style={{ alignSelf: 'flex-start' }}>
                            <Screen minWidth={400} style={styles.formGroup}>
                                <View style={{ flexDirection: 'row', position: 'relative' }}>
                                    <TextInput
                                        style={
                                            isSubmit && !value?.password
                                                ? [styles.input, styles.errorBorder]
                                                : styles.input
                                        }
                                        secureTextEntry={!isShowNewPassword}
                                        onChangeText={(_value: string) =>
                                            this.onChangeValue('password', _value)
                                        }
                                        placeholder="New Password"
                                        placeholderTextColor="#C0C1C2"
                                        onSubmitEditing={this.onSubmit}
                                    />
                                    <IconButton
                                        icon={eyeIconNewPw}
                                        onPress={() =>
                                            this.setState({ isShowNewPassword: !isShowNewPassword })
                                        }
                                        style={{ position: 'absolute', right: 0 }}
                                        size={24}
                                    />
                                </View>
                            </Screen>
                            <Screen maxWidth={399} style={styles.formGroup}>
                                <View style={{ flexDirection: 'row', position: 'relative' }}>
                                    <TextInput
                                        style={[
                                            styles.input,
                                            isSubmit && !value?.oldPassword && styles.errorBorder,
                                            { alignSelf: 'center', width: 250 }
                                        ]}
                                        secureTextEntry={!isShowNewPassword}
                                        onChangeText={(_value: string) =>
                                            this.onChangeValue('password', _value)
                                        }
                                        placeholder="New Password"
                                        placeholderTextColor="#C0C1C2"
                                        onSubmitEditing={this.onSubmit}
                                    />
                                    <IconButton
                                        icon={eyeIconNewPw}
                                        onPress={() =>
                                            this.setState({ isShowNewPassword: !isShowNewPassword })
                                        }
                                        style={{ position: 'absolute', right: 0 }}
                                        size={24}
                                    />
                                </View>
                            </Screen>
                            <Screen minWidth={400} style={styles.formGroup}>
                                <View style={{ flexDirection: 'row', position: 'relative' }}>
                                    <TextInput
                                        secureTextEntry={!isShowVerifyPassword}
                                        style={
                                            isSubmit && !value?.verifyPassword
                                                ? [styles.input, styles.errorBorder]
                                                : styles.input
                                        }
                                        onBlur={() => this.onValidateField('verifyPassword')}
                                        onChangeText={(_value: string) =>
                                            this.onChangeValue('verifyPassword', _value)
                                        }
                                        placeholder="Verify Password"
                                        placeholderTextColor="#C0C1C2"
                                        onSubmitEditing={this.onSubmit}
                                    />
                                    <IconButton
                                        icon={eyeIconVerifyPw}
                                        onPress={() =>
                                            this.setState({
                                                isShowVerifyPassword: !isShowVerifyPassword
                                            })
                                        }
                                        style={{ position: 'absolute', right: 0 }}
                                        size={24}
                                    />
                                </View>

                                {validationValue?.verifyPassword?.error && (
                                    <View style={styles.errorContainer}>
                                        <Text style={styles.errorColor}>
                                            <FormattedMessage id="ForgotPwd.msg.passwordNotMatch" />
                                        </Text>
                                    </View>
                                )}
                            </Screen>
                            <Screen maxWidth={399} style={styles.formGroup}>
                                <View style={{ flexDirection: 'row', position: 'relative' }}>
                                    <TextInput
                                        secureTextEntry={!isShowVerifyPassword}
                                        style={[
                                            styles.input,
                                            isSubmit && !value?.oldPassword && styles.errorBorder,
                                            { alignSelf: 'center', width: 250 }
                                        ]}
                                        onBlur={() => this.onValidateField('verifyPassword')}
                                        onChangeText={(_value: string) =>
                                            this.onChangeValue('verifyPassword', _value)
                                        }
                                        placeholder="Verify Password"
                                        placeholderTextColor="#C0C1C2"
                                        onSubmitEditing={this.onSubmit}
                                    />
                                    <IconButton
                                        icon={eyeIconVerifyPw}
                                        onPress={() =>
                                            this.setState({
                                                isShowVerifyPassword: !isShowVerifyPassword
                                            })
                                        }
                                        style={{ position: 'absolute', right: 0 }}
                                        size={24}
                                    />
                                </View>

                                {validationValue?.verifyPassword?.error && (
                                    <View style={styles.errorContainer}>
                                        <Text style={styles.errorColor}>
                                            <FormattedMessage id="ForgotPwd.msg.passwordNotMatch" />
                                        </Text>
                                    </View>
                                )}
                            </Screen>
                            <View style={styles.requirementsContainer}>
                                <Text
                                    style={[
                                        styles.text,
                                        this.passwordResultColor('lengthValidation')
                                    ]}>
                                    <FormattedMessage id="ForgotPwd.msg.minimunPasswordLengthValidation" />
                                </Text>
                                <Text
                                    style={[
                                        styles.text,
                                        this.passwordResultColor('uppercaseValidation')
                                    ]}>
                                    <FormattedMessage id="ForgotPwd.msg.uppercaseValidation" />
                                </Text>
                                <Text
                                    style={[
                                        styles.text,
                                        this.passwordResultColor('lowercaseValidation')
                                    ]}>
                                    <FormattedMessage id="ForgotPwd.msg.lowercaseValidation" />
                                </Text>
                                <Text
                                    style={[
                                        styles.text,
                                        this.passwordResultColor('specialcharValidation')
                                    ]}>
                                    <FormattedMessage id="ForgotPwd.msg.specialcharValidation" />
                                </Text>
                                <Text
                                    style={[
                                        styles.text,
                                        this.passwordResultColor('numberValidation')
                                    ]}>
                                    <FormattedMessage id="ForgotPwd.msg.numberValidation" />
                                </Text>
                            </View>
                            <View style={[styles.formGroup2]}>
                                <Button
                                    loading={this.state.isLoading}
                                    onPress={this.onSubmit}
                                    style={[styles.registerBtn, { width: 250 }]}
                                    labelStyle={styles.textWhite}>
                                    Reset Password
                                </Button>
                            </View>
                        </View>
                    </View>
                ) : (
                    <AlertMessage
                        navigateToLogin={() => this.onNavigateToLogin()}
                        isSuccess={true}
                        message={`Successful Reset Password`}
                    />
                )}
            </BackgroundTemplate>
        )
    }
}

const mapStateToProps = (state: any) => {
    const _otpReq = state.api?.forgotPwd?.forgotPwdOtpReq
    return {
        otpReqStatus: _otpReq?.statusText,
        verifyEmailResp: state.api?.forgotPwd?.verifyEmail?.response,
        changePwdResp: state.api?.forgotPwd?.changePassword?.response,
        isVerifiedEmail:
            state.api?.forgotPwd?.verifyEmail?.response?.userResponse === responseCode.USER_FOUND,
        isVerifiedOTP: state.api?.forgotPwd?.verifyOTP?.response === responseCode.SUCCESS
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeUserPassword: (data: any) => changeUserPassword(dispatch, data),
    onClearChangePwd: () => dispatch({ type: CLEAR_USER_CHANGE_PASSWORD }),
    onClearForgotPwdOtpReq: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ }),
    onClearVerifyOTP: () => dispatch({ type: CLEAR_VERIFY_OTP }),
    onClearUser: () => dispatch({ type: CLEAR_USER })
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(ForgotPwdPage))
