import React, { useState, useRef, useEffect } from 'react'
import { View, Image, TouchableOpacity, Text, Dimensions, Platform } from 'react-native'
import { Avatar, Title } from 'react-native-paper'
import { ScrollView } from 'react-native-gesture-handler'
import { ResponsiveView, Screen } from '..'
import { useMediaQuery } from 'react-responsive'
import { FormattedMessage } from 'react-intl'
import styles from './styles'
import Terms from '../../components/Terms/Terms'
import Disclaimer from '../../components/Disclaimer/Disclaimer'

const BackgroundTemplate = (props: any) => {
    const [isModal, setIsModal] = useState(false)
    const [isOpenDisclaimerModal, setIsOpenDisclaimerModal] = useState(false)
    const [screenSize, setScreenSize] = useState(Dimensions.get('window').height)

    const viewElement = useRef(undefined)

    useEffect(() => {
        console.log(window.location?.pathname)
        if (Platform.OS === 'web') {
            window.addEventListener('resize', () => {
                setScreenSize(viewElement?.current?.clientHeight)
            })
            if (screenSize !== viewElement?.current?.clientHeight) {
                setScreenSize(viewElement?.current?.clientHeight)
            }
            return () =>
                window.removeEventListener('resize', setScreenSize(Dimensions.get('window').height))
        }
    }, [])

    const onClickModal = () => {
        setIsModal(!isModal)
    }

    const isDesktop = useMediaQuery({ query: '(min-width: 1100px)' })
    const isMediumScreen = useMediaQuery({ query: '(max-width: 1259px)' })
    const isMobile = Screen.isMobile()
    return (
        <View style={{ flex: 1, overflow: 'hidden' }}>
            <View style={{ marginBottom: isMobile ? 40 : 0, flex: 8 }}>
                <View style={{ flexDirection: 'row' }}>
                    {isDesktop && (
                        <ResponsiveView height={'100%'} width={'50%'}>
                            <Image
                                style={{
                                    backgroundColor: 'white',
                                    width: '100%',
                                    height: screenSize
                                }}
                                source={require('../../assets/artshare-big-logo-v2.png')}
                                resizeMode="stretch"
                            />
                        </ResponsiveView>
                    )}
                    <View ref={viewElement}>
                        <ResponsiveView
                            viewStyle={{
                                marginTop: isDesktop ? 50 : 30,
                                alignItems: isDesktop ? undefined : 'center'
                            }}
                            width={isDesktop ? '50%' : '100%'}>
                            <View style={{ flexDirection: 'row' }}>
                                {isDesktop && (
                                    <View
                                        style={{
                                            height: 2.5,
                                            backgroundColor: 'black',
                                            width: '70%'
                                        }}
                                    />
                                )}
                                <Image
                                    style={{
                                        width: 200,
                                        height: 70,
                                        marginHorizontal: 10,
                                        marginTop: '-4%'
                                    }}
                                    source={require('../../assets/artshare-new-logo-v2.png')}
                                    resizeMode="contain"
                                />
                            </View>
                            <View style={{ padding: isDesktop ? '2%' : 0 }}>{props.children}</View>
                        </ResponsiveView>
                    </View>
                </View>
            </View>

            {/* <View style={[isDesktop ? styles.deskTopFooter : styles.mobileFooter]}>
                <TouchableOpacity onPress={() => onClickModal()}>
                    <Text style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 12 }}>
                        <FormattedMessage id="Footer.msg.terms" />
                    </Text>
                </TouchableOpacity>
            </View> */}

            <View
                style={[
                    isDesktop ? styles.deskTopFooter : styles.mobileFooter,
                    { flexDirection: 'row' }
                ]}>
                <Text
                    style={{
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: 12
                    }}>
                    <FormattedMessage id="Footer.msg.copyright" />
                </Text>
                <TouchableOpacity onPress={() => onClickModal()} style={{ marginRight: 10 }}>
                    <Text
                        style={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 12,
                            textDecorationLine: 'underline'
                        }}>
                        <FormattedMessage id="Footer.msg.terms" />
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setIsOpenDisclaimerModal(true)}>
                    <Text
                        style={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 12,
                            textDecorationLine: 'underline'
                        }}>
                        Disclaimer
                    </Text>
                </TouchableOpacity>
            </View>
            <Terms showModal={isModal} closeModal={onClickModal} />
            <Disclaimer
                showModal={isOpenDisclaimerModal}
                closeModal={() => setIsOpenDisclaimerModal(false)}
            />
        </View>
    )
}

export default BackgroundTemplate
