export const customInitialState = {}

export interface CustomState {
    [s: string]: any
}

export interface CustomActions {
    type: string
    [item: string]: any
}


const reducer = (newState: CustomState = customInitialState, action: CustomActions) => {
    if (action.type.toLowerCase().indexOf('custom.') !== -1 && action.type.split('.')?.length === 2) {
        const _updatedState = action.type.split('.')[1]
        return Object.assign({}, newState, { [_updatedState]: action?.value })
    }
    return newState
}

export default reducer