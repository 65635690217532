import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

export const activeList = StyleSheet.create({
    title: {
        textTransform: 'uppercase',
        color: '#808080',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        marginHorizontal: 20
    },
    activeTitle: {
        textTransform: 'uppercase',
        color: '#F7931E',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        marginHorizontal: 20
    },
    titleContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    artTitle: {
        color: '#000000',
        fontSize: 45,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 'bold',
        marginVertical: 10,
        marginHorizontal: 20
    },
    artCaption: {
        fontStyle: 'italic',
        color: '#000000',
        fontSize: 45,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 'bold',
        marginVertical: 10,
        marginHorizontal: 20
    },
    demoContainer: {
        marginHorizontal: 20
    },
    iconStyle: {
        height: 40,
        width: 40,
        tintColor: '#fff',
        marginBottom: 15
    },
    normalText: {
        fontFamily: "'Montserrat', sans-serif",
    },
    boldText: {
        fontWeight: 'bold'
    },
    italicText: {
        fontStyle: 'italic'
    },
    upperCaseText: {
        textTransform: 'uppercase'
    },
    table: {
        borderColor: '#9D9D9D',
        borderWidth: 1,
        flexDirection: 'row',
        marginBottom: 12,
        flexWrap: 'wrap',
        width: '100%'
    },
    tableHeader: {
        borderColor: 'black',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%'
    },
    imageContainer: {
        // width: '30%',
        flexDirection: 'row',
        padding: 10,
        height: 120,
        justifyContent: 'space-between'
    },
    row: {
        width: '20%',
        flexDirection: 'row',
        padding: 10,
        height: 140,
        justifyContent: 'space-between'
    },
    rowHeader: {
        width: '20%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    rowTextContainer: {
        justifyContent: 'center',
        alignSelf: 'center',
        width: '90%'
    },
    rowBtnContainer: {
        flexDirection: 'row',
        // justifyContent: 'center',
        // alignContent: 'center',
        // marginHorizontal: '50%',
        width: '100%'
    },
    modalText: {
        fontSize: 14,
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'justify'
    }
})

export const modalCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto'
    }
}