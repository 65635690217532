import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Notification } from '.'
import moment from 'moment'

export const BEGIN_GET_ALL_NOTIFICATION = 'API/ITEM/BEGIN_GET_ALL_NOTIFICATION'
export const SUCCESS_GET_ALL_NOTIFICATION = 'API/ITEM/SUCCESS_GET_ALL_NOTIFICATION'
export const ERROR_GET_ALL_NOTIFICATION = 'API/ITEM/ERROR_GET_ALL_NOTIFICATION'

export const notificationInitialState = {
    groupedByDate: [],
    loading: false,
}

export interface NotificationState {
    groupedByDate: Notification[]
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface GetNotifWithLimitActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: NotificationState = notificationInitialState, action: GetNotifWithLimitActions) => {
    switch (action.type) {
        case BEGIN_GET_ALL_NOTIFICATION:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_ALL_NOTIFICATION:
            return Object.assign({}, newState, {
                groupedByDate: action.grouped,
                loading: false,
                statusText: 'success',
                status: action.status
            })
        case ERROR_GET_ALL_NOTIFICATION:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}


export const beginGetAllNotification = () => ({
    type: BEGIN_GET_ALL_NOTIFICATION,
})

export const successGetAllNotification = (response: AxiosResponse, grouped: Notification[]) => ({
    type: SUCCESS_GET_ALL_NOTIFICATION,
    status: response.status,
    grouped
})

export const errorGetAllNotification = (error: AxiosError) => ({
    type: ERROR_GET_ALL_NOTIFICATION,
    status: error.response?.status,
})

export const getNotifications = async (dispatch: Dispatch, userId: string) => {
    const url = `/audit?field=userId&value=${userId}&isNotify=true`
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url
    }
    dispatch(beginGetAllNotification())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            if (response.status === 200) {
                const data = response.data.message
                const grouped = data?.reduce((acc: any, el: any) => {
                    const dateCreated = moment(el.createdAt).format('MMMM DD, YYYY')
                    if (!acc[dateCreated]) acc[dateCreated] = [];
                    acc[dateCreated].push(el);
                    return acc
                }, {})
                dispatch(successGetAllNotification(response, grouped))
            }
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetAllNotification(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })
}

export default reducer