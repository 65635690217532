import React from 'react';
import FooterHOC from '../../hoc/Footer';
import HeaderContentHOC from '../../hoc/HeaderContent';
import SideNavContentHOC from '../../hoc/SidenavContent';
import ArtShare from '../ArtShare/ArtShare';
import TranHistory from '../TranHistory/TranHistory';
import ReservedList from '../ReservedList/ReservedList';
import { FormattedMessage } from 'react-intl';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { Title } from 'react-native-paper';
import { NavigationStackProp } from 'react-navigation-stack';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { RouteName, TabName } from '../../navigation/types';
import { updateMenu } from '../../reducers/Menu/changeMenu';
import { ReduxAppState } from '../../utils/types';
import { User } from '../../reducers/User';
import { dashboardStyles as styles } from './styles';

export interface ComponentProps {
    navigation: NavigationStackProp<any>
    isRefreshing: boolean
}

export interface DispatchProps {
    updateMenu: (menu: string) => any
}

export interface StateProps {
    currentUser?: User
    isLoading: boolean
    isError: boolean
}

export interface DashboardPageState {
    activeTab: string,
}

export type DashboardPageProps = ComponentProps & DispatchProps & StateProps

class DashboardPage extends React.Component<DashboardPageProps, DashboardPageState> {
    constructor(props: DashboardPageProps) {
        super(props)
        this.state = {
            activeTab: TabName.ArtShareTab
        }
    }

    componentDidMount() {
        this.props.updateMenu(RouteName.DashboardPage)
    }

    onChangeActiveTab = (item: string) => {
        this.setState({
            activeTab: item
        })
    }

    render() {
        return (
            <ScrollView style={styles.demoContainer}>
                <View>
                    <View style={{ flexDirection: 'row' }}>
                        <TouchableOpacity onPress={() => this.onChangeActiveTab(TabName.ArtShareTab)}>
                            <Title numberOfLines={1} style={this.state.activeTab === TabName.ArtShareTab ? styles.activeTitle : styles.title}>
                                <FormattedMessage id='Dashboard.msg.artShare' />
                            </Title>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => this.onChangeActiveTab(TabName.ReservedListTab)}>
                            <Title numberOfLines={1}
                                style={this.state.activeTab === TabName.ReservedListTab ? styles.activeTitle : styles.title}>
                                <FormattedMessage id='Dashboard.msg.reserved' />
                            </Title>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => this.onChangeActiveTab(TabName.TranHistoryTab)}>
                            <Title numberOfLines={1} style={this.state.activeTab === TabName.TranHistoryTab ? styles.activeTitle : styles.title}>
                                <FormattedMessage id='Dashboard.msg.tranHistory' />
                            </Title>
                        </TouchableOpacity>
                    </View>
                    {this.state.activeTab === TabName.ArtShareTab ? (
                        <ArtShare navigation={this.props.navigation} />
                    ) : this.state.activeTab === TabName.ReservedListTab ? <ReservedList navigation={this.props.navigation} /> : <TranHistory />}
                </View>
            </ScrollView >
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _authUserInfo = state.api?.user?.authUser
    return ({
        currentUser: _authUserInfo.response,
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateMenu: (activeItem: string) => dispatch(updateMenu(activeItem))
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(HeaderContentHOC, SideNavContentHOC, FooterHOC)(DashboardPage as any))