import React from 'react'
import ReactModal from 'react-modal'
import DrawerContent from './DrawerContent'
import Auth from '@aws-amplify/auth'
import AuthDrawerContent from './AuthDrawerContent'
import linking from '../utils/linking'
import IdleTimerContainer from './IdleTimerContainer'
import config from '../config'
import Socket from '../websocket'
import Heartbeat from 'react-heartbeat'
import ReminderModal from '../components/Modal/ReminderModal'
import * as _ from 'lodash'
import amplify, { Hub } from '@aws-amplify/core'
import { NavigationContainer, Theme } from '@react-navigation/native'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { AuthStackScreen } from './screens/AuthStackScreen'
import { MainStackScreen } from './screens/MainStackScreen'
import { RouteName } from './types'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Platform, Pressable, Text, View } from 'react-native'
import { ReduxAppState } from '../utils/types'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { ActivityIndicator, IconButton, Title } from 'react-native-paper'
import {
    CLEAR_USER,
    errorFederatedLogin,
    logOutUser,
    retrieveUser,
    setRefreshToken,
    setToken
} from '../reducers/User/user'
import { GlobalModal } from '../ReusableComponents'
import { getForex } from '../reducers/Wallet/getForex'
import { validateReservedItem } from '../reducers/Items/validateReservedItem'
import { getReservedItemByUser } from '../reducers/Items/getReservedItemByUser'
import { getAllSystemParams } from '../reducers/SystemProperty/getAllSysParam'
import { generateRandomId } from '../utils'
import {
    CLEAR_NOTIFICATION_COUNT,
    setNotificationCount
} from '../reducers/Notification/getNotificationWithLimit'

const Drawer = createDrawerNavigator()
const Tab = createBottomTabNavigator()

export interface NavigationThemeProps {
    theme: Theme
}

export interface NavigationProps {
    isSignedIn?: boolean
    isLoading: boolean
    hasToken?: boolean
    isFederatedSignIn?: boolean
    isGoogleLogin?: boolean
    isFbLogin?: boolean
    isFederatedError: boolean
    // isVerifiedEmail: boolean
}

export interface StateProps {
    user: any
    isGlobalLoading: boolean
    isGlobalError: boolean
    globalErrorMessage: string
}

export interface DispatchProps {
    getUserInfo: () => any
    logOutUser: () => any
    onClearUser: () => any
    errorFederatedLogin: (message: string) => any
    onClearNotifications: () => any
    onHideGlobalError: () => void
    onGetForex: () => any
    onValidateReservedItem: () => any
    onGetReservedItemByUser: (accountId: string) => any
    onGetAllSystemParams: () => any
    setGuestUser: (value: string) => void
    setNotifCount: (count: number) => any
}

export interface NavPageState {
    isOpenReminder: boolean
    remainingDays: number
}

export type NavContentProps = NavigationThemeProps & StateProps & NavigationProps & DispatchProps

class Navigation extends React.Component<NavContentProps, NavPageState> {
    constructor(props: NavContentProps) {
        super(props)
        this.state = {
            isOpenReminder: false,
            remainingDays: 0
        }
        amplify.configure({
            Auth: {
                mandatorySignIn: true,
                region: config.cognito.REGION,
                userPoolId: config.cognito.USER_POOL_ID,
                identityPoolId: config.cognito.IDENTITY_POOL_ID,
                userPoolWebClientId: config.cognito.APP_CLIENT_ID,
                oauth: {
                    domain: config.oauth.DOMAIN,
                    scope: config.oauth.SCOPE,
                    redirectSignIn: config.oauth.REDIRECT_SIGNIN,
                    redirectSignOut: config.oauth.REDIRECT_SIGNOUT,
                    responseType: config.oauth.RESPONSE_TYPE
                }
            }
        })

        Hub.listen('auth', async ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    await Auth.currentAuthenticatedUser().then((res) => {
                        const userSession = res.signInUserSession
                        setToken(userSession.idToken.jwtToken)
                        setRefreshToken(userSession.refreshToken.token)
                        this.props.getUserInfo()
                        if (this.props.isFederatedSignIn) {
                            this.props.setGuestUser('')
                            Socket.init(userSession.idToken.payload.sub)
                        }
                    })
                    break
                case 'oAuthSignOut':
                    this.props.onClearNotifications()
                    this.props.logOutUser()
                    Socket.closeConnection()
                    break
                case 'customOAuthState':
                    break
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    this.props.errorFederatedLogin(data)
                    break
            }
        })
    }

    onCloseReminder = () => this.setState({ isOpenReminder: false })

    checkIfItemIsExpired = async () => {
        await this.props.onValidateReservedItem()
        this.props.onGetReservedItemByUser(this.props.user.id).then((resp) => {
            const _data: any = _.first(resp?.data?.message)
            if (_data && _data?.shouldRemind) {
                this.setState({
                    isOpenReminder: _data?.shouldRemind,
                    remainingDays: _data?.remainingDays
                })
            }
        })
    }

    initiateSocket = async () => {
        // Socket.closeConnection()
        let id = `guest:${generateRandomId()}`
        if (this.props.isSignedIn) {
            id = this.props.user?.sub
            await this.checkIfItemIsExpired()
            this.props.onGetForex()
        } else {
            this.props.setGuestUser(id)
        }
        await Socket.init(id)
        this.handleReceive(id)
    }

    async componentDidMount() {
        this.props.onGetAllSystemParams()
        this.initiateSocket()
    }

    componentWillUnmount() {
        // Hub.remove('auth', this.props.logOutUser())
        this.props.onClearNotifications()
    }

    async componentDidUpdate(
        prevProps: Readonly<NavContentProps>,
        prevState: Readonly<NavPageState>,
        snapshot?: any
    ): Promise<void> {
        if (!_.isEqual(this.props.user, prevProps.user)) {
            this.initiateSocket()
        }
    }

    handleReceive = (id: string) => {
        if (Socket.ws?.readyState === WebSocket.OPEN) {
            Socket.onMessage((event: any) => {
                if (event?.data === 'updateSystemParams') {
                    this.props.onGetAllSystemParams()
                } else if (event?.data !== 'Updated Successfully') {
                    const _data = JSON.parse(event.data)
                    const _notificationCount = _data.notificationCount
                    this.props.setNotifCount(_notificationCount)
                }
            }, id)
        } else {
            // Queue a retry
            setTimeout(() => {
                this.handleReceive(id)
            }, 1000)
        }
    }

    reConnectWebsocket = () => {
        if (this.props.isSignedIn) {
            Socket.init(this.props.user?.cognitoId)
        } else {
            const guestId = `guest:${generateRandomId()}`
            this.props.setGuestUser(guestId)
            Socket.init(guestId)
        }
    }

    render() {
        const { theme, isLoading, isSignedIn, isFederatedSignIn, user } = this.props
        const isWeb = Platform.OS === 'web'
        const _url = new URL(window.location.href)
        let defaultPage = 'Dashboard'
        if (_url?.pathname) {
            defaultPage = _url?.pathname.split('/')[1]
        }

        // console.log(defaultPage)

        return (
            <React.Fragment>
                <ReminderModal
                    isOpen={this.state.isOpenReminder}
                    remainingDays={this.state.remainingDays}
                    onRequestClose={this.onCloseReminder}
                />
                <ReactModal
                    isOpen={this.props.isGlobalLoading}
                    style={{
                        content: {
                            opacity: 0.5,
                            overflow: 'hidden',
                            zIndex: 10,
                            position: 'absolute'
                        },
                        overlay: {
                            zIndex: 100
                        }
                    }}>
                    <View
                        style={{
                            position: 'absolute',
                            backgroundColor: 'transparent',
                            zIndex: 10000,
                            width: '100%',
                            height: '100%',
                            opacity: 0.7,
                            justifyContent: 'center'
                        }}>
                        <ActivityIndicator animating={true} color="#F68C29" size="large" />
                    </View>
                </ReactModal>
                {/* {!this.props.isGlobalLoading && <View style={{ position: 'absolute', backgroundColor: 'white', zIndex: 10000, width: '100%', height: '100%', opacity: 0.7, justifyContent: 'center'  }}>
          <ActivityIndicator animating={true} color='#F68C29' size='large' />
        </View>} */}
                {this.props.isGlobalError && (
                    <Pressable
                        onPress={() => this.props.onHideGlobalError()}
                        style={{
                            minWidth: 400,
                            position: 'absolute',
                            zIndex: 10,
                            backgroundColor: '#F36564',
                            paddingHorizontal: 20,
                            paddingVertical: 10,
                            borderRadius: 10,
                            top: '10%',
                            right: '50%',
                            transform: 'translateX(50%)'
                        }}>
                        <Text style={{ fontFamily: 'Roboto', fontSize: 24, color: 'white' }}>
                            {this.props.globalErrorMessage ??
                                'Something went wrong. Please try again'}
                        </Text>
                    </Pressable>
                )}
                <GlobalModal />
                {isLoading && isFederatedSignIn ? (
                    <SplashScreen />
                ) : (
                    <NavigationContainer theme={theme} linking={linking}>
                        {isSignedIn ? (
                            isWeb ? (
                                <React.Fragment>
                                    <Heartbeat
                                        heartbeatInterval={1000 * 30}
                                        heartbeatFunction={this.reConnectWebsocket}
                                    />
                                    <IdleTimerContainer />
                                    <Drawer.Navigator
                                        drawerContent={(props) => <DrawerContent {...props} />}>
                                        <Drawer.Screen
                                            name={RouteName.MainHomePage}
                                            children={() => (
                                                <MainStackScreen
                                                    isResetPwd={user[
                                                        'custom:force_change_pwd'
                                                    ]?.toString()}
                                                    defaultPage={defaultPage}
                                                />
                                            )}
                                        />
                                    </Drawer.Navigator>
                                </React.Fragment>
                            ) : (
                                <Tab.Navigator>
                                    <Tab.Screen
                                        name="Portfolio"
                                        // component={Dashboard}
                                        options={{
                                            tabBarIcon: () => (
                                                <IconButton icon="briefcase-outline" />
                                            )
                                        }}
                                        children={() => (
                                            <MainStackScreen
                                                defaultPage={RouteName.DashboardPage}
                                                isResetPwd={user[
                                                    'custom:force_change_pwd'
                                                ]?.toString()}
                                            />
                                        )}
                                    />
                                    <Tab.Screen
                                        name="Listing"
                                        // component={List}
                                        options={{
                                            tabBarIcon: () => (
                                                <IconButton icon="image-multiple-outline" />
                                            )
                                        }}
                                        children={() => (
                                            <MainStackScreen
                                                defaultPage={RouteName.ListPage}
                                                isResetPwd={user[
                                                    'custom:force_change_pwd'
                                                ]?.toString()}
                                            />
                                        )}
                                    />
                                    <Tab.Screen
                                        name="Wallet"
                                        // component={Wallet}
                                        options={{
                                            tabBarIcon: () => <IconButton icon="wallet-outline" />
                                        }}
                                        children={() => (
                                            <MainStackScreen
                                                defaultPage={RouteName.WalletPage}
                                                isResetPwd={user[
                                                    'custom:force_change_pwd'
                                                ]?.toString()}
                                            />
                                        )}
                                    />
                                    <Tab.Screen
                                        name="Settings"
                                        // component={Settings}
                                        options={{
                                            tabBarIcon: () => <IconButton icon="cog-outline" />
                                        }}
                                        children={() => (
                                            <MainStackScreen
                                                defaultPage={RouteName.SettingsPage}
                                                isResetPwd={user[
                                                    'custom:force_change_pwd'
                                                ]?.toString()}
                                            />
                                        )}
                                    />
                                </Tab.Navigator>
                            )
                        ) : (
                            <Drawer.Navigator
                                drawerContent={(props) => <AuthDrawerContent {...props} />}>
                                <Drawer.Screen
                                    name={RouteName.AuthPage}
                                    children={() => (
                                        <AuthStackScreen
                                            defaultPage={RouteName.SettingsPage}
                                            isFederatedError={this.props.isFederatedError}
                                        />
                                    )}
                                />
                            </Drawer.Navigator>
                        )}
                    </NavigationContainer>
                )}
            </React.Fragment>
        )
    }
}

const SplashScreen = () => {
    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator
                animating={true}
                color="#AFCD37"
                size="large"
                style={{ margin: 10 }}
            />
            <Text>Loading...</Text>
        </View>
    )
}

const mapStateToProps = (state: ReduxAppState) => {
    const _authUserInfo = state.api?.user?.authUser
    return {
        isSignedIn: _authUserInfo.isSignedIn,
        isLoading: _authUserInfo.loading,
        user: _authUserInfo.response,
        isGoogleLogin: _authUserInfo.isGoogleLogin,
        isFbLogin: _authUserInfo.isFbLogin,
        isFederatedSignIn: _authUserInfo.isFederatedSignIn,
        isFederatedError: state.api?.user.authUser.statusText === 'relogin',
        isGlobalLoading: state.custom?.isGlobalLoading === true,
        isGlobalError: state.custom?.isGlobalError === true,
        globalErrorMessage: state.custom?.globalErrorMessage
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getUserInfo: () => retrieveUser(dispatch),
    logOutUser: () => logOutUser(dispatch),
    errorFederatedLogin: (message: string) => dispatch(errorFederatedLogin(message)),
    onClearUser: () => dispatch({ type: CLEAR_USER }),
    onClearNotifications: () => dispatch({ type: CLEAR_NOTIFICATION_COUNT }),
    onHideGlobalError: () => dispatch({ type: 'custom.isGlobalError', value: false }),
    setGuestUser: (value: string) => dispatch({ type: 'custom.guest', value }),
    onGetForex: () => getForex(dispatch, { from: 'USD', to: 'PHP' }),
    onValidateReservedItem: () => validateReservedItem(dispatch),
    onGetReservedItemByUser: (accountId: string) => getReservedItemByUser(dispatch, accountId),
    onGetAllSystemParams: () => getAllSystemParams(dispatch),
    setNotifCount: (count: number) => dispatch(setNotificationCount(count))
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
