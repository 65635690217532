import React from 'react'

import { View, ScrollView, Text, TouchableOpacity } from 'react-native'
import { NavigationStackProp } from 'react-navigation-stack';
import { listingStyles as styles } from './style'
import { IconButton, Title } from 'react-native-paper'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { FormattedMessage } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import SideNavContentHOC from '../../hoc/SidenavContent'
import HeaderContentHOC from '../../hoc/HeaderContent'
import FooterHOC from '../../hoc/Footer'
import { ResponsiveView, Alert } from '../../ReusableComponents'
import { RouteName, TabName } from '../../navigation/types'
import ActiveList from '../ActiveList/ActiveList'
import UpcomingList from '../UpcomingList/UpcomingList'
import { RESET_ADD_ITEM } from '../../reducers/Items/addItem'
import { DeleteItemState } from '../../reducers/Items/deleteItem'
import * as _ from 'lodash'
import { useMediaQuery } from 'react-responsive'
export interface ComponentProps {
    navigation: NavigationStackProp<any>
    isRefreshing: boolean
}

export interface DispatchProps {
    resetAddItem: () => void
}

export interface StateProps {
    isLoading: boolean
    isError: boolean
    isAddItemSuccess: boolean
    deleteItem: DeleteItemState
    userType: string | number
}

export interface ListPageState {
    activeTab: string
    showSuccessDelete: boolean
}

export type ListPageProps = ComponentProps & DispatchProps & StateProps

const ListPageView = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 987px)' })
    return (
        <View>
            {props.children}
        </View>
    )
}

class ListPage extends React.Component<ListPageProps, ListPageState> {
    constructor(props: ListPageProps) {
        super(props)
        this.state = {
            activeTab: TabName.ActiveListTab,
            showSuccessDelete: false
        }
    }


    onChangeActiveTab = (item: string) => {
        this.setState({
            activeTab: item
        })
    }

    componentDidUpdate(prevProps: ListPageProps) {
        if (!_.isEqual(this.props.deleteItem?.response, prevProps.deleteItem?.response) && this.props.deleteItem?.statusText === 'success') {
            this.setState({ showSuccessDelete: true })
        }
    }

    onAlertClose = () => {
        this.setState({ showSuccessDelete: false })
    }

    render() {
        return (
            <ScrollView style={styles.demoContainer}>
                <ListPageView>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row' }}>
                            <TouchableOpacity onPress={() => this.onChangeActiveTab(TabName.ActiveListTab)}>
                                <Title numberOfLines={1}
                                    style={this.state.activeTab === TabName.ActiveListTab ? styles.activeTitle : styles.title}>
                                    <FormattedMessage id='Listing.msg.activeList' />
                                </Title>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.onChangeActiveTab(TabName.UpcomingListTab)}>
                                <Title numberOfLines={1}
                                    style={this.state.activeTab === TabName.UpcomingListTab ? styles.activeTitle : styles.title}>
                                    <FormattedMessage id='Listing.msg.upcomingList' />
                                </Title>
                            </TouchableOpacity>
                        </View>
                        {/* {this.props.userType === '0' && (
                            <View style={{ position: 'relative' }}>
                                <View style={styles.addButton} />
                                <IconButton
                                    icon='plus-box'
                                    size={50}
                                    onPress={() => this.props.navigation.push(RouteName.ListingPage)}
                                />
                            </View>
                        )} */}
                    </View>
                    <Alert autoClose visible={this.props.isAddItemSuccess} type='success'>
                        <FormattedMessage id='Listing.msg.successMsg' />
                    </Alert>
                    <Alert autoClose onClose={this.onAlertClose} visible={this.state.showSuccessDelete} type='success'>
                        <FormattedMessage id='Listing.msg.deleteSuccessMsg' />
                    </Alert>
                    {/* {this.state.showSuccessDelete && (
                        <View style={styles.successMessage}>
                            <Text style={styles.successTxt}><FormattedMessage id='Listing.msg.successMsg' /></Text>
                        </View>
                    )} */}
                    {this.state.activeTab === TabName.ActiveListTab ? (
                        <ActiveList navigation={this.props.navigation} />
                    ) : <UpcomingList navigation={this.props.navigation} />}
                </ListPageView>
            </ScrollView >
        )
    }
}



const mapStateToProps = (state: ReduxAppState) => {
    return ({
        isAddItemSuccess: state.api?.items?.addItem?.statusText === 'success',
        deleteItem: state.api?.items?.deleteItem,
        userType: state.api?.user?.authUser?.response?.hasOwnProperty('custom:user_type') ? state.api?.user?.authUser?.response['custom:user_type'] : '1'
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetAddItem: () => dispatch({ type: RESET_ADD_ITEM })

})

export default connect(mapStateToProps, mapDispatchToProps)(compose(HeaderContentHOC, SideNavContentHOC, FooterHOC)(ListPage as any))