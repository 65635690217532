import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Items } from '.'


export const BEGIN_GET_ITEM_LIST = 'API/ITEM/BEGIN_GET_ITEM_LIST'
export const SUCCESS_GET_ITEM_LIST = 'API/ITEM/SUCCESS_GET_ITEM_LIST'
export const ERROR_GET_ITEM_LIST = 'API/ITEM/ERROR_GET_ITEM_LIST'

export const getItemListInitialState = {
    response: undefined,
    loading: false,
}

export interface GetItemListState {
    response: Items | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any
    imageUrl?: any
}

export interface GetItemListActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: GetItemListState = getItemListInitialState, action: GetItemListActions) => {
    switch (action.type) {
        case BEGIN_GET_ITEM_LIST:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_ITEM_LIST:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_GET_ITEM_LIST:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}

export const beginGetItemList = () => ({
    type: BEGIN_GET_ITEM_LIST,
})

export const successGetItemList = (response: AxiosResponse) => ({
    type: SUCCESS_GET_ITEM_LIST,
    data: response.data.message,
    status: response.status
})

export const errorGetItemList = (error: AxiosError) => ({
    type: ERROR_GET_ITEM_LIST,
    status: error.response?.status,
})

export const getItemList = (dispatch: Dispatch, params: any) => {
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url: `/get/item-list?&id=0`,
        params
    }
    dispatch(beginGetItemList())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successGetItemList(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetItemList(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer