import React from 'react'

import { View, ScrollView, Text, TouchableOpacity, Dimensions, Image, Platform } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { dashboardStyles as styles } from './styles'
import { Button, IconButton, Switch, Title } from 'react-native-paper'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { FormattedMessage } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import SideNavContentHOC from '../../hoc/SidenavContent'
import HeaderContentHOC from '../../hoc/HeaderContent'
import { updateUser } from '../../reducers/User/updateUser'
import { User } from '../../reducers/User'
import { AuthUserInfoState, retrieveUser, successRetrieveUser } from '../../reducers/User/user'
import { RouteName } from '../../navigation/types'
import * as _ from 'lodash'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean
}

export interface DispatchProps {
    updateUser: (userId: string, data: any) => any
    onSetGlobalLoading: (isLoading: boolean) => void
    updateUserState: (data: any) => void
    getUserInfo: () => any
    onSetGlobalError: (isShowError: boolean) => void
}

export interface StateProps {
    isLoading: boolean
    isError: boolean
    user: User
}

export interface SettingsPageState {}

export type SettingsPageProps = ComponentProps & DispatchProps & StateProps

class SettingsPage extends React.Component<SettingsPageProps, SettingsPageState> {
    constructor(props: SettingsPageProps) {
        super(props)
    }

    handleBooleanValue = async (key: string) => {
        const user = this.props.user
        this.props.onSetGlobalLoading(true)
        if (user || !_.isEmpty(user)) {
            try {
                await this.props.updateUser(user.id, { [key]: !_.get(user, key, false) })
                await this.props.getUserInfo()
                this.props.onSetGlobalLoading(false)
            } catch (error: any) {
                this.props.onSetGlobalLoading(false)
                this.props.onSetGlobalError(true)
                setTimeout(() => this.props.onSetGlobalError(false), 10000)
            }
        }
    }

    navigateToChangePassword = () => {
        this.props.navigation.navigate(RouteName.ChangePasswordPage)
    }

    render() {
        return (
            <ScrollView style={styles.demoContainer}>
                {/* Overview */}
                <View style={styles.titleContainer}>
                    <View>
                        {/* <Title numberOfLines={1} style={styles.title}>
                            <FormattedMessage id='Settings.msg.title' />
                        </Title> */}
                        <View>
                            <Title
                                style={{
                                    fontWeight: '700',
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontSize: 18
                                }}>
                                SECURITY
                            </Title>
                            <View style={styles.titleDivider} />
                            <View style={styles.content}>
                                <Text style={styles.contentLabel}>Change Password</Text>
                                <Button
                                    onPress={() => this.navigateToChangePassword()}
                                    style={{ backgroundColor: '#F68C29', borderRadius: 10 }}
                                    labelStyle={{ fontFamily: "'Montserrat', sans-serif" }}
                                    color="white">
                                    CHANGE
                                </Button>
                            </View>
                            <View style={styles.contentDivider} />
                            <View style={styles.content}>
                                <Text style={styles.contentLabel}>Two Factor Authentication</Text>
                                <Switch
                                    color="#29B34B"
                                    value={this.props.user?.isTwoFactorEnabled}
                                    onValueChange={() =>
                                        this.handleBooleanValue('isTwoFactorEnabled')
                                    }
                                    style={{ marginRight: 25 }}
                                />
                            </View>
                            <View style={styles.contentDivider} />
                        </View>
                        <View style={{ marginTop: 50 }}>
                            <Title
                                style={{
                                    fontWeight: '700',
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontSize: 18
                                }}>
                                NOTIFICATIONS
                            </Title>
                            <View style={styles.titleDivider} />
                            <View style={styles.content}>
                                <Text style={styles.contentLabel}>Email</Text>
                                <Switch color="#29B34B" value={false} style={{ marginRight: 25 }} />
                            </View>
                            <View style={styles.contentDivider} />
                            <View style={styles.content}>
                                <Text style={styles.contentLabel}>Web</Text>
                                <Switch color="#29B34B" value={false} style={{ marginRight: 25 }} />
                            </View>
                            <View style={styles.contentDivider} />
                            <View style={styles.content}>
                                <Text style={styles.contentLabel}>SMS</Text>
                                <Switch color="#29B34B" value={false} style={{ marginRight: 25 }} />
                            </View>
                            <View style={styles.contentDivider} />
                            <View style={styles.content}>
                                <Text style={styles.contentLabel}>Buy</Text>
                                <Switch
                                    color="#29B34B"
                                    value={this.props.user?.isBuyNotifEnabled}
                                    style={{ marginRight: 25 }}
                                    onValueChange={() =>
                                        this.handleBooleanValue('isBuyNotifEnabled')
                                    }
                                />
                            </View>
                            <View style={styles.contentDivider} />
                            <View style={styles.content}>
                                <Text style={styles.contentLabel}>Deposit</Text>
                                <Switch
                                    color="#29B34B"
                                    value={this.props.user?.isDepositNotifEnabled}
                                    style={{ marginRight: 25 }}
                                    onValueChange={() =>
                                        this.handleBooleanValue('isDepositNotifEnabled')
                                    }
                                />
                            </View>
                            <View style={styles.contentDivider} />
                            <View style={styles.content}>
                                <Text style={styles.contentLabel}>Refund</Text>
                                <Switch
                                    color="#29B34B"
                                    value={this.props.user?.isRefundNotifEnabled}
                                    style={{ marginRight: 25 }}
                                    onValueChange={() =>
                                        this.handleBooleanValue('isRefundNotifEnabled')
                                    }
                                />
                            </View>
                            <View style={styles.contentDivider} />
                            <View style={styles.content}>
                                <Text style={styles.contentLabel}>Reserve</Text>
                                <Switch
                                    color="#29B34B"
                                    value={this.props.user?.isReserveNotifEnabled}
                                    style={{ marginRight: 25 }}
                                    onValueChange={() =>
                                        this.handleBooleanValue('isReserveNotifEnabled')
                                    }
                                />
                            </View>
                            <View style={styles.contentDivider} />
                            <View style={styles.content}>
                                <Text style={styles.contentLabel}>Sell</Text>
                                <Switch
                                    color="#29B34B"
                                    value={this.props.user?.isSellNotifEnabled}
                                    style={{ marginRight: 25 }}
                                    onValueChange={() =>
                                        this.handleBooleanValue('isSellNotifEnabled')
                                    }
                                />
                            </View>
                            <View style={styles.contentDivider} />
                        </View>
                        <View style={{ marginTop: 50 }}>
                            <Title
                                style={{
                                    fontWeight: '700',
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontSize: 18
                                }}>
                                INFORMATION
                            </Title>
                            <View style={styles.titleDivider} />
                            <View style={{ paddingHorizontal: 20, paddingVertical: 5 }}>
                                <Text style={styles.contentLabel}>Terms and Condition</Text>
                            </View>
                            <View style={styles.contentDivider} />
                            <View style={{ paddingHorizontal: 20, paddingVertical: 5 }}>
                                <Text style={styles.contentLabel}>Privacy Policy</Text>
                            </View>
                            <View style={styles.contentDivider} />
                            <View style={{ paddingHorizontal: 20, paddingVertical: 5 }}>
                                <Text style={styles.contentLabel}>ArtShare Release Version</Text>
                            </View>
                            <View style={styles.contentDivider} />
                        </View>
                    </View>
                </View>
            </ScrollView>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        user: state.api?.user?.authUser.response
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateUser: (userId: string, data: any) =>
        updateUser(dispatch, userId, { userAttributes: data }),
    onSetGlobalLoading: (isLoading: boolean) =>
        dispatch({ type: 'custom.isGlobalLoading', value: isLoading }),
    onSetGlobalError: (isShowError: boolean) =>
        dispatch({ type: 'custom.isGlobalError', value: isShowError }),
    updateUserState: (data: any) => dispatch(successRetrieveUser(data)),
    getUserInfo: () => retrieveUser(dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(HeaderContentHOC, SideNavContentHOC)(SettingsPage as any))
