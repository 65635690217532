import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import awsInstance, { methods } from '../../core/axios.config'

export const BEGIN_CHANGE_USER_PASSWORD = 'API/USER/BEGIN_CHANGE_USER_PASSWORD '
export const SUCCESS_CHANGE_USER_PASSWORD = 'API/USER/SUCCESS_CHANGE_USER_PASSWORD'
export const ERROR_CHANGE_USER_PASSWORD = 'API/USER/ERROR_CHANGE_USER_PASSWORD'
export const CLEAR_USER_CHANGE_PASSWORD = 'API/USER/CLEAR_USER_CHANGE_PASSWORD'
export const SET_TEMP_USER_PASSWORD = 'API/USER/SET_TEMP_USER_PASSWORD'

export const changePasswordInitialState = {
    response: [],
    loading: false
}

export interface ChangePasswordState {
    response: any
    tempData?: any
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface ChangePasswordActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: ChangePasswordState = changePasswordInitialState, action: ChangePasswordActions) => {
    switch (action.type) {
        case BEGIN_CHANGE_USER_PASSWORD:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_CHANGE_USER_PASSWORD:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status,
                error: ''
            })

        case ERROR_CHANGE_USER_PASSWORD:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status,
                error: action.message
            })

        case SET_TEMP_USER_PASSWORD:
            return Object.assign({}, newState, {
                loading: false,
                tempData: action.message
            })

        case CLEAR_USER_CHANGE_PASSWORD:
            return changePasswordInitialState
        default:
            return newState
    }
}

export const beginChangeUserPassword = () => ({
    type: BEGIN_CHANGE_USER_PASSWORD,
})

export const successChangeUserPassword = (response: any) => ({
    type: SUCCESS_CHANGE_USER_PASSWORD,
    data: response,
    status: response.status
})

export const errorChangeUserPassword = (error: any) => ({
    type: ERROR_CHANGE_USER_PASSWORD,
    message: error.message
})

export const setTempUserPassword = (data: any) => ({
    type: SET_TEMP_USER_PASSWORD,
    message: data
})

export const clearUserChangePassword = () => ({
    type: CLEAR_USER_CHANGE_PASSWORD
})

export const changeUserPassword = async (dispatch: Dispatch, data: any) => {
    const config: AxiosRequestConfig = {
        method: methods.PUT,
        url: `/user/change-password`,
        data
    }
    dispatch(beginChangeUserPassword())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successChangeUserPassword(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorChangeUserPassword(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })
}

export default reducer