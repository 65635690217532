import React from 'react'

import { View, ScrollView, Text, TouchableOpacity, Dimensions, Image, Platform, Linking } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { itemStyles as styles } from './styles'
import { ActivityIndicator, Button, IconButton, Title } from 'react-native-paper'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import SideNavContentHOC from '../../hoc/SidenavContent'
import HeaderContentHOC from '../../hoc/HeaderContent'
import { Alert, ArtText, Form, ResponsiveView, Screen } from '../../ReusableComponents'
import { TabName } from '../../navigation/types';
import { Items } from '../../reducers/Items'
import Buy from '../Buy/Buy'
import MyImageGallery from "./components/MyImageGallery";
import { useMediaQuery } from 'react-responsive'
import FooterHOC from '../../hoc/Footer'
import SellModal from '../Modal/SellModal'
import { getPortfolio } from '../../reducers/Portfolio/getPortfolio'
import { User } from '../../reducers/User'
import { Portfolio } from '../../reducers/Portfolio'

import * as _ from 'lodash'
import { sellItem } from '../../reducers/Items/sellItem'
import ArtworkSoldModal from '../Modal/ArtworkSoldModal'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean
    route?: any
}

export interface DispatchProps {
    onGetPortfolio: (params: any) => any
}

export interface StateProps {
    item: Items
    isLoading: boolean
    isError: boolean
    imageUrl: string,
    authenticationDetailsFileId: string,
    conditionReportId: string,
    factSheetFileId: string,
    otherImages: any
    user: User
    portfolio: Portfolio
}

export interface ItemDetailsPageState {
    activeTab: string
    isBuy: boolean
    isSell: boolean
    isSellSuccess: boolean
    isOpenSoldModal: boolean
}

const DetailPage = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 991px)' })
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 990px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const { item, otherImages, authenticationDetailsFileId, conditionReportId, factSheetFileId } = props
    const isSoldOut = props.checkDisabledBuy(props?.route?.params?.isSoldOut, item.listingDate) || item?.isPhysicallySold
    return (
        <React.Fragment>
            <View style={{ flexDirection: isDesktop ? 'row' : 'column', justifyContent: isDesktop ? 'space-evenly' : undefined, paddingBottom: 50, flexWrap: 'wrap' }}>
                <View style={{ flexWrap: 'nowrap', flex: 1, marginBottom: isTablet ? '20%' : 0 }}>
                    <View style={styles.imageContainer}>
                        <MyImageGallery otherImages={otherImages} />
                    </View>
                </View>
                <View style={{ flexWrap: 'nowrap', flex: 1, height: '100%' }}>
                    <View style={{ position: 'absolute', zIndex: 100, marginLeft: 10 }}>
                        {Platform.OS === 'web' && <div id="myPortal" />}
                    </View>
                    <View style={{ flexDirection: 'row', marginLeft: 20, flexWrap: 'wrap' }}>
                        <View style={{ backgroundColor: '#000000', padding: 20, borderRadius: 0, marginRight: 10, marginBottom: 10, alignItems: 'center', width: 150 }}>
                            <Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}><FormattedMessage id='ItemDetails.msg.value' /></Text>
                            <Text style={{ color: 'white', fontSize: 20 }}><FormattedNumber format='USD' value={Number(item?.listedValue)} /></Text>
                        </View>
                        <View style={{ backgroundColor: '#000000', padding: 20, borderRadius: 0, marginRight: 10, marginBottom: 10, alignItems: 'center', width: 150 }}>
                            <Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}><FormattedMessage id='ItemDetails.msg.saleDate' /></Text>
                            <Text style={{ color: 'white', fontSize: 20 }}>{props.convertUTCToDate(item?.saleDate)}</Text>
                        </View>
                        <View style={{ backgroundColor: '#000000', padding: 20, borderRadius: 0, marginRight: 10, marginBottom: 10, alignItems: 'center' }}>
                            <Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}><FormattedMessage id='ItemDetails.msg.shareValue' /></Text>
                            <Text style={{ color: 'white', fontSize: 20 }}><FormattedNumber style='percent' maximumFractionDigits={2} value={1} /></Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginLeft: 20, flexWrap: 'wrap' }}>
                        <Form.Submit disabled={isSoldOut} onPress={props.onBuyItem} title='BUY' style={{ backgroundColor: isSoldOut ? '#CCCCCC' : '#F7931E', padding: 10, borderRadius: 0, marginRight: 10, marginBottom: 10, width: 150 }} labelStyle={{ color: 'white', fontSize: 25 }} />
                        <Button onPress={props.openSellModal} disabled={_.isEmpty(props.portfolio) || props.portfolio[0].itemId !== item?.id || props.portfolio[0].numberOfShare <= 0 || props.portfolio[0].itemId !== item?.id || props.portfolio[0].numberOfShare <= 0 || item?.isPhysicallySold} style={{ backgroundColor: '#CCCCCC', padding: 10, borderRadius: 0, marginRight: 10, marginBottom: 10, width: 150 }} labelStyle={{ color: 'white', fontSize: 25 }} >SELL</Button>
                    </View>
                    <View style={{ flexDirection: 'row', marginLeft: 20, flexWrap: 'wrap' }}>
                        <Text style={{ color: '#000000', fontSize: 15, fontStyle: 'italic' }}>{`Note: minimum share is at $${Number(process.env.VALUE_PER_SHARE).toFixed(2)}`}</Text>
                    </View>

                    {/* Art Work */}
                    <View style={{ marginVertical: 20, marginLeft: 25, flexWrap: 'wrap', borderBottomColor: '#000' }}>
                        <Text style={{ fontSize: 25, fontWeight: 'bold', marginBottom: 10 }}><FormattedMessage id='ItemDetails.msg.artwork' /></Text>
                        <Text style={{ fontSize: 20 }}>{`${item.artist}, ${item.year}`}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginLeft: 20, marginVertical: 10 }}>
                        <View style={{ flex: 1, height: 1, backgroundColor: '#9D9D9D', marginRight: 150 }} />
                    </View>

                    {/* DMT */}
                    <View style={{ marginVertical: 20, marginLeft: 25, flexWrap: 'wrap', borderBottomColor: '#000' }}>
                        <Text style={{ fontSize: 25, fontWeight: 'bold', marginBottom: 10 }}><FormattedMessage id='ItemDetails.msg.dmt' /></Text>
                        <Text style={{ fontSize: 20 }}>{`${item.dmt}`}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginLeft: 20, marginVertical: 10 }}>
                        <View style={{ flex: 1, height: 1, backgroundColor: '#9D9D9D', marginRight: 150 }} />
                    </View>

                    {/* Description */}
                    <View style={{ marginVertical: 20, marginLeft: 25, flexWrap: 'wrap', borderBottomColor: '#000' }}>
                        <Text style={{ fontSize: 25, fontWeight: 'bold', marginBottom: 10 }}><FormattedMessage id='ItemDetails.msg.desc' /></Text>
                        <Text style={{ fontSize: 20, textAlign: 'justify' }}>{`${item.description}`}</Text>
                    </View>
                    {/* Display Location */}
                    <View style={{ marginVertical: 20, marginLeft: 25, flexWrap: 'wrap', borderBottomColor: '#000' }}>
                        <Text style={{ fontSize: 25, fontWeight: 'bold', marginBottom: 10 }}><FormattedMessage id='ItemDetails.msg.location' /></Text>
                        <Text style={{ fontSize: 20 }}>Original Artwork - Edition 28/50</Text>
                        <Text style={{ fontSize: 20 }}>{`${item.displayLocation}`}</Text>
                    </View>

                    {/* Files */}
                    <View style={{ marginVertical: 20, marginLeft: 25, flexWrap: 'wrap', borderBottomColor: '#000' }}>
                        <TouchableOpacity onPress={() => Linking.openURL('mailto:support@artshare.gallery')}>
                            <Text style={{ fontSize: 20, fontStyle: 'italic', color: '#3FDCFF', marginBottom: 5 }}><FormattedMessage id='ItemDetails.msg.authDetails' /></Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => Linking.openURL(conditionReportId)}>
                            <Text style={{ fontSize: 20, fontStyle: 'italic', color: '#3FDCFF', marginBottom: 5 }}><FormattedMessage id='ItemDetails.msg.conditionReport' /></Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => Linking.openURL(factSheetFileId)}>
                            <Text style={{ fontSize: 20, fontStyle: 'italic', color: '#3FDCFF' }}><FormattedMessage id='ItemDetails.msg.factSheet' /></Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </React.Fragment>
    )
}

export type ItemDetailsPageProps = ComponentProps & DispatchProps & StateProps

class ItemDetailsPage extends React.Component<ItemDetailsPageProps, ItemDetailsPageState> {
    constructor(props: ItemDetailsPageProps) {
        super(props)
        this.state = {
            activeTab: TabName.DetailsTab,
            isBuy: false,
            isSell: false,
            isSellSuccess: false,
            isOpenSoldModal: false
        }
    }

    componentDidMount(): void {
        // if (this.props.onGetPortfolio({  }))
        if (this.props.item?.isPhysicallySold) {
            this.onSetSoldModal(true)
        }
    }

    componentDidUpdate(prevProps: Readonly<ItemDetailsPageProps>, prevState: Readonly<ItemDetailsPageState>, snapshot?: any): void {
        if (!_.isEqual(prevProps.item, this.props.item)) {
            if (this.props.item?.isPhysicallySold) {
                this.onSetSoldModal(true)
            }
            this.props.onGetPortfolio({ userId: this.props.user.id, itemId: this.props.item.id })
        }
    }

    onSetSoldModal = (isOpen: boolean) => this.setState({ isOpenSoldModal: isOpen })

    onChangeActiveTab = (item: string) => {
        this.setState({
            activeTab: item
        })
    }

    handleSuccess = (isSuccess: boolean) => this.setState({ isSellSuccess: isSuccess })

    openSellModal = () => this.setState({ isSell: true })

    closeSellModal = () => this.setState({ isSell: false })

    onBuyItem = () => {
        this.setState({ isBuy: true })
    }

    closeModal = () => {
        this.setState({ isBuy: false })
    }

    convertUTCToDate = (date: Date) => {
        const convertedDate = new Date(date)
        return (convertedDate.getMonth() + 1) + '/' + convertedDate.getDate() + '/' + convertedDate.getFullYear()
    }

    checkDisabledBuy = (isSoldOut: boolean, listingDate: Date) => {
        let isDisabled = true
        const comparedDate = new Date(listingDate) <= new Date()
        if (comparedDate) {
            isDisabled = false
            if (isSoldOut) {
                isDisabled = true
            }
        }
        return isDisabled
    }

    renderDetailsPage = () => {
        const { isBuy } = this.state
        const { item, otherImages, authenticationDetailsFileId, conditionReportId, factSheetFileId } = this.props
        const isSoldOut = this.checkDisabledBuy(this.props?.route?.params?.isSoldOut, item.listingDate) || item?.isPhysicallySold
        return (
            <React.Fragment>
                <View style={{ flexDirection: 'column', justifyContent: 'space-evenly', paddingBottom: 50, flexWrap: 'wrap' }}>
                    <View style={{ flexWrap: 'nowrap', flex: 1, marginBottom: '20%' }}>
                        <View style={styles.imageContainer}>
                            <MyImageGallery otherImages={otherImages} />
                        </View>
                    </View>
                    <View style={{ flexWrap: 'nowrap', flex: 1, height: '100%' }}>
                        <View style={{ position: 'absolute', zIndex: 100, marginLeft: 10 }}>
                            {Platform.OS === 'web' && <div id="myPortal" />}
                        </View>
                        <View style={{ flexDirection: 'row', marginLeft: 20, flexWrap: 'wrap' }}>
                            <View style={{ backgroundColor: '#000000', padding: 20, borderRadius: 0, marginRight: 10, marginBottom: 10, alignItems: 'center', width: 150 }}>
                                <Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}><FormattedMessage id='ItemDetails.msg.value' /></Text>
                                <Text style={{ color: 'white', fontSize: 20 }}>{`$ ${item.listedValue}`}</Text>
                            </View>
                            <View style={{ backgroundColor: '#000000', padding: 20, borderRadius: 0, marginRight: 10, marginBottom: 10, alignItems: 'center', width: 150 }}>
                                <Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}><FormattedMessage id='ItemDetails.msg.saleDate' /></Text>
                                <Text style={{ color: 'white', fontSize: 20 }}>{`${this.convertUTCToDate(item?.saleDate)}`}</Text>
                            </View>
                            <View style={{ backgroundColor: '#000000', padding: 20, borderRadius: 0, marginRight: 10, marginBottom: 10, alignItems: 'center' }}>
                                <Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}><FormattedMessage id='ItemDetails.msg.shareValue' /></Text>
                                <Text style={{ color: 'white', fontSize: 20 }}><FormattedNumber style='percent' maximumFractionDigits={2} value={1} /></Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', marginLeft: 20, flexWrap: 'wrap' }}>
                            <Form.Submit disabled={isSoldOut} onPress={this.onBuyItem} title='BUY' style={{ backgroundColor: isSoldOut ? '#CCCCCC' : '#F7931E', padding: 10, borderRadius: 0, marginRight: 10, marginBottom: 10, width: 150 }} labelStyle={{ color: 'white', fontSize: 25 }} />
                            <Button onPress={this.openSellModal} disabled={_.isEmpty(this.props.portfolio) || item?.isPhysicallySold} style={{ backgroundColor: '#CCCCCC', padding: 10, borderRadius: 0, marginRight: 10, marginBottom: 10, width: 150 }} labelStyle={{ color: 'white', fontSize: 25 }}>Sell</Button>
                        </View>

                        {/* Art Work */}
                        <View style={{ marginVertical: 20, marginLeft: 25, flexWrap: 'wrap', borderBottomColor: '#000' }}>
                            <Text style={{ fontSize: 25, fontWeight: 'bold', marginBottom: 10 }}><FormattedMessage id='ItemDetails.msg.artwork' /></Text>
                            <Text style={{ fontSize: 20 }}>{`${item.artist}, ${item.year}`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', marginLeft: 20, marginVertical: 10 }}>
                            <View style={{ flex: 1, height: 1, backgroundColor: '#9D9D9D', marginRight: 150 }} />
                        </View>

                        {/* DMT */}
                        <View style={{ marginVertical: 20, marginLeft: 25, flexWrap: 'wrap', borderBottomColor: '#000' }}>
                            <Text style={{ fontSize: 25, fontWeight: 'bold', marginBottom: 10 }}><FormattedMessage id='ItemDetails.msg.dmt' /></Text>
                            <Text style={{ fontSize: 20 }}>{`${item.dmt}`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', marginLeft: 20, marginVertical: 10 }}>
                            <View style={{ flex: 1, height: 1, backgroundColor: '#9D9D9D', marginRight: 150 }} />
                        </View>

                        {/* Description */}
                        <View style={{ marginVertical: 20, marginLeft: 25, flexWrap: 'wrap', borderBottomColor: '#000' }}>
                            <Text style={{ fontSize: 25, fontWeight: 'bold', marginBottom: 10 }}><FormattedMessage id='ItemDetails.msg.desc' /></Text>
                            <Text style={{ fontSize: 20 }}>{`${item.description}`}</Text>
                        </View>
                        {/* Display Location */}
                        <View style={{ marginVertical: 20, marginLeft: 25, flexWrap: 'wrap', borderBottomColor: '#000' }}>
                            <Text style={{ fontSize: 25, fontWeight: 'bold', marginBottom: 10 }}><FormattedMessage id='ItemDetails.msg.location' /></Text>
                            <Text style={{ fontSize: 20 }}>Original Artwork - Edition 28/50</Text>
                            <Text style={{ fontSize: 20 }}>{`${item.displayLocation}`}</Text>
                        </View>

                        {/* Files */}
                        <View style={{ marginVertical: 20, marginLeft: 25, flexWrap: 'wrap', borderBottomColor: '#000' }}>
                            <TouchableOpacity onPress={() => Linking.openURL(authenticationDetailsFileId)}>
                                <Text style={{ fontSize: 20, fontStyle: 'italic', color: '#3FDCFF', marginBottom: 5 }}><FormattedMessage id='ItemDetails.msg.authDetails' /></Text>
                            </TouchableOpacity>
                            <TouchableOpacity>
                                <Text style={{ fontSize: 20, fontStyle: 'italic', color: '#3FDCFF', marginBottom: 5 }}><FormattedMessage id='ItemDetails.msg.conditionReport' /></Text>
                            </TouchableOpacity>
                            <TouchableOpacity>
                                <Text style={{ fontSize: 20, fontStyle: 'italic', color: '#3FDCFF' }}><FormattedMessage id='ItemDetails.msg.factSheet' /></Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </React.Fragment>
        )
    }

    render() {
        const { isBuy } = this.state
        const { item, isLoading } = this.props
        return isLoading ? <ActivityIndicator animating={true} color='#AFCD37' size='large' /> : (
            (
                <View style={styles.demoContainer}>
                    <ArtworkSoldModal isOpen={this.state.isOpenSoldModal} onRequestClose={() => this.onSetSoldModal(false)} />
                    <ResponsiveView width={'80%'}>
                        <Alert autoClose visible={this.state.isSellSuccess} type='success' style={{ width: '50%' }}>
                            <ArtText style={{ fontSize: 20, textAlignVertical: 'center' }} label='Transaction processed successfully.' />
                        </Alert>
                        <View style={{ flexDirection: 'row' }}>
                            <TouchableOpacity onPress={() => this.onChangeActiveTab(TabName.DetailsTab)}>
                                <Title numberOfLines={1} style={this.state.activeTab === TabName.DetailsTab ? styles.activeTitle : styles.title}>
                                    <FormattedMessage id='ItemDetails.msg.details' />
                                </Title>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.onChangeActiveTab(TabName.PreviousOwnerTab)}>
                                <Title numberOfLines={1} style={this.state.activeTab === TabName.PreviousOwnerTab ? styles.activeTitle : styles.title}>
                                    <FormattedMessage id='ItemDetails.msg.previousOwners' />
                                </Title>
                            </TouchableOpacity>
                        </View>
                        <View style={{ flexDirection: 'column' }}>
                            <Text style={styles.artTitle}>{`${item.title}`}</Text>
                            <Text style={[styles.title, { fontStyle: 'italic' }]}>{`${item.artist}, ${item.year}`}</Text>
                        </View>
                        {this.state.activeTab === TabName.DetailsTab ? (
                            <DetailPage
                                {...this.props}
                                onBuyItem={this.onBuyItem}
                                openSellModal={this.openSellModal}
                                checkDisabledBuy={this.checkDisabledBuy}
                                convertUTCToDate={this.convertUTCToDate}
                            />
                        ) : null}
                        <Buy
                            showModal={isBuy}
                            closeModal={this.closeModal}
                            navigation={this.props.navigation}
                            isDetailsPage={true}
                        />
                        <SellModal navigation={this.props.navigation} onHandleSuccess={this.handleSuccess} showModal={this.state.isSell} onClose={this.closeSellModal} />
                    </ResponsiveView>
                </View >
            )
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _getItemInfo = state.api?.items?.getItemInfo
    return ({
        item: _getItemInfo.response,
        imageUrl: _getItemInfo.imageUrl,
        otherImages: _getItemInfo.otherImages,
        authenticationDetailsFileId: _getItemInfo.authenticationDetailsFileId,
        conditionReportId: _getItemInfo.conditionReportId,
        factSheetFileId: _getItemInfo.factSheetFileId,
        isLoading: _getItemInfo.loading,
        isError: _getItemInfo.statusText === 'error',
        user: state.api?.user?.authUser?.response,
        portfolio: state.api?.portfolio?.getPortfolio?.response
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onGetPortfolio: (params: any) => getPortfolio(dispatch, { filter: `userId==${params.userId}&itemId==${params.itemId}` })
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(HeaderContentHOC, SideNavContentHOC, FooterHOC)(ItemDetailsPage as any))