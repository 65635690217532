import { StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

const FormStyle = StyleSheet.create({
    marginBottomSm: {
        marginBottom: 5
    },
    formGroup: {
        marginHorizontal: 20
    },
    input: {
        backgroundColor: '#D1D3D4',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: 'transparent',
        fontSize: 16,
        paddingVertical: 12,
        // width: 300
        width: '100%'
    },
    fields: {
        alignSelf: 'flex-start',
        marginBottom: 7,
        marginLeft: 5,
        color: cpebColors.labelColor,
        fontWeight: '900'
    },
    fields2: {
        marginBottom: 7,
        marginLeft: 10
    },
    errorStyle: {
        marginHorizontal: 0,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12
    },
    greenBtn: {
        backgroundColor: '#AFCD37',
        borderRadius: 20,
        alignSelf: 'center',
        width: '90%',
        fontSize: 20,
        marginVertical: 15,
        borderWidth: 1,
        borderColor: 'black'
    },
    textBlack: {
        color: 'black'
    },
    textWhite: {
        color: 'white'
    },
    alignSelfCenter: {
        alignSelf: 'center'
    },
    fileName: {
        fontSize: 10,
        marginBottom: 10
    },
    paddingOne: {
        padding: 6,
    },
    listStyle: {
        padding: 10,
        borderBottomWidth: .5,
        borderBottomColor: 'grey',
    },
    requiredIcon: {
        color: 'red'
    },
})

export default FormStyle