import { StackNavigationProp } from '@react-navigation/stack'
import { AxiosPromise, AxiosResponse } from 'axios'
import React from 'react'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { Image, Linking, Pressable, Text, View } from 'react-native'
import {
    ActivityIndicator,
    Button,
    Headline,
    IconButton,
    Paragraph,
    Title
} from 'react-native-paper'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { RouteName } from '../../navigation/types'
import { Items } from '../../reducers/Items'
import { getArtShareList } from '../../reducers/Items/getArtShareList'
import { getItemInfo } from '../../reducers/Items/getItemInfo'
import { User } from '../../reducers/User'
import { getPortfolioOfUser, GetPortfolioOfUserState } from '../../reducers/User/getPortfolioOfUser'
import { generateRandomId } from '../../utils'
import { ReduxAppState } from '../../utils/types'
import Buy from '../Buy/Buy'
import { artShareStyles as styles, modalCustomStyles } from './styles'
import { isEqual } from 'lodash'
import { ArtText, ResponsiveView, Screen } from '../../ReusableComponents'
import { useMediaQuery } from 'react-responsive'
import { TouchableOpacity } from 'react-native-gesture-handler'
import Modal from 'react-modal'
import { updateShowModal } from '../../reducers/Modal/showModal'
import Socket from '../../websocket'
import ArtworkSoldModal from '../Modal/ArtworkSoldModal'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
}

export interface DispatchProps {
    getArtShareList: () => any
    getItemInfo: (id: string) => AxiosPromise
    getPortolioItem: (userId: string) => any
    updateShowModal: () => any
    onSetSelectedPortfolio: (data: any) => void
}

export interface StateProps {
    user: User
    portfolioOfUser: GetPortfolioOfUserState
    buyItem: any
    openModal: boolean
    validateLoading: boolean
}

export interface ArtSharePageState {
    items: any
    isBuy: boolean
    isOpenSoldModal: boolean
}

const TableView = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 987px)' })
    return (
        <ResponsiveView width={isDesktop ? '75%' : '100%'} viewStyle={{ overflow: 'visible' }}>
            {props.children}
        </ResponsiveView>
    )
}

export type ArtSharePageProps = ComponentProps & DispatchProps & StateProps

class ArtShareComponent extends React.Component<ArtSharePageProps, ArtSharePageState> {
    constructor(props: ArtSharePageProps) {
        super(props)
        this.state = {
            items: [],
            isBuy: false,
            isOpenSoldModal: false
        }
    }

    componentDidMount() {
        this.handleSend()
        this.props.onSetSelectedPortfolio(undefined)
        this.props.getPortolioItem(this.props.user?.sub)
    }

    handleSend = () => {
        if (Socket.ws?.readyState === WebSocket.OPEN) {
            Socket.sendMessage(
                JSON.stringify({
                    action: 'sendToOne',
                    userId: this.props.user?.sub,
                    isUpdate: false,
                    isAdd: false
                }),
                this.props.user?.sub
            )
        } else {
            // Queue a retry
            setTimeout(() => {
                this.handleSend()
            }, 1000)
        }
    }

    componentDidUpdate(prevProps: ArtSharePageProps) {
        if (!isEqual(prevProps.buyItem, this.props.buyItem)) {
            this.props.getPortolioItem(this.props.user?.sub)
        }
    }

    navigateScreen = () => {
        this.props.navigation.navigate(RouteName.ItemDetailPage)
    }

    onSetSoldModal = (isOpen: boolean) => this.setState({ isOpenSoldModal: isOpen })

    getItemInfo = (itemId: string, item?: any) => {
        this.props.onSetSelectedPortfolio(item)
        this.props.getItemInfo(itemId)
        this.navigateScreen()
        this.closeModal()
    }

    getInitial = (name: string) => {
        const nameSplit = name.split(' ')
        return nameSplit
            .map((a) => a[0])
            .reduce((prev, curr) => (prev += curr))
            .toUpperCase()
    }

    onBuyItem = (item: string) => {
        this.props.getItemInfo(item)
        this.setState({
            isBuy: true
        })
    }

    closeModal = () => {
        this.setState({ isBuy: false })
    }

    onSubmit = () => {
        this.setState({ isBuy: false })
    }

    getShares(numberOfShare: number, totalShare: number) {
        return numberOfShare / totalShare
    }

    onClose = () => {
        this.props.updateShowModal()
    }

    render() {
        const { isBuy } = this.state
        const { portfolioOfUser } = this.props
        return (
            <React.Fragment>
                {portfolioOfUser?.statusText === 'error' && (
                    <Text>
                        <FormattedMessage id="ArtShare.msg.somethingWentWrong" />
                    </Text>
                )}
                <Screen minWidth={769} style={{ width: '100%' }}>
                    <TableView>
                        <View key={generateRandomId()} style={styles.tableHeader}>
                            <View style={[styles.rowHeader, { width: '35%', maxWidth: 400 }]}>
                                <View style={[styles.rowTextContainer]}>
                                    <Headline
                                        style={[
                                            styles.normalText,
                                            styles.boldText,
                                            styles.upperCaseText,
                                            { fontSize: 16, marginLeft: 8 }
                                        ]}>
                                        <FormattedMessage id="ArtShare.msg.artwork" />
                                    </Headline>
                                </View>
                            </View>
                            <View style={styles.rowHeader}>
                                <View style={[styles.rowTextContainer]}>
                                    <Headline
                                        style={[
                                            styles.normalText,
                                            styles.boldText,
                                            styles.upperCaseText,
                                            { textAlign: 'center', fontSize: 16 }
                                        ]}>
                                        Listing Value
                                    </Headline>
                                </View>
                            </View>
                            <View style={styles.rowHeader}>
                                <View style={[styles.rowTextContainer]}>
                                    <Headline
                                        style={[
                                            styles.normalText,
                                            styles.boldText,
                                            styles.upperCaseText,
                                            { textAlign: 'center', fontSize: 16 }
                                        ]}>
                                        Shares
                                    </Headline>
                                </View>
                            </View>
                            <View style={{ width: '25%' }}></View>
                        </View>
                    </TableView>
                    <TableView>
                        {portfolioOfUser?.loading || this.props.validateLoading ? (
                            <ActivityIndicator animating={true} color="#AFCD37" size="large" />
                        ) : (
                            portfolioOfUser?.response?.map((item: any) => {
                                return (
                                    <Pressable
                                        onPress={() => this.getItemInfo(item.id, item)}
                                        key={generateRandomId()}
                                        style={styles.table}>
                                        <View
                                            style={[
                                                styles.row,
                                                { width: '35%', maxWidth: 400, height: 140 }
                                            ]}>
                                            <View
                                                style={[
                                                    styles.row,
                                                    {
                                                        width: '100%',
                                                        height: '100%',
                                                        position: 'relative'
                                                    }
                                                ]}>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <View style={{ height: 80, width: 80 }}>
                                                        <Image
                                                            source={{ uri: item?.imageUrl }}
                                                            style={{
                                                                height: '100%',
                                                                width: '100%'
                                                            }}
                                                        />
                                                        <Text
                                                            style={[
                                                                styles.normalText,
                                                                styles.boldText,
                                                                {
                                                                    textAlign: 'center',
                                                                    fontSize: 16,
                                                                    marginTop: 5
                                                                }
                                                            ]}>
                                                            {this.getInitial(item?.artist ?? '')}
                                                        </Text>
                                                    </View>
                                                    <View style={{ marginLeft: 8, width: 180 }}>
                                                        <Title
                                                            numberOfLines={2}
                                                            style={[
                                                                styles.normalText,
                                                                styles.boldText,
                                                                {
                                                                    fontSize: 16,
                                                                    flexShrink: 1,
                                                                    lineHeight: 25
                                                                }
                                                            ]}>
                                                            {item?.title}
                                                        </Title>
                                                        <Text
                                                            numberOfLines={2}
                                                            style={[
                                                                styles.normalText,
                                                                styles.italicText,
                                                                { fontSize: 14, flexShrink: 1 }
                                                            ]}>{`${item?.artist}, ${item?.year}`}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View
                                                style={{
                                                    borderRightWidth: 1,
                                                    borderColor: '#9D9D9D'
                                                }}
                                            />
                                        </View>
                                        <View style={styles.row}>
                                            <View style={styles.rowTextContainer}>
                                                <Paragraph
                                                    style={[
                                                        styles.normalText,
                                                        styles.boldText,
                                                        { textAlign: 'center', fontSize: 16 }
                                                    ]}>
                                                    <FormattedNumber
                                                        format="USD"
                                                        value={Number(item.listedValue)}
                                                    />
                                                </Paragraph>
                                            </View>
                                            <View
                                                style={{
                                                    borderRightWidth: 1,
                                                    borderColor: '#9D9D9D'
                                                }}
                                            />
                                        </View>
                                        <View style={styles.row}>
                                            <View style={styles.rowTextContainer}>
                                                <Text
                                                    style={[
                                                        styles.normalText,
                                                        styles.boldText,
                                                        { textAlign: 'center', fontSize: 16 }
                                                    ]}>
                                                    <FormattedNumber
                                                        format="USD"
                                                        value={item.portfolio.totalValue}
                                                    />
                                                    (
                                                    <FormattedNumber
                                                        style="percent"
                                                        maximumFractionDigits={4}
                                                        value={this.getShares(
                                                            item.portfolio.numberOfShare,
                                                            item.numberOfShare
                                                        )}
                                                    />
                                                    )
                                                    {/* <Text style={{ color: 'grey', fontFamily: "'Montserrat', sans-serif", fontSize: 16, textAlign: 'center' }}>{` (${this.getShares(item.portfolio.numberOfShare, item.numberOfShare)}%)`}</Text> */}
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    borderRightWidth: 1,
                                                    borderColor: '#9D9D9D'
                                                }}
                                            />
                                        </View>
                                        {item?.isPhysicallySold ? (
                                            <>
                                                <View
                                                    style={[
                                                        styles.row,
                                                        {
                                                            width: '15%',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }
                                                    ]}>
                                                    <ArtText
                                                        label={
                                                            <Text>
                                                                Sold at{' '}
                                                                <FormattedNumber
                                                                    format={'USD'}
                                                                    value={item.amountSold}
                                                                />
                                                            </Text>
                                                        }
                                                    />
                                                </View>
                                                <View
                                                    style={[
                                                        styles.row,
                                                        {
                                                            width: '10%',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }
                                                    ]}>
                                                    <Image
                                                        source={{
                                                            uri: require('../../assets/sold-art-icon.png')
                                                        }}
                                                        style={{
                                                            maxHeight: 100.02,
                                                            maxWidth: 116.97,
                                                            height: '100%',
                                                            width: '100%'
                                                        }}
                                                        resizeMode="stretch"
                                                    />
                                                </View>
                                            </>
                                        ) : (
                                            <>
                                                <View style={[styles.row, { width: '25%' }]}>
                                                    <View
                                                        style={{
                                                            alignSelf: 'center',
                                                            flexDirection: 'row',
                                                            flexWrap: 'wrap',
                                                            width: '100%',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-evenly'
                                                        }}>
                                                        <View style={{ width: 120 }}>
                                                            <Button
                                                                onPress={() =>
                                                                    this.getItemInfo(item.id, item)
                                                                }
                                                                mode="outlined"
                                                                labelStyle={{
                                                                    fontSize: 10,
                                                                    fontFamily:
                                                                        "'Montserrat', sans-serif"
                                                                }}
                                                                color="black">
                                                                DETAILS
                                                            </Button>
                                                        </View>
                                                        <View style={{ width: 120 }}>
                                                            <Button
                                                                onPress={() =>
                                                                    this.onBuyItem(item.id)
                                                                }
                                                                mode="contained"
                                                                color="#F7931E"
                                                                labelStyle={{
                                                                    color: 'white',
                                                                    fontSize: 10,
                                                                    fontFamily:
                                                                        "'Montserrat', sans-serif"
                                                                }}>
                                                                Add Share
                                                            </Button>
                                                        </View>
                                                    </View>
                                                </View>
                                            </>
                                        )}
                                        {/* <View style={[styles.row, { width: '25%' }]}>
                                        <View style={{ alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap', width: '100%', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                            <View style={{ width: 120 }}>
                                                <Button onPress={() => this.getItemInfo(item.id, item)} mode='outlined' labelStyle={{ fontSize: 10, fontFamily: "'Montserrat', sans-serif" }} color='black' >DETAILS</Button>
                                            </View>
                                            <View style={{ width: 120 }}>
                                                <Button onPress={() => this.onBuyItem(item.id)} mode='contained' color='#F7931E' labelStyle={{ color: 'white', fontSize: 10, fontFamily: "'Montserrat', sans-serif" }}>Add Share</Button>
                                            </View>
                                        </View>
                                    </View> */}
                                    </Pressable>
                                )
                            })
                        )}
                    </TableView>
                </Screen>
                <Screen maxWidth={768}>
                    <ResponsiveView width={'90%'}>
                        {portfolioOfUser?.loading ? (
                            <ActivityIndicator animating={true} color="#AFCD37" size="large" />
                        ) : (
                            portfolioOfUser?.response?.map((item: any) => {
                                return (
                                    // <TouchableOpacity style={{ height: '%' }} key={generateRandomId()} onPress={() => this.getItemInfo(item?.i, itemd)}>
                                    <TouchableOpacity
                                        key={generateRandomId()}
                                        onPress={() => this.getItemInfo(item?.i, item)}
                                        style={[
                                            styles.row,
                                            {
                                                height: 'max-content',
                                                width: '100%',
                                                justifyContent: 'space-between',
                                                flexWrap: 'wrap',
                                                borderWidth: 1,
                                                marginBottom: 10
                                            }
                                        ]}>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View
                                                style={{ height: 80, width: 80, marginBottom: 16 }}>
                                                <View style={{ height: '90%', width: '90%' }}>
                                                    <Image
                                                        source={{ uri: item?.imageUrl }}
                                                        style={{ height: '100%', width: '100%' }}
                                                    />
                                                </View>
                                                <View>
                                                    <Text
                                                        style={[
                                                            styles.normalText,
                                                            styles.boldText,
                                                            {
                                                                textAlign: 'center',
                                                                fontSize: 16,
                                                                marginTop: 5
                                                            }
                                                        ]}>
                                                        {this.getInitial(item?.artist ?? '')}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ marginLeft: 8 }}>
                                                <Title style={[styles.boldText, { fontSize: 20 }]}>
                                                    {item?.title}
                                                </Title>
                                                <Text
                                                    style={[
                                                        styles.italicText,
                                                        { fontSize: 17 }
                                                    ]}>{`${item?.artist}, ${item?.year}`}</Text>
                                            </View>
                                        </View>
                                        <Screen minWidth={375}>
                                            <Text style={{ fontWeight: 'bold' }}>
                                                <FormattedMessage id="ActiveList.msg.listValue" />
                                            </Text>
                                            <Text>
                                                <FormattedNumber
                                                    format={'USD'}
                                                    value={item?.listedValue}
                                                />
                                            </Text>
                                            <Text style={{ fontWeight: 'bold' }}>Shares</Text>
                                            <Text>{` (${this.getShares(
                                                item.portfolio.numberOfShare,
                                                item.numberOfShare
                                            )}%)`}</Text>
                                        </Screen>
                                    </TouchableOpacity>
                                    // </TouchableOpacity>
                                )
                            })
                        )}
                    </ResponsiveView>
                </Screen>
                <Buy
                    showModal={isBuy}
                    closeModal={this.closeModal}
                    getItemInfo={this.getItemInfo}
                    navigation={this.props.navigation}
                    isDetailsPage={false}
                />

                {/* Beta Modal  */}
                <Modal
                    isOpen={this.props.openModal}
                    onRequestClose={this.onClose}
                    style={modalCustomStyles}>
                    <View style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}>
                        <IconButton icon="close" size={20} onPress={this.onClose} />
                    </View>
                    <View style={{ width: 480, height: 250, alignItems: 'center' }}>
                        <View style={{ flexDirection: 'column', margin: 20, alignItems: 'center' }}>
                            <Text
                                style={[
                                    styles.modalText,
                                    {
                                        marginBottom: 40,
                                        marginTop: 20,
                                        fontSize: 16,
                                        fontWeight: 'bold'
                                    }
                                ]}>{`Hello there!`}</Text>
                            <Text
                                style={[
                                    styles.modalText
                                ]}>{`ArtShare is on beta and some features are currently limited.`}</Text>
                            <Text style={styles.modalText}>
                                {`Please email us at `}
                                <TouchableOpacity
                                    onPress={() =>
                                        Linking.openURL('mailto:support@artshare.gallery')
                                    }>
                                    <Text style={[styles.modalText, { fontWeight: 'bold' }]}>
                                        support@artshare.gallery
                                    </Text>
                                </TouchableOpacity>
                                <Text style={styles.modalText}>{` for inquiries.`}</Text>
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 40 }}>
                            <Button
                                onPress={() => this.onClose()}
                                mode="contained"
                                color="#F7931E"
                                labelStyle={{
                                    fontSize: 12,
                                    color: '#FFFFFF',
                                    fontFamily: "'Montserrat', sans-serif"
                                }}
                                style={{ margin: 5 }}>
                                I Understand
                            </Button>
                        </View>
                    </View>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        portfolioOfUser: state.api?.user.getPortfolioOfUser,
        user: state.api?.user?.authUser?.response,
        buyItem: state?.api?.items?.buyItem?.response,
        openModal: state.api?.modal?.activeModal?.showModal,
        validateLoading: state.api?.items.validateReservedItem?.loading
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getArtShareList: () => getArtShareList(dispatch),
    getItemInfo: (id: string) => getItemInfo(dispatch, id),
    getPortolioItem: (userId: string) => getPortfolioOfUser(dispatch, userId),
    updateShowModal: () => dispatch(updateShowModal()),
    onSetSelectedPortfolio: (data: any) =>
        dispatch({ type: 'custom.selectedPortfolio', value: data })
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(injectIntl(ArtShareComponent as any)))
