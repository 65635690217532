import React from 'react'
import * as _ from 'lodash'
import * as yup from 'yup'
import { View, Text } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RouteName } from '../../navigation/types'
import { Title } from 'react-native-paper'
import { forgotPwdStyles as styles } from './styles'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { BackgroundTemplate, Form, Screen } from '../../ReusableComponents'
import recaptchaCreds from '../../utils/recaptcha'
import ReCAPTCHA from 'react-google-recaptcha'
import { CLEAR_VERIFY_EMAIL, verifyEmail } from '../../reducers/ForgotPassword/verifyEmail'
import { CLEAR_FORGOT_PWD_OTP_REQ, forgotPwdOtpReq } from '../../reducers/ForgotPassword/otpRequest'
import AlertMessage from './component/AlertMessage'
import { responseCode, responseMsg } from '../../utils/response'

const emailFormat =
    '^[a-zA-Z0-9]+([a-zA-Z0-9_.]+)*@[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*\\.([a-zA-Z0-9]{2,4})$'

const FormSchema = yup.object({
    email: yup
        .string()
        .required('Email is required')
        .test('email', 'Invalid email format', (value: any) => !!value.match(emailFormat))
        .min(16, 'Email must be between 16 and 40 characters long')
        .max(40, 'Email must be between 16 and 40 characters long'),
    verifyEmail: yup
        .string()
        .when('email', (_email, schema) => {
            return schema.test({
                test: () => Boolean(_email),
                message: 'Email field must have a value to verify'
            })
        })
        .oneOf([yup.ref('email'), null], "Email don't match")
        .required('Confirm Email is required'),
    recaptcha: yup.string().required()
})

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean
    intl: any
}

export interface StateProps {
    verifyEmailResp: any
    isLoadingOTPReq: boolean
    otpReqStatus: string
}

export interface DispatchProps {
    verifyEmail: (email: string) => any
    forgotPwdOtpReq: (data: any) => any
    onClearVerifyEmail: () => any
    onClearForgotPwdOtpReq: () => any
}

export interface VerifyEmailPageState {
    isSubmit: boolean
    isLoading: boolean
    isVerified: boolean
    isUserFound: boolean
    isVerifyError: boolean
    isLockedAcc: boolean
    isAlertMessage: boolean
    errMsg: string
    resetData: any
    email: string
    reCaptchatchaResponse: any
}

export type VerifyEmailPageProps = ComponentProps & StateProps & DispatchProps

class VerifyEmailPage extends React.Component<VerifyEmailPageProps, VerifyEmailPageState> {
    reCaptcha: React.RefObject<any>
    constructor(props: VerifyEmailPageProps) {
        super(props)
        this.state = {
            isSubmit: false,
            isLoading: false,
            isVerified: false,
            isUserFound: false,
            isLockedAcc: false,
            isVerifyError: false,
            isAlertMessage: false,
            resetData: {},
            reCaptchatchaResponse: '',
            email: '',
            errMsg: ''
        }
        this.reCaptcha = React.createRef()
    }

    componentDidMount() {
        this.props.onClearVerifyEmail()
        this.props.onClearForgotPwdOtpReq()
    }

    onNavigateToLogin = () => {
        this.setState({ isAlertMessage: false, isVerifyError: false })
        this.props.navigation.navigate(RouteName.LoginPage)
    }

    onChangeRecaptcha = (result: any, onChange?: (value: any) => any) => {
        this.setState({
            isVerified: true,
            reCaptchatchaResponse: result
        })

        if (onChange) {
            onChange(result)
        }
    }

    onSubmit = () => {
        const todaysDate = new Date()
        this.setState({ isLoading: true, isUserFound: true })

        this.props
            .verifyEmail(this.state.email)
            .then(() => {
                if (this.props.verifyEmailResp?.userResponse === responseCode.USER_FOUND) {
                    let dtLastSentOTP = new Date()
                    const selectedUser = this.props.verifyEmailResp?.selectedUser
                    if (selectedUser?.hasOwnProperty('dtLastAttemptOTP')) {
                        dtLastSentOTP = new Date(selectedUser?.dtLastAttemptOTP)
                    }

                    if (
                        dtLastSentOTP.setHours(0, 0, 0, 0) >= todaysDate.setHours(0, 0, 0, 0) &&
                        selectedUser?.otpAttemptCount >= 3
                    ) {
                        this.setState({
                            isLoading: false,
                            isLockedAcc: true,
                            isAlertMessage: true,
                            errMsg: 'Maximum invalid attempt. Your account has been locked.'
                        })
                    } else {
                        const data = {
                            userId: selectedUser?.id,
                            isUpdate: false
                        }

                        this.props.onClearForgotPwdOtpReq()
                        this.props.forgotPwdOtpReq(data).then(() => {
                            if (
                                !this.props.isLoadingOTPReq &&
                                this.props.otpReqStatus !== responseCode.OTP_SUCCESSFUL
                            ) {
                                // Max OTP sent count
                                this.setState({
                                    isLoading: false,
                                    isVerifyError: true,
                                    isAlertMessage: true,
                                    errMsg: responseMsg[this.props.otpReqStatus]
                                })
                            } else {
                                this.setState({
                                    isLoading: false,
                                    isVerifyError: false,
                                    isAlertMessage: false,
                                    errMsg: ''
                                })
                                this.props.navigation.navigate(RouteName.VerifyOTPPage)
                            }
                        })
                    }
                } else {
                    // Email not found
                    this.setState({
                        isUserFound: false,
                        isVerifyError: true,
                        isLoading: false,
                        errMsg: `Sorry, we are unable to verify your email. \nPlease check and try again.`
                    })
                }
            })
            .catch(() => {
                this.setState({
                    isVerifyError: true,
                    isUserFound: false,
                    isLoading: false
                })
            })
    }

    render() {
        const { isVerifyError, isLoading, isAlertMessage } = this.state
        return (
            <BackgroundTemplate>
                {!isAlertMessage ? (
                    <Form
                        formSchema={FormSchema}
                        formStyle={[styles.formStyle]}
                        resetData={this.state.resetData}
                        onSubmit={this.onSubmit}>
                        {isVerifyError && (
                            <View
                                style={{
                                    alignItems: 'flex-start',
                                    marginLeft: 10,
                                    marginRight: 20
                                }}>
                                <View style={styles.errorMessage}>
                                    <Text
                                        style={{
                                            color: 'white',
                                            fontSize: 12,
                                            fontFamily: "'Montserrat', sans-serif"
                                        }}>
                                        {this.state.errMsg || (
                                            <FormattedMessage id="Buy.msg.somethingWentWrong" />
                                        )}
                                    </Text>
                                </View>
                            </View>
                        )}
                        {/* {!isUserFound && isSubmit && !isLoading && <View style={{ alignItems: 'flex-start', marginLeft: 10, marginRight: 20 }}><View style={styles.errorMessage} ><Text style={{ color: 'white', fontSize: 12, fontFamily: "'Montserrat', sans-serif" }}>{this.props.verifyEmailResp?.userResponse}</Text></View></View>} */}
                        <Screen minWidth={450}>
                            <View style={{ marginLeft: 10, marginRight: 20 }}>
                                <Title
                                    style={[
                                        styles.textStyle,
                                        styles.title,
                                        { fontSize: 24, marginBottom: 20 }
                                    ]}>
                                    Forgot Password
                                </Title>
                                <Text
                                    style={[styles.textStyle, { fontSize: 14, marginBottom: 30 }]}>
                                    To reset your password, please enter the email address you used
                                    to register to ArtShare.
                                </Text>
                            </View>
                            <Form.Field
                                style={styles.input}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                placeholder="Email"
                                name="email"
                                type="textinput"
                                rules={(value: any) => value.replace(/\s/g, '')}
                                onChangeValue={(text: string) => this.setState({ email: text })}
                            />
                            <Form.Field
                                style={styles.input}
                                formGroupStyle={styles.fieldFormGroupStyle}
                                placeholder="Confirm Email"
                                name="verifyEmail"
                                type="textinput"
                                rules={(value: any) => value.replace(/\s/g, '')}
                            />
                            <Form.CustomField
                                name="recaptcha"
                                customField={(value, onChange, error) => {
                                    return (
                                        <View style={{ marginLeft: 15, marginVertical: 20 }}>
                                            <ReCAPTCHA
                                                ref={this.reCaptcha}
                                                sitekey={recaptchaCreds.siteKey}
                                                onChange={(val) =>
                                                    this.onChangeRecaptcha(val, onChange)
                                                }
                                                onExpired={() => {
                                                    this.reCaptcha.current.reset()
                                                }}
                                            />
                                            {error && (
                                                <Text
                                                    style={[
                                                        styles.textStyle,
                                                        { color: '#AD001F', marginTop: 5 }
                                                    ]}>
                                                    reCaptcha is required
                                                </Text>
                                            )}
                                        </View>
                                    )
                                }}
                            />
                            <Form.Submit
                                loading={isLoading}
                                disabled={isLoading}
                                onPress={this.onSubmit}
                                title="Send Verification Code"
                                style={styles.registerBtn}
                                labelStyle={styles.textWhite}
                            />
                        </Screen>

                        <Screen maxWidth={449}>
                            <View style={{ marginLeft: 10, marginRight: 20, alignItems: 'center' }}>
                                <Title
                                    style={[
                                        styles.textStyle,
                                        styles.title,
                                        { fontSize: 24, marginBottom: 20 }
                                    ]}>
                                    Forgot Password
                                </Title>
                                <Text
                                    style={[
                                        styles.textStyle,
                                        { fontSize: 14, marginBottom: 30, marginHorizontal: '10%' }
                                    ]}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Text>
                            </View>
                            <Form.Field
                                style={[styles.input, { width: 300, alignSelf: 'center' }]}
                                formGroupStyle={[
                                    styles.fieldFormGroupStyle,
                                    { alignSelf: 'center' }
                                ]}
                                placeholder="Email"
                                name="email"
                                type="textinput"
                                rules={(value: any) => value.replace(/\s/g, '')}
                                onChangeValue={(text: string) => this.setState({ email: text })}
                            />
                            <Form.Field
                                style={[styles.input, { width: 300, alignSelf: 'center' }]}
                                formGroupStyle={[
                                    styles.fieldFormGroupStyle,
                                    { alignSelf: 'center' }
                                ]}
                                placeholder="Confirm Email"
                                name="verifyEmail"
                                type="textinput"
                                rules={(value: any) => value.replace(/\s/g, '')}
                            />
                            <Form.CustomField
                                name="recaptcha"
                                customField={(value, onChange, error) => {
                                    return (
                                        <View
                                            style={{
                                                marginLeft: 15,
                                                marginVertical: 10,
                                                alignSelf: 'center'
                                            }}>
                                            <ReCAPTCHA
                                                ref={this.reCaptcha}
                                                sitekey={recaptchaCreds.siteKey}
                                                onChange={(val) =>
                                                    this.onChangeRecaptcha(val, onChange)
                                                }
                                                onExpired={() => {
                                                    this.reCaptcha.current.reset()
                                                }}
                                            />
                                            {error && (
                                                <Text
                                                    style={[
                                                        styles.textStyle,
                                                        { color: '#AD001F', marginTop: 5 }
                                                    ]}>
                                                    reCaptcha is required
                                                </Text>
                                            )}
                                        </View>
                                    )
                                }}
                            />

                            <Form.Submit
                                loading={isLoading}
                                disabled={isLoading}
                                onPress={this.onSubmit}
                                title="Send Verification Code"
                                style={[styles.registerBtn, { width: 300, alignSelf: 'center' }]}
                                labelStyle={styles.textWhite}
                            />
                        </Screen>
                    </Form>
                ) : (
                    <AlertMessage
                        navigateToLogin={() => this.onNavigateToLogin()}
                        isSuccess={false}
                        message={this.state.errMsg}
                    />
                )}
            </BackgroundTemplate>
        )
    }
}

const mapStateToProps = (state: any) => {
    const _otpReq = state.api?.forgotPwd?.forgotPwdOtpReq
    return {
        verifyEmailResp: state.api?.forgotPwd?.verifyEmail?.response,
        isLoadingOTPReq: state.api?.forgotPwd?.forgotPwdOtpReq?.loading,
        otpReqStatus: _otpReq?.statusText
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    verifyEmail: (email: string) => verifyEmail(dispatch, { email }),
    forgotPwdOtpReq: (data: any) => forgotPwdOtpReq(dispatch, data),
    onClearVerifyEmail: () => dispatch({ type: CLEAR_VERIFY_EMAIL }),
    onClearForgotPwdOtpReq: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ })
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPage))
