import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import LoginScreen from '../../components/Login/Login'
import Register from '../../components/SignUp/Register'
import WelcomeScreen from '../../components/Welcome/Welcome'
import ForgotPwdScreen from '../../components/ForgotPassword/ForgotPassword'
import VerifyEmailScreen from '../../components/ForgotPassword/VerifyEmail'
import VerifyOTPScreen from '../../components/ForgotPassword/VerifyOTP'

import { RouteName } from '../types'

const AuthStack = createStackNavigator();

export const AuthStackScreen = ({ isFederatedError, defaultPage = RouteName.WelcomePage }: { isFederatedError: boolean, defaultPage?: string }) => (
    <AuthStack.Navigator
        initialRouteName={isFederatedError ? RouteName.LoginPage : defaultPage}
        screenOptions={{
            headerShown: false,
            animationEnabled: false
        }}
    >
        <AuthStack.Screen name={RouteName.WelcomePage} component={WelcomeScreen} />
        <AuthStack.Screen name={RouteName.LoginPage} component={LoginScreen} />
        <AuthStack.Screen name={RouteName.SignUpPage} component={Register} />
        <AuthStack.Screen name={RouteName.VerifyEmailPage} component={VerifyEmailScreen} />
        <AuthStack.Screen name={RouteName.VerifyOTPPage} component={VerifyOTPScreen} />
        <AuthStack.Screen name={RouteName.ForgotPwdPage} component={ForgotPwdScreen} />
    </AuthStack.Navigator>
)