import React from 'react'
import * as _ from 'lodash'
import NotificationDetail from './components/NotificationDetail'
import { View, Image, ScrollView, Text, TouchableOpacity } from 'react-native'
import { Avatar, Button, Title, Menu, Divider, IconButton, ActivityIndicator } from 'react-native-paper'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { FormattedMessage } from 'react-intl'
import { Screen } from '../ReusableComponents'
import { ReduxAppState } from '../utils/types'
import { connect } from 'react-redux'
import { User } from '../reducers/User'
import { RouteName } from '../navigation/types'
import { updateMenu } from '../reducers/Menu/changeMenu'
import { Dispatch } from 'redux'
import { retrieveUser } from '../reducers/User/user'
import Socket from '../websocket'
import { Notification } from '../reducers/Notification'
import { generateRandomId } from '../utils'
import { updateNotification } from '../reducers/Notification/updateNotification'
import { getNotifications } from '../reducers/Notification/getNotifications'
import { CLEAR_NOTIFICATION_COUNT, setNotificationCount, getNotificationWithLimit } from '../reducers/Notification/getNotificationWithLimit'
import { Route } from '@react-navigation/native'
import { HeaderStyles as styles } from './styles'


export interface HeaderContentProps {
    navigation: DrawerNavigationProp<any>
    route: Route<RouteName>
}

export interface DispatchProps {
    updateMenu: (menu: string) => any
    setNotifCount: (count: number) => any
    readNotification: (auditId: string) => any
    onClearNotifications: () => any
    getUserInfo: () => any
    getNotificationWithLimit: (userId: string) => any,
    getAllNotification: (userId: string) => any,
    onSetRoute: (route: Route<RouteName>) => void
}

export interface StateProps {
    currentUser?: User,
    notificationWithLimit?: Notification[],
    notificationCount: number,
    isLoadingNotif: boolean
}

export interface HeaderContentState {
    isVisible: boolean
    imageError: boolean
}

export type HeaderContentDetailsProps = HeaderContentProps & DispatchProps & StateProps

const ProfileDropdown = (props: any) => {
    return (
        <React.Fragment>
            <TouchableOpacity style={{ flexDirection: 'row' }} onPress={props.navigateToProfile}>
                {!props.profilePicThumbnail ? (
                    <Avatar.Text size={40} label={props.initials} style={styles.profileIcon} color={'#FFFFFF'} />
                ) : (
                    <Image
                        style={{ width: 40, height: 40, borderRadius: 80, borderColor: '#F5BF32', borderWidth: 1, marginRight: 10 }}
                        source={{ uri: !props.imageError ? props.profilePicThumbnail : props.profilePicUrl }}
                        onError={() => props.onImageError()}
                        resizeMode='contain' />
                )}

                <View style={{ alignSelf: 'center' }}>
                    <Title style={styles.greetingsText}>{props.name}</Title>
                </View>
                {/* <Icon name="caret-down" size={18} style={styles.caretDownIcon} /> */}
            </TouchableOpacity>
        </React.Fragment>
    )
}

const Badge = (props: any) => (
    <View style={[props.count > 0 ? styles.circle : styles.hiddenCircle]}>
        <Text style={styles.count}>{props.count >= 10 ? '9+' : props.count}</Text>
    </View>
)

const HeaderContentHOC = (WrappedComponent: any) => {
    class HeaderContent extends React.Component<HeaderContentDetailsProps, HeaderContentState> {
        constructor(props: HeaderContentDetailsProps) {
            super(props)
            this.state = {
                isVisible: false,
                imageError: false
            }
        }

        componentDidMount() {
            this.props.getUserInfo()
            this.handleReceive()
            this.props.onSetRoute(this.props.route)
            // console.log('m ount', this.props.navigation.getState())
        }


        componentDidUpdate(prevProps: Readonly<HeaderContentDetailsProps>, prevState: Readonly<HeaderContentState>, snapshot?: any): void {
            if (!_.isEqual(this.props.route, prevProps.route)) {
                this.props.onSetRoute(this.props.route)
            }
            if (!_.isEqual(this.props.navigation.getState(), prevProps.navigation.getState())) {
                this.props.onSetRoute(this.props.route)
            }
            // console.log('update', this.props.navigation.getState())
        }

        componentWillUnmount() {
            // this.props.onClearNotifications()
        }

        getInitial = (name: any) => {
            return `${name?.charAt(0).toUpperCase()}`
        }

        capitalize = (str: any) => {
            const firstWord = str?.split(" ")[0]
            return firstWord?.charAt(0).toUpperCase() + firstWord?.slice(1)
        }

        onImageError = () => {
            this.setState({ imageError: true })
        }

        openMenu = () => {
            this.setState({ isVisible: true })
        }

        closeMenu = () => {
            this.setState({ isVisible: false })
        }

        navigateToProfile = () => {
            this.props.navigation.navigate(RouteName.ProfilePage)
            this.props.updateMenu(RouteName.ProfilePage)
            this.closeMenu()
        }

        navigateToNotification = () => {
            this.props.getAllNotification(this.props.currentUser?.sub)
            this.props.navigation.navigate(RouteName.NotificationPage)
            this.closeMenu()
        }

        getHeader = () => (
            <React.Fragment>
                <Screen maxWidth={991}>
                    <TouchableOpacity onPress={() => this.props.navigation.toggleDrawer()}>
                        <Image
                            source={require('../assets/menu.png')}
                            style={{ height: 30, width: 30 }}
                        />
                    </TouchableOpacity>
                </Screen>
                <View style={{ flex: 1, height: 2.5, backgroundColor: '#9D9D9D', marginHorizontal: 20 }} />
                <View style={{ flexDirection: 'row' }}>
                    <Image
                        source={require('../assets/artshare-logo.png')}
                        style={{ height: 60, width: 60 }}
                        resizeMode='contain'
                    />
                    <View style={{ marginLeft: 10 }}>
                        <Text style={[styles.titleText, { marginTop: '6%' }]}>
                            <FormattedMessage id='HeaderContent.msg.title' />
                        </Text>
                    </View>
                </View>
            </React.Fragment>
        )

        onUpdateNotifCount = () => {
            this.handleSend()
            this.props.getNotificationWithLimit(this.props.currentUser?.sub)
            this.openMenu()
        }

        handleSend = () => {
            if (Socket.ws?.readyState === WebSocket.OPEN) {
                Socket.sendMessage(JSON.stringify({
                    action: 'sendToOne',
                    userId: this.props.currentUser?.cognitoId,
                    isUpdate: true,
                    isAdd: false
                }), this.props.currentUser?.cognitoId)
            } else {
                // Queue a retry
                setTimeout(() => { this.handleSend() }, 1000)
            }
        }

        handleReceive = () => {
            if (Socket.ws?.readyState === WebSocket.OPEN) {
                Socket.onMessage((event: any) => {
                    if (event.data !== 'Updated Successfully' && event.data !== 'updateSystemParams') {
                        const _data = JSON.parse(event.data)
                        const _notificationCount = _data.notificationCount
                        this.props.setNotifCount(_notificationCount)
                    }
                }, this.props.currentUser?.cognitoId)
            } else {
                // Queue a retry
                setTimeout(() => { this.handleReceive() }, 1000)
            }
        }

        onHandleNotif = (auditId: string) => {
            this.props.readNotification(auditId)
        }

        render() {
            const { currentUser, notificationWithLimit, notificationCount, isLoadingNotif } = this.props
            return (
                <React.Fragment>
                    <View style={styles.headerParent}>
                        <Screen minWidth={375} style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                            {this.getHeader()}
                        </Screen>
                        <Screen maxWidth={374} style={{ marginBottom: 30 }}>
                            {this.getHeader()}
                        </Screen>
                        <Screen minWidth={991}>
                            <View>
                                <ProfileDropdown
                                    profilePicThumbnail={currentUser?.profilePicThumbnail}
                                    profilePicUrl={currentUser?.profilePicUrl}
                                    imageError={this.state.imageError}
                                    initials={this.getInitial(currentUser?.name)}
                                    name={this.capitalize(currentUser?.name)}
                                    onImageError={this.onImageError}
                                    openMenu={this.openMenu}
                                    navigateToProfile={this.navigateToProfile}
                                />
                            </View>
                            <View style={styles.rightSideParent}>
                                <Menu
                                    visible={this.state.isVisible}
                                    onDismiss={this.closeMenu}
                                    anchor={
                                        <TouchableOpacity style={{ flexDirection: 'row' }} onPress={this.onUpdateNotifCount}>
                                            <IconButton
                                                icon='bell-outline'
                                                size={35}
                                                onPress={this.onUpdateNotifCount}
                                            />
                                            <Badge count={notificationCount} />
                                        </TouchableOpacity>
                                    }
                                    style={{ marginTop: 50, width: '35%' }}>
                                    {isLoadingNotif ? <ActivityIndicator animating={true} color='#AFCD37' size='small' /> : (
                                        <ScrollView style={{ maxHeight: 400 }}>
                                            {notificationWithLimit?.map((item: Notification) => (
                                                <NotificationDetail
                                                    key={generateRandomId()}
                                                    onReadNotification={() => this.onHandleNotif(item?.id)}
                                                    item={item}
                                                />
                                            ))}
                                        </ScrollView>
                                    )}
                                    <Divider />
                                    <View style={{ alignItems: 'center' }}>
                                        <Button onPress={this.navigateToNotification} mode='outlined' color='black' labelStyle={{ fontSize: 10, fontFamily: "'Montserrat', sans-serif" }} style={{ width: '120px', marginTop: 10 }}>See All</Button>
                                    </View>
                                </Menu>
                            </View>
                        </Screen>
                    </View>
                    <WrappedComponent {...this.props} />
                </React.Fragment>
            )
        }
    }

    const mapStateToProps = (state: ReduxAppState) => {
        const _authUserInfo = state.api?.user?.authUser
        const _notification = state.api?.notification?.getNotificationWithLimit
        return ({
            currentUser: _authUserInfo.response,
            notificationWithLimit: _notification.response,
            isLoadingNotif: _notification.loading,
            notificationCount: _notification.count,
        })
    }

    const mapDispatchToProps = (dispatch: Dispatch) => ({
        updateMenu: (activeItem: string) => dispatch(updateMenu(activeItem)),
        setNotifCount: (count: number) => dispatch(setNotificationCount(count)),
        readNotification: (auditId: string) => updateNotification(dispatch, auditId),
        onClearNotifications: () => dispatch({ type: CLEAR_NOTIFICATION_COUNT }),
        getUserInfo: () => retrieveUser(dispatch),
        getNotificationWithLimit: (userId: string) => getNotificationWithLimit(dispatch, userId),
        getAllNotification: (userId: string) => getNotifications(dispatch, userId),
        onSetRoute: (route: Route<RouteName>) => dispatch({ type: 'custom.route', value: route })
    })

    return connect(mapStateToProps, mapDispatchToProps)(HeaderContent)
}

export default HeaderContentHOC
