export default {
    apiGateway: {
        REGION: 'ap-southeast-1',
        URL: 'https://89r4632sre.execute-api.ap-southeast-1.amazonaws.com/uat'
    },
    cognito: {
        REGION: 'ap-southeast-1',
        USER_POOL_ID: 'ap-southeast-1_mJ1wfFCir',
        APP_CLIENT_ID: '1vblehes5d422m3j6m4s7dvrar',
        IDENTITY_POOL_ID: 'ap-southeast-1:0f30cddf-2e3b-4cae-a745-495c484560bd'
    },
    oauth: {
        DOMAIN: 'artshareuat.auth.ap-southeast-1.amazoncognito.com',
        REDIRECT_SIGNIN: 'https://uat.app.artshare.gallery/dashboard',
        REDIRECT_SIGNOUT: 'https://uat.app.artshare.gallery',
        RESPONSE_TYPE: 'code',
        SCOPE: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"]
    }
};
