import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Wallet } from '.'

export const BEGIN_FETCH_DETAILS = 'API/ITEM/BEGIN_FETCH_DETAILS'
export const SUCCESS_FETCH_DETAILS = 'API/ITEM/SUCCESS_FETCH_DETAILS'
export const ERROR_FETCH_DETAILS = 'API/ITEM/ERROR_FETCH_DETAILS'
export const CLEAR_FETCH_DETAILS = 'API/ITEM/CLEAR_FETCH_DETAILS'

export const fetchDetailsInitialState = {
    response: undefined,
    loading: false
}

export interface FetchDetailsState {
    response: Wallet | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface FetchDetailsAction {
    type: string
    [item: string]: any
}

export const reducer = (newState: FetchDetailsState = fetchDetailsInitialState, action: FetchDetailsAction) => {
    switch (action.type) {
        case BEGIN_FETCH_DETAILS:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_FETCH_DETAILS:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_FETCH_DETAILS:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        case CLEAR_FETCH_DETAILS:
            return fetchDetailsInitialState
        default:
            return newState
    }
}

export const beginFetchDetails = () => ({
    type: BEGIN_FETCH_DETAILS,
})

export const successFetchDetails = (response: AxiosResponse) => ({
    type: SUCCESS_FETCH_DETAILS,
    data: response.data.payload,
    status: response.status
})

export const errorFetchDetails = (error: AxiosError) => ({
    type: ERROR_FETCH_DETAILS,
    status: error.response?.status,
})

export const fetchDetails = (dispatch: Dispatch, id: any) => {
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url: `/wallet/topup/${id}`
    }

    dispatch(beginFetchDetails())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successFetchDetails(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorFetchDetails(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer