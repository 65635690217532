import React from 'react'
import { View, ToastAndroid, Platform, Image } from 'react-native'
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer'
import { Title, Caption, Drawer, Button, IconButton } from 'react-native-paper'
import { navigationStyles as styles } from './styles'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { logOutUser } from '../reducers/User/user'
import { RouteName } from './types'
import { ReduxAppState } from '../utils/types'

export interface ComponentProps {
    navigation: any
}

export interface DispatchProps {}

export interface StateProps {}

export interface NavContentState {}

export type NavContentDetailsProps = ComponentProps & DispatchProps & StateProps

class AuthDrawerContent extends React.Component<NavContentDetailsProps, NavContentState> {
    render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={styles.sideNavHeader}>
                    <Title style={styles.greetingsText}>ARTSHARE</Title>
                </View>
                <DrawerContentScrollView>
                    <View style={styles.drawerContent}>
                        <Drawer.Section style={styles.drawerSection}>
                            <DrawerItem
                                icon={({ color, size }) => (
                                    <Icon name="briefcase-outline" color={color} size={size} />
                                )}
                                label="Home"
                                onPress={() => {
                                    this.props.navigation.navigate(RouteName.DashboardPage)
                                }}
                            />
                            <DrawerItem
                                icon={({ color, size }) => (
                                    <Icon name="image-multiple-outline" color={color} size={size} />
                                )}
                                label="About"
                                onPress={() => {
                                    this.props.navigation.navigate(RouteName.ListingPage)
                                }}
                            />
                            <DrawerItem
                                icon={({ color, size }) => (
                                    <Icon name="wallet-outline" color={color} size={size} />
                                )}
                                label="How It Works"
                                onPress={() => {
                                    this.props.navigation.navigate(RouteName.WalletPage)
                                }}
                            />
                            {/* <DrawerItem
                                icon={({ color, size }) => (
                                    <Icon
                                        name="cog-outline"
                                        color={color}
                                        size={size}
                                    />
                                )}
                                label="Pricing"
                                onPress={() => { this.props.navigation.navigate(RouteName.SettingsPage) }}
                            />
                            <DrawerItem
                                icon={({ color, size }) => (
                                    <Icon
                                        name="cog-outline"
                                        color={color}
                                        size={size}
                                    />
                                )}
                                label="News/Updates"
                                onPress={() => { this.props.navigation.navigate(RouteName.SettingsPage) }}
                            /> */}
                            <DrawerItem
                                icon={({ color, size }) => (
                                    // <Button onPress={() => {}} icon={{ uri: require('../assets/phone-icon.PNG') }}>wew</Button>
                                    // <IconButton icon={{ uri: require('../assets/phoneicon.jpg')}} size={50} />
                                    <Image
                                        source={require('../assets/phone-icon.png')}
                                        style={{ width: size, height: size, marginLeft: 2 }}
                                    />
                                    // <Icon
                                    //     name='phone-in-talk-outline'
                                    //     color={color}
                                    //     size={size}
                                    // />
                                )}
                                label="Contact Us"
                                onPress={() => {
                                    this.props.navigation.navigate(RouteName.SettingsPage)
                                }}
                            />
                        </Drawer.Section>
                    </View>
                </DrawerContentScrollView>
            </View>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AuthDrawerContent)
