import React from 'react'
import { Image, Text, View } from 'react-native'
import { Button } from 'react-native-paper'
import { forgotPwdStyles as styles } from '../styles'

export const AlertMessage = ({ ...props }) => {
  const { isSuccess, message, navigateToLogin } = props

  const onHandleClickButton = () => {
    navigateToLogin()
  }

  return (
    <React.Fragment>
      <View style={{ alignSelf: 'center' }}>
        <Image
          source={isSuccess ? require('../../../assets/verified.png') : require('../../../assets/warning.png')}
          style={{ height: 300, width: 300, alignSelf: 'center', marginTop: 15 }}
          resizeMode='contain'
        />
        <View style={{ alignSelf: 'center' }}>
          <Text style={[styles.textStyle, { textAlign: 'center', marginTop: 20, fontSize: 16 }]}>{message}</Text>
          <Button onPress={() => onHandleClickButton()} style={[styles.registerBtn, { width: 200, alignSelf: 'center' }]} labelStyle={styles.textWhite}>Back to Login</Button>
        </View>

      </View>
    </React.Fragment>
  )
}

export default AlertMessage