import React from 'react'
import * as _ from 'lodash'
import { notificationStyles as styles } from './styles'
import { View, Text, } from 'react-native'
import { FormattedMessage } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { generateRandomId } from '../../utils'
import { ActivityIndicator, Title } from 'react-native-paper'
import { Audit } from '../../reducers/Audit'
import { User } from '../../reducers/User'
import FooterHOC from '../../hoc/Footer'
import HeaderContentHOC from '../../hoc/HeaderContent'
import SideNavContentHOC from '../../hoc/SidenavContent'
import { useMediaQuery } from 'react-responsive'
import { ResponsiveView, Screen } from '../../ReusableComponents'
import { updateNotification } from '../../reducers/Notification/updateNotification'
import NotifDetailDesktop from './component/NotifDetailDesktop'
import NotifDetailMobile from './component/NotifDetailMobile'
import { getNotifications } from '../../reducers/Notification/getNotifications'
import { Notification } from '../../reducers/Notification'

export interface ComponentProps {
    isRefreshing: boolean
    intl: any
}

export interface DispatchProps {
    getAllNotification: (userId: string) => any,
    readNotification: (audit: string) => any
}

export interface StateProps {
    currentUser?: User
    notifications: Notification[]
    isLoadingLimited: boolean
    isError: boolean
    userType: string | number
}

export interface NotificationPageState {
}

export type NotificationPageProps = ComponentProps & DispatchProps & StateProps

const TableView = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 987px)' })
    return (
        <ResponsiveView width={isDesktop ? '75%' : '100%'}>
            {props.children}
        </ResponsiveView>
    )
}

class NotificationComponent extends React.Component<NotificationPageProps, NotificationPageState> {
    constructor(props: NotificationPageProps) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        this.props.getAllNotification(this.props.currentUser?.sub)
    }

    render() {
        const { isLoadingLimited, isError, notifications } = this.props
        return (
            <React.Fragment>
                <View style={{ marginHorizontal: 20 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Title style={styles.activeTitle}><FormattedMessage id='Notification.msg.notifications' /></Title>
                    </View>
                    {isError && <Text><FormattedMessage id='Notification.msg.somethingWentWrong' /></Text>}
                    <Screen minWidth={769} style={{ width: '100%' }}>
                        <TableView style={{ marginHorizontal: 20 }}>
                            {isLoadingLimited ? (
                                <ActivityIndicator animating={true} color='#AFCD37' size='large' />
                            ) : Object.entries(notifications).map(([date, options]: [string, any]) => {
                                return (
                                    <View key={generateRandomId()} style={{ marginTop: 10 }}>
                                        <Text style={[styles.activeTitle, styles.boldText, { color: '#000000' }]}>{date}</Text>
                                        {options?.map((item: Audit) => (
                                            <NotifDetailDesktop
                                                key={generateRandomId()}
                                                onReadNotification={() => this.props.readNotification(item?.id)}
                                                item={item}
                                            />
                                        ))}
                                    </View>
                                )
                            }
                            )}
                        </TableView>
                    </Screen>

                    <Screen maxWidth={768}>
                        <ResponsiveView width={'90%'}>
                            {isLoadingLimited ? (
                                <ActivityIndicator animating={true} color='#AFCD37' size='large' />
                            ) : Object.entries(notifications).map(([date, options]: [string, any]) => {
                                return (
                                    <View key={generateRandomId()} style={{ marginTop: 10 }}>
                                        <Text style={[styles.activeTitle, styles.boldText, { color: '#000000' }]}>{date}</Text>
                                        {options?.map((item: Audit) => (
                                            <NotifDetailMobile
                                                key={generateRandomId()}
                                                onReadNotification={() => this.props.readNotification(item?.id)}
                                                item={item}
                                            />
                                        ))}
                                    </View>
                                )
                            }
                            )}
                        </ResponsiveView>
                    </Screen>

                </View>
            </React.Fragment >
        )
    }
}


const mapStateToProps = (state: ReduxAppState) => {
    const _getNotifications = state.api?.notification?.getNotification
    const _authUserInfo = state.api?.user?.authUser
    return ({
        notifications: _getNotifications.groupedByDate,
        isLoading: _getNotifications.loading,
        isError: _getNotifications.statusText === 'error',
        userType: _authUserInfo?.response?.hasOwnProperty('custom:user_type') ? _authUserInfo?.response['custom:user_type'] : '1',
        currentUser: _authUserInfo.response
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getAllNotification: (userId: string) => getNotifications(dispatch, userId),
    readNotification: (auditId: string) => updateNotification(dispatch, auditId)
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(HeaderContentHOC, SideNavContentHOC, FooterHOC)(NotificationComponent as any))
